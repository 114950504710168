import axios from 'axios';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Row, Col } from 'antd';

import { normalize_Clock, remove_Punctuation_CPF } from './normalizeEntry';

export const GenesysInfo = () => {
    
    // Read the client info from Redux and take the CPF/CNPJ
    const Client = useSelector(state => state.Client.client);
    const cpf_cnpj = (Client.CPF__c !== null) ? (Client.CPF__c) : (Client.CNPJ__c);
    const telephone = (Client.Phone !== null) ? (Client.Phone) : '';

    // State timer it's used to make the clock function, changing is state every second
    const [timer, setTimer] = useState(false);
    // State callTime it's the cronometer value, it's rendered in the component after normalize it
    const [callTime, setCallTime] = useState({hrs: 0, min: 0, sec: 0});
    // State time represent the time when the call was started
    const [time, setTime] = useState(moment().tz('America/Sao_Paulo'));
    // State ura contains the point of URA when the user were redirected to Tela unica
    const [ura, setUra] = useState('');
    // State callback it's a boolean who say if this call it's a 'Rechamada'
    const [callback, setCallback] = useState(false);
    // State opencases contain the number of cases with status different to 'Fechado' and show it in the component
    const [openCase, setOpenCase] = useState(0);

    // This effect made an API request and inicialize the states time, ura, callback and opencase
    useEffect(()=> {
        if(cpf_cnpj !== null){
            axios.get(
                process.env.REACT_APP_API_URL + `/api/genesys`,
                {
                    params: {
                        cpf_cnpj: cpf_cnpj,
                        cpf_format: remove_Punctuation_CPF(cpf_cnpj),
                        telephone: telephone
                    }
                }
            ).then(( {data} ) => {
                setTime(data.time);
                setUra(data.ura);
                setCallback(data.callback);
                setOpenCase(data.openCase);
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [cpf_cnpj, telephone]);

    // This effect make the clock work, it's executed by first time when time is inicialized by the previous effect, 
    // after that, is executed every second by the setTimeout inside him
    useEffect(() => {
        let current = moment().tz('America/Sao_Paulo');

        // Calculate the hours, minutes and seconds 
        let hrs = current.diff(time, 'hours');
        let min = (current.diff(time, 'minutes') - (hrs * 60) ); 
        let sec = (current.diff(time, 'seconds') - (hrs * 60 * 60) - (min * 60));

        setCallTime({ hrs, min, sec });
        setTimeout(() => {setTimer(!timer)}, 1000);
    }, [time, timer]);

    if(cpf_cnpj === null || cpf_cnpj === undefined || cpf_cnpj === ''){
        return (<></>)
    }

    return (
        <Row gutter={[16, 0]} style={{height: '20px'}}>
            <Col span={5} style={{margin: '10px 0 0 10px'}}>
                <h4 style={{ color: '#002D62', fontWeight: '600'}}>
                    Duração da chamada: 
                    <span style={{margin: '0 10px'}}> { normalize_Clock(callTime.hrs, callTime.min, callTime.sec) }</span>
                </h4>  
            </Col>
            {   (ura !== '') &&
                <Col span={6} style={{margin: '10px 0 0 5px'}}>
                    <h4 style={{ color: '#002D62', fontWeight: '600'}}>
                        Ponto transferência:
                        <span style={{margin: '0 10px'}}> {ura} </span>
                    </h4>
                </Col>
            }
            {   // If it's a 'rechamada'
                callback &&  
                (<Col span={3} style={{marginTop: '10px'}}>
                    <h4 style={{ background: 'rgb(127, 0, 0)', fontWeight: '600', color: 'white', borderRadius: '15px', width: '100px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Recontato</h4>
                </Col>)
            }
            {   // If openCase it's different to 0
                (openCase > 0) &&
                <Col span={5} style={{marginTop: '10px'}}>
                    <h4 style={{ background: '#002D62', fontWeight: '600', color: 'white', borderRadius: '15px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        O cliente possui { openCase } casos abertos.
                    </h4>
                </Col>
            }
        </Row>
    )
}
