import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Divider, Form, Input, message, Modal, Row, Select } from "antd";
import MaskedInput from 'antd-mask-input';
import axios from 'axios';
import moment from 'moment';
import React, { useState } from "react";
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from 'react-router-dom';
import createAccount from "../../Components/Images/create_account.png";
import edit from "../../Components/Images/edit-tools.svg";
import openAccount from "../../Components/Images/open_account.png";
import exit from "../../Components/Images/sign.svg";
import { GenesysInfo } from "./GenesysInfo";
import { normalizeCPF_CNPJ, normalizePhone } from './normalizeEntry';
import { CLEAN_CLIENT_DATA, SET_CLIENT_DATA, SET_CLIENT_SELECTED_REGISTER, SET_CONTA_RESUMIDA, SET_SEARCH_CPF, UPDATE_LEAD_REGISTERS } from "./reducers";
import { AccountBtn, Layout } from "./styles";
import { CreateContaResumida } from "../ContaResumida"

axios.defaults.withCredentials = true

const { Option } = Select;

export const ClientInfos = () => {

  const dispatch = useDispatch();

  const Client = useSelector(state => state.Client.client);
  const pathname = useLocation().pathname;

  const history = useHistory()

  const UserData = {
    Id: null,
    CNPJ__c: null,
    CPF__c: "",
    RecordTypeId: "",
    Segmenta_o_do_consumidor__c: "",
    Name: "",
    FirstName: "",
    LastName: "",
    E_Mail__c: "",
    PersonEmail: "",
    Phone: "",
    PersonMobilePhone: "",
    PersonBirthdate: null,
    Sexo__c: "",
    Bairro__c: "",
    BillingCity: "",
    BillingPostalCode: "",
    BillingState: "",
    BillingStreet: "",
    BillingTypeStreet: "",
    Numero__c: "",
    Proximidade__c: "",
    Complemento__c: "",
    Lead: null
  }

  const { cpf } = require('cpf-cnpj-validator');
  const { cnpj } = require('cpf-cnpj-validator');

  const [CreateContaResumidaModal, setCreateContaResumidaModal] = useState(false);
  const [error, setError] = useState(false);
  const [ValidateCPF_CNPJ, setValidateCPF_CNPJ] = useState('');
  const [ValidateCPF_CNPJ_Search, setValidateCPF_CNPJ_Search] = useState('');
  const [ValidateBirthdate, setValidateBirthdate] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingCep, setloadingCep] = useState(false);
  const [loadingUpateUser, setLoadingUpateUser] = useState(false);
  const [noEmail, setNoEmail] = useState(false);
  const [Client_Data, setClient_Data] = useState(UserData)
  const [visible, setVisible] = useState(false);
  const [typeModal, setTypeModal] = useState(false);
  const [cepSearched, setCepSearched] = useState(false);
  const [hasBairro, sethasBairro] = useState(false);
  const [hasLog, sethasLog] = useState(false);
  const [hasType, sethasType] = useState(false);

  const [form] = Form.useForm()

  const handleChangeCPF_CNPJ = event => {
    if (String(event.target.value).length === 14 || String(event.target.value).length === 18) {
      if (cpf.isValid(event.target.value) || cnpj.isValid(event.target.value)) {
        setValidateCPF_CNPJ_Search('success')
      }
      else {
        setValidateCPF_CNPJ_Search('error')
      }
    }
  }

  const handleChangeCheckBox = event => {

    if (event.target.checked === true) {
      form.setFieldsValue({ user_email: "" })
      setClient_Data({ ...Client_Data, E_Mail__c: "" })
    }
    setNoEmail(event.target.checked)
  }

  function handleChangeData(event, type) {
    if (type === 'PersonBirthdate') {
      if ((String(event.target.value.replace(/_/g, "")).length === 10) && (moment(event.target.value.replace(/-/g, "/"), 'DD/MM/YYYY').format("YYYY-MM-DD") !== 'Invalid date')) {
        setValidateBirthdate('success')
        setClient_Data({ ...Client_Data, PersonBirthdate: moment(event.target.value.replace(/-/g, "/"), 'DD/MM/YYYY').format("YYYY-MM-DD") })
      }
      else {
        setValidateBirthdate('error')
        setClient_Data({ ...Client_Data, PersonBirthdate: event.target.value })
      }
    }
    else if (type === 'Sexo__c') {
      setClient_Data({ ...Client_Data, Sexo__c: event })
    }
    else if (type === 'Name') {
      let NameArry = event.target.value.split(' ')
      if (NameArry.length > 1) {
        Client_Data['FirstName'] = NameArry[0]
        Client_Data['LastName'] = event.target.value.replace(NameArry[0] + ' ', "")
      }
      Client_Data['Name'] = event.target.value
      setClient_Data({ ...Client_Data })

    }
    else if (type === 'CPF/CPNJ') {
      if (String(event.target.value).length > 14) {
        setClient_Data({ ...Client_Data, CNPJ__c: event.target.value, CPF__c: null, RecordTypeId: '01280000000Ahh5AAC' })
      }
      else {
        setClient_Data({ ...Client_Data, CNPJ__c: null, CPF__c: event.target.value, RecordTypeId: '01280000000AhlLAAS' })
      }

      if ((String(event.target.value).length === 14 || String(event.target.value).length === 18)) {
        if (cpf.isValid(event.target.value) || cnpj.isValid(event.target.value)) {
          setValidateCPF_CNPJ('success')
        }
        else {
          setValidateCPF_CNPJ('error')
        }
      }
      else {
        setValidateCPF_CNPJ('')
      }
    }
    else if (type === 'BillingPostalCode') {
      if (String(event.target.value).replace(/\D/g, "").length === 8) {
        searchCep(event.target.value)
      }
      else {
        setClient_Data({ ...Client_Data, BillingPostalCode: event.target.value })
      }
    }
    else if (type === 'Phone' || type === 'Telefone_2__c') {
      setClient_Data({ ...Client_Data, [type]: event.target.value.replace(/[^\d]/g, '') })
    }
    else if (type === 'BillingTypeStreet') {
      setClient_Data({ ...Client_Data, [type]: event })
    }
    else {
      //   Client_Data[type] = event.target.value
      setClient_Data({ ...Client_Data, [type]: event.target.value })
    }

  }

  const divideStreet = (street, complemento = "") => {
    if (!String.prototype.replaceLast) {
      String.prototype.replaceLast = function (find, replace) {
        var index = this.lastIndexOf(find);

        if (index >= 0) {
          return this.substring(0, index) + replace + this.substring(index + find.length);
        }

        return this.toString();
      };
    }

    const kindOfStreets = ["Aeroporto", "Alameda", "Área", "Avenida", "Campo", "Chácara", "Colônia", "Condomínio", "Conjunto", "Distrito", "Esplanada", "Estação", "Estrada", "Favela", "Fazen", "Feira", "Jardim", "Ladeira", "Lago", "Lagoa", "Largo", "Loteamento", "Morro", "Núcleo", "Parque", "Passarela", "Pátio", "Praça", "Quadra", "Recanto", "Residencial", "Rodovia", "Rua", "Setor", "Sítio", "Travessa", "Trecho", "Trevo", "Vale", "Vereda", "Via", "Viaduto", "Viela", "Vila"];

    if (complemento !== "") {
      street = street.replaceLast(complemento, "");
    }

    // Separate the street name by ',' and spaces
    let parts = street.replace(",", " ").split(" ");

    for (let i = 0; i < parts.length; i++) {
      if (parts[i] === "") {
        parts.splice(i, 1);
      }
    }

    let kind = "", newStreet = street, num = "";

    // Check if the first element is a kind of street
    if (kindOfStreets.includes(parts[0])) {
      kind = parts[0];
    }

    // Check is the last element is a number
    if (!isNaN(parts[parts.length - 1])) {
      num = parts[parts.length - 1];
    }

    // If the street name contains a kind of street, we remove them
    if (kind !== "")
      newStreet = street.replace(kind + " ", "");

    // If the street name contains a number at end, we remove them
    if (num !== "") {
      if (newStreet.includes(', ' + num))
        newStreet = newStreet.replace(', ' + num, "");
      else if (newStreet.includes(',' + num))
        newStreet = newStreet.replace(',' + num, "");
      else
        newStreet = newStreet.replace(num, "");
    }

    return [kind, newStreet, num];
  }

  function setFormData(data) {
    if (data === 'reset') {
      form.setFieldsValue({
        user_bairro: "",
        user_birthdate: "",
        user_cep: "",
        user_cidade: "",
        user_cpf_cnpj: "",
        user_sex: "",
        user_email: "",
        user_estado: "",
        user_logradouro: "",
        user_number: "",
        user_type_street: "",
        user_name: "",
        user_phone: "",
        user_phone2: "",
        user_ref: "",
        user_complement: "",

      })
    }
    else {
      if (data.E_Mail__c === '' || data.E_Mail__c === null) { setNoEmail(true) }
      const streetDivided = divideStreet(data.BillingStreet, data.Complemento__c);
      form.setFieldsValue({
        user_bairro: data.Bairro__c,
        user_birthdate: moment(data.PersonBirthdate).format("DD-MM-YYYY"),
        user_cep: data.BillingPostalCode,
        user_cidade: data.BillingCity,
        user_sex: data.Sexo__c,
        user_cpf_cnpj: data.CNPJ__c === null ? data.CPF__c : data.CNPJ__c,
        user_email: data.E_Mail__c,
        user_estado: data.BillingState,
        user_type_street: streetDivided[0],
        user_logradouro: streetDivided[1],
        user_number: (data.Numero__c === null) ? streetDivided[2] : data.Numero__c,
        user_name: data.Name,
        user_phone: (data.Phone !== null ? normalizePhone(data.Phone.replace('+55', ""), '') : data.PersonMobilePhone),
        user_phone2: (data.PersonMobilePhone !== null ? normalizePhone(data.PersonMobilePhone.replace('+55', ""), '') : data.PersonMobilePhone),
        user_ref: data.Proximidade__c,
        user_complement: data.Complemento__c
      })
    }
  }

  function createClient() {
    setFormData('reset')
    setVisible(true)
    setNoEmail(false)
  }

  function ClientDataClean() {
    dispatch({ type: CLEAN_CLIENT_DATA, payload: null })
    setNoEmail(false)
    setValidateBirthdate('')
    setValidateCPF_CNPJ_Search('')
    setValidateCPF_CNPJ('')
    setFormData('reset')
    setClient_Data(UserData)
  }

  async function searchCep(cep) {
    if (String(cep).replace(/\D/g, "").length === 8) {
      setloadingCep(true)
      axios.get(
        process.env.REACT_APP_API_URL + `/api/searchcep`,
        {
          params: {
            data: cep.replace(/\D/g, ""),
          }
        }
      ).then(res => {
        setloadingCep(false)
        if (res.data.return.length === 0) {
          setClient_Data({
            ...Client_Data, BillingPostalCode: cep,
          })
          message.info("Endereço não encontrado para o CEP informado");
        }
        else {
          if (res.data.return[0].Bairro_inicial__c === "" && res.data.return[0].Logradouro__c === "") {
            message.info("Verifique se a cidade tem CEP único, nesse caso, preencha o campo Bairro como 'Centro'");
          }
          setCepSearched(true);

          form.setFieldsValue({
            user_bairro: res.data.return[0].Bairro_inicial__c,
            user_type_street: res.data.return[0].Tipo_de_logradouro__c,
            user_logradouro: res.data.return[0].Logradouro__c,
            user_estado: res.data.return[0].Estado__c,
            user_cidade: res.data.return[0].Cidade__c,
            user_number: "",
            user_ref: "",
            user_complement: "",
          })

          setClient_Data({
            ...Client_Data,
            Bairro__c: res.data.return[0].Bairro_inicial__c,
            BillingCity: res.data.return[0].Cidade__c,
            BillingPostalCode: cep,
            BillingState: res.data.return[0].Estado__c,
            BillingStreet: res.data.return[0].Logradouro__c,
            BillingTypeStreet: res.data.return[0].Tipo_de_logradouro__c,
            Numero__c: '',
            Proximidade__c: '',
            Complemento__c: ''
          })

          res.data.return[0].Logradouro__c.length < 2 ? sethasLog(false) : sethasLog(true);
          res.data.return[0].Tipo_de_logradouro__c.length < 2 ? sethasType(false) : sethasType(true);
          res.data.return[0].Bairro_inicial__c.length < 2 ? sethasBairro(false) : sethasBairro(true);
        }
      })
        .catch((error) => {
          setCepSearched(false);
          setloadingCep(false)
          message.error("Houve um erro ao consultar dados do CEP");
        })
    }
    else {
      message.info("CEP Incompleto");
    }
  }

  function updateClient() {
    let client_changes = {}
    if (Client_Data.Id !== null)
      for (var key in Client_Data) {
        if (Client_Data[key] !== Client[key]) {
          if (key === 'BillingStreet' || key === 'BillingTypeStreet' || key === 'Numero__c' || key === 'Complemento__c') {
            client_changes = { ...client_changes, 'BillingStreet': Client_Data['BillingTypeStreet'] + ' ' + Client_Data['BillingStreet'] + ', ' + Client_Data['Numero__c'] + ' ' + Client_Data['Complemento__c'] }
          }
          else {
            client_changes = { ...client_changes, [key]: Client_Data[key] }
          }
        }
      }
    else
      client_changes = {
        RecordTypeId: Client_Data['RecordTypeId'],
        CNPJ__c: Client_Data['CNPJ__c'],
        CPF__c: Client_Data['CPF__c'],
        FirstName: Client_Data['FirstName'],
        LastName: Client_Data['LastName'],
        E_Mail__c: Client_Data['E_Mail__c'],
        PersonEmail: Client_Data['PersonEmail'],
        Phone: Client_Data['Phone'],
        PersonMobilePhone: Client_Data['PersonMobilePhone'],
        PersonBirthdate: Client_Data['PersonBirthdate'],
        Sexo__c: Client_Data['Sexo__c'],
        Bairro__c: Client_Data['Bairro__c'],
        BillingCity: Client_Data['BillingCity'],
        BillingPostalCode: Client_Data['BillingPostalCode'],
        BillingState: Client_Data['BillingState'],
        BillingStreet: Client_Data['BillingTypeStreet'] + ' ' + Client_Data['BillingStreet'] + ', ' + Client_Data['Numero__c'] + ' ' + Client_Data['Complemento__c'],
        Proximidade__c: Client_Data['Proximidade__c'],
      }


    if (Object.keys(client_changes).length === 0) {
      message.error('Nenhuma alteração feita')
    }
    else {
      delete client_changes['Name']
      setLoadingUpateUser(true)
      axios.request({
        method: Client_Data.Id == null ? 'post' : 'patch',
        url: process.env.REACT_APP_API_URL + "/api/" + (Client_Data.Id == null ? 'create_client' : 'update_client'),
        data: {
          client_data: client_changes,
          client_id: Client_Data['Id']
        },
        headers: { "Content-Type": "application/json" },
      }).then(res => {
        setLoadingUpateUser(false);
        if (res.status === 200 && res.data.statusText) {
          let getClientData = Client_Data.CNPJ__c === null ? Client_Data.CPF__c : Client_Data.CNPJ__c
          message.success(res.data.statusText)
          setVisible(false)
          getClient({ cpf_cnpj: getClientData })
        }
      })
        .catch((error) => {
          setLoadingUpateUser(false)

          setLoadingUpateUser(false)
          let MSG = ' NO MSG '
          let ERROR_CODE = '000'
          if (error && error.response !== undefined) {
            MSG = error.response.data !== undefined ? error.response.data.return : ' NO MSG '
            ERROR_CODE = error.response.status
            console.log('ERRO CODE: ' + ERROR_CODE + ' - ERRO MSG: ' + MSG + ' DATA: ' + JSON.stringify(client_changes))
            ReactGA.event({
              category: 'ERROR',
              action: 'CREATE USER API ERROR',
              label: 'ERRO CODE: ' + ERROR_CODE + ' - ERRO MSG: ' + MSG + ' DATA: ' + client_changes
            });
          }
          let error_msg = ('Erro ao ' + (Client_Data.Id == null ? 'criar conta' : 'atualizar dados') + ', tente novamente mais tarde.')
          console.log(error_msg)
          message.error(error_msg);

        })
    }
  }

  const getClient = ({ cpf_cnpj }) => {
    setError(false);
    setCepSearched(false);
    ClientDataClean()
    if (cpf.isValid(cpf_cnpj) || cnpj.isValid(cpf_cnpj)) {
      setLoading(true);
      let type = String(cpf_cnpj).length > 14 ? 'CNPJ' : 'CPF'
      axios.get(
        process.env.REACT_APP_API_URL + `/api/search_client`,
        {
          params: {
            type: type,
            data: cpf_cnpj
          }
        }
      ).then(res => {
        setLoading(false);

        // Modal with options to create o to see Conta Resumida o Conta Detalhada 
        // switch to true to show or false to hide
        setTypeModal(true);

        // Check data for "Conta Detalhada", if exist check "Conta resumida" too
        if (res.data.return['totalSize'] !== 0 && res.data.return.records[0].Id !== undefined) {
          dispatch({ type: SET_CLIENT_DATA, payload: { clientData: res.data.return.records[0] } });
          res.data.return.records[0].LeadRegisters !== null && dispatch({ type: SET_CLIENT_SELECTED_REGISTER, payload: res.data.return.records[0].LeadRegisters[0] });
          setClient_Data(res.data.return.records[0]);
          setFormData(res.data.return.records[0]);
          // try {
          //   document.querySelector(".ant-modal-root").style.cssText = "display: none;"
          // } catch (error) {}
        }
        // If not exist "Conta detalhada" check if only exist the "Conta resumida"
        else if (res.data.return['totalSize'] !== 0 && res.data.return.records[0].Id === undefined && res.data.return.records[0].Lead !== undefined) {
          setCreateContaResumidaModal(false)
          res.data.return.records[0].CPF__c = res.data.return.records[0].Lead.CPF__c;
          let Lead = res.data.return.records[0].Lead;
          dispatch({
            type: SET_CONTA_RESUMIDA,
            payload: {
              ...res.data.return.records[0].Lead,
              E_Mail__c: Lead.Email,
            },
          });
          res.data.return.records[0].LeadRegisters !== undefined &&
            dispatch({
              type: UPDATE_LEAD_REGISTERS,
              payload: res.data.return.records[0].LeadRegisters,
            });

          let data = {
            Bairro__c: Lead.Bairro__c,
            PersonBirthdate: Lead.Data_de_nascimento__c,
            BillingPostalCode: Lead.PostalCode,
            BillingCity: Lead.City,
            Sexo__c: Lead.Sexo__c,
            CNPJ__c: null,
            CPF__c: Lead.CPF__c,
            E_Mail__c: Lead.Email,
            BillingState: Lead.State,
            BillingStreet: Lead.Street,
            Name: Lead.FirstName,
            Phone: Lead.Phone,
            PersonMobilePhone: Lead.MobilePhone,
            Proximidade__c: Lead.Proximidade__c,
          };
          setClient_Data({ ...Client_Data, Lead: Lead, CPF__c: Lead.CPF__c });
        }
        // If not exists accounts of both types
        else {
          dispatch({type: SET_SEARCH_CPF, payload: cpf_cnpj})
          // try {
          //   document.querySelector(".ant-modal-root").style.cssText = "";
          // } catch (error) {
          // }
          message.info("Consumidor não possui cadastro. Necessário criar conta.")
          if (type === 'CPF') {
            setClient_Data({ ...Client_Data, CPF__c: cpf_cnpj, CNPJ__c: null, RecordTypeId: '01280000000AhlLAAS' })
            form.setFieldsValue({ user_cpf_cnpj: cpf_cnpj })
          }
          else {
            setClient_Data({ ...Client_Data, CPF__c: null, CNPJ__c: cpf_cnpj, RecordTypeId: '01280000000Ahh5AAC' })
            form.setFieldsValue({ user_cpf_cnpj: cpf_cnpj })
          }
        }
      })
        .catch((error) => {
          setLoading(false)
          message.error("Houve um erro ao buscar os dados do cliente");
        })
    }
    else {
      message.error("CPF/CNPJ inválido, digite novamente.");
      setError(true);
    }
  }

  return (
    <div>
      <>
        <Modal
          visible={typeModal}
          onCancel={() => setTypeModal(false)}
          width={'55%'}
          footer={null}>
          {Client_Data.Id === null ?
            <AccountBtn className="accountBtn" onClick={() => { setVisible(true); setTypeModal(false); }}>
              <img src={createAccount} />
              Criar conta detalhada
            </AccountBtn>
            :
            <Link to="/">
              <AccountBtn className="accountBtn" onClick={() => { setTypeModal(false) }}>
                <img src={openAccount} />
                Abrir conta detalhada
              </AccountBtn>
            </Link>
          }
          {Client_Data.Lead === null ?
            <AccountBtn className="accountBtn" onClick={() => { setCreateContaResumidaModal(true); setTypeModal(false); }}>
              <img src={createAccount} />
              Criar conta resumida
            </AccountBtn>
            :
            <Link to="contaResumida">
              <AccountBtn className="accountBtn" onClick={() => { setTypeModal(false) }}>
                <img src={openAccount} />
                Abrir conta resumida
              </AccountBtn>
            </Link>
          }
        </Modal>
        
        <CreateContaResumida visible={ CreateContaResumidaModal } onSuccess={getClient} onCancel={() => setCreateContaResumidaModal(false)} />
        
        <Modal
          visible={visible}
          onCancel={() => { setVisible(false); setCepSearched(false); }}
          width={'55%'}
          footer={null}>
          <h2 style={{ color: '#002D62', fontWeight: '500', textAlign: 'center' }} >{Client_Data.Id == null ? 'Criar conta detalhada' : 'Atualização de dados cadastrais'}</h2>
          <Divider style={{ color: '#002D62' }} type="horizontal" />
          <Form
            form={form}
            name="updateUser"
            onFinish={() => updateClient()}
          >
            <Row gutter={[16, 0]} style={{ height: '80px' }}>
              <Col span={10}>
                <label style={{ color: '#002D62', fontWeight: '500' }}>Nome Completo</label>
                <Form.Item
                  name="user_name"
                  rules={[{ required: true, message: "Inserir Nome" }]}>
                  <Input
                    disabled={false}
                    value={Client_Data.Name}
                    style={{
                      borderRadius: '5px',
                    }}
                    placeholder="Informe o Nome do Usuário"
                    onChange={(e) => handleChangeData(e, 'Name')}
                  />
                </Form.Item>

              </Col>

              <Col span={6}>
                <label style={{ color: '#002D62', fontWeight: '500' }}>{(Client_Data.CPF__c === null && Client_Data.CNPJ__c === null) ? 'CPF/CNPJ' : Client_Data.CNPJ__c === null ? 'CPF' : 'CNPJ'}</label>
                <Form.Item
                  name="user_cpf_cnpj"
                  hasFeedback
                  validateStatus={ValidateCPF_CNPJ}
                  rules={[{ required: true, message: "Inserir CPF/CNPJ." }]}
                  normalize={normalizeCPF_CNPJ}>
                  <Input
                    disabled={Client_Data.Id === null ? false : true}
                    value={Client_Data.CPF__c === null ? '' : Client_Data.CNPJ__c === null ? Client_Data.CPF__c : Client_Data.CNPJ__c}
                    tyle={{ borderRadius: '5px' }}
                    maxLength={18}
                    placeholder={'CPF/CNPJ do usuário'}
                    onChange={(e) => handleChangeData(e, 'CPF/CPNJ')}
                  />
                </Form.Item>
              </Col>

              {Client_Data.CNPJ__c === null &&
                <Col span={4}>
                  <label style={{ color: '#002D62', fontWeight: '500' }}>Nascimento</label>
                  <Form.Item
                    name="user_birthdate"
                    hasFeedback
                    validateStatus={ValidateBirthdate}
                    rules={[{ required: false, message: "Inserir Data" }]}>
                    <MaskedInput
                      disabled={false}
                      value={moment(Client_Data.PersonBirthdate).format("DD-MM-YYYY")}
                      style={{ borderRadius: '5px' }}
                      onChange={(e) => handleChangeData(e, 'PersonBirthdate')}
                      mask="11-11-1111"
                    />
                  </Form.Item>
                </Col>}

              {Client_Data.CNPJ__c === null &&
                <Col span={4}>
                  <label style={{ color: '#002D62', fontWeight: '500' }}>Sexo</label>
                  <Form.Item
                    name="user_sex"
                    hasFeedback
                    rules={[{ required: Client_Data.CPF__c === null ? false : true, message: "Inserir Sexo" }]}>
                    <Select
                      showSearch
                      style={{ width: 110 }}
                      optionFilterProp="children"
                      value={Client_Data.Sexo__c}
                      onChange={(e) => handleChangeData(e, 'Sexo__c')}
                    >
                      <Option value="Masculino">Masculino</Option>
                      <Option value="Feminino">Feminino</Option>
                    </Select>
                  </Form.Item>

                </Col>}

            </Row>

            <Row gutter={[16, 0]} style={{ height: '80px' }}>
              <Col span={5}>
                <label style={{ color: '#002D62', fontWeight: '500' }}>Telefone 1</label>
                <Form.Item
                  name="user_phone"
                  rules={[{ required: true, message: "Inserir Telefone" }]}
                  normalize={normalizePhone}>
                  <Input
                    value={Client_Data.Phone}
                    style={{ borderRadius: '5px' }}
                    maxLength={15}
                    placeholder="Telefone"
                    onChange={(e) => handleChangeData(e, 'Phone')}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <label style={{ color: '#002D62', fontWeight: '500' }}>Telefone 2</label>
                <Form.Item
                  name="user_phone2"
                  rules={[{ required: false, message: "Inserir Telefone 2" }]}
                  normalize={normalizePhone}>
                  <Input
                    value={Client_Data.Telefone_2__c}
                    style={{ borderRadius: '5px' }}
                    maxLength={15}
                    placeholder="Telefone 2"
                    onChange={(e) => handleChangeData(e, 'Telefone_2__c')}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <label style={{ color: '#002D62', fontWeight: '500' }}>E-mail</label>
                <Form.Item
                  name="user_email"
                  rules={[{ required: !noEmail, message: "Inserir e-mail" }]}>
                  <Input
                    disabled={noEmail}
                    value={Client_Data.E_Mail__c}
                    style={{ borderRadius: '5px' }}
                    placeholder="E-mail"
                    onChange={(e) => handleChangeData(e, 'E_Mail__c')}
                  />
                </Form.Item>
              </Col>

              <Col span={7}>
                <Checkbox
                  style={{ marginTop: '15%', marginLeft: '10px' }}
                  checked={noEmail}
                  onChange={(e) => handleChangeCheckBox(e)}
                >
                  {'Consumidor não possui e-mail'}
                </Checkbox>
              </Col>
            </Row>

            <Row gutter={[16, 0]} style={{ height: '70px', marginTop: '10px', marginBottom: '5px' }}>
              <Col span={6}>
                <label style={{ color: '#002D62', fontWeight: '500' }}>CEP</label>
                <Form.Item
                  name="user_cep"
                  rules={[{ required: true, message: "Inserir CEP" }]}>
                  <MaskedInput
                    style={{ borderRadius: '5px' }}
                    value={Client_Data.BillingPostalCode}
                    onChange={(e) => handleChangeData(e, 'BillingPostalCode')}
                    placeholder="CEP"
                    maxLength={9}
                    mask="11111-111"
                  />
                </Form.Item>

              </Col>
              <Col span={2}>
                <label style={{ color: '#002D62', fontWeight: '500' }}></label>
                <Button style={{ marginTop: '22px', borderRadius: '5px' }} icon={<SearchOutlined />} loading={loadingCep} disabled={loadingCep} onClick={() => { searchCep(Client_Data.BillingPostalCode) }}>Buscar</Button>
              </Col>
            </Row>

            <Row gutter={[16, 0]} style={{ height: '30px', marginTop: '5px', marginBottom: '15px', padding: '0px 10px', color: "rgba(0, 0, 0, 0.7)", fontSize: "14px" }}>
              {
                (cepSearched === false) ? <>Faza a busca pelo CEP para alterar as informações do endereço</> : <>Complete as informações solicitadas no formulario</>
              }
            </Row>

            <Row gutter={[16, 0]} style={{ height: '80px' }}>
              <Col span={6}>
                <label style={{ color: '#002D62', fontWeight: '500' }}>Tipo logradouro</label>
                <Form.Item
                  name="user_type_street"
                  rules={[{ required: true, message: "Inserir tipo de logradouro" }]}>
                  <Select onChange={(e) => handleChangeData(e, 'BillingTypeStreet')} disabled={!cepSearched || (cepSearched && hasType)}>
                    <Option value="" disabled={true} selected>Selecione um tipo</Option>
                    <Option value="Aeroporto">Aeroporto</Option>
                    <Option value="Alameda">Alameda</Option>
                    <Option value="Área">Área</Option>
                    <Option value="Avenida">Avenida</Option>
                    <Option value="Campo">Campo</Option>
                    <Option value="Chácara">Chácara</Option>
                    <Option value="Colônia">Colônia</Option>
                    <Option value="Condomínio">Condomínio</Option>
                    <Option value="Conjunto">Conjunto</Option>
                    <Option value="Distrito">Distrito</Option>
                    <Option value="Esplanada">Esplanada</Option>
                    <Option value="Estação">Estação</Option>
                    <Option value="Estrada">Estrada</Option>
                    <Option value="Favela">Favela</Option>
                    <Option value="Fazenda">Fazenda</Option>
                    <Option value="Feira">Feira</Option>
                    <Option value="Jardim">Jardim</Option>
                    <Option value="Ladeira">Ladeira</Option>
                    <Option value="Lago">Lago</Option>
                    <Option value="Lagoa">Lagoa</Option>
                    <Option value="Largo">Largo</Option>
                    <Option value="Loteamento">Loteamento</Option>
                    <Option value="Morro">Morro</Option>
                    <Option value="Núcleo">Núcleo</Option>
                    <Option value="Parque">Parque</Option>
                    <Option value="Passarela">Passarela</Option>
                    <Option value="Pátio">Pátio</Option>
                    <Option value="Praça">Praça</Option>
                    <Option value="Quadra">Quadra</Option>
                    <Option value="Recanto">Recanto</Option>
                    <Option value="Residencial">Residencial</Option>
                    <Option value="Rodovia">Rodovia</Option>
                    <Option value="Rua">Rua</Option>
                    <Option value="Setor">Setor</Option>
                    <Option value="Sítio">Sítio</Option>
                    <Option value="Travessa">Travessa</Option>
                    <Option value="Trecho">Trecho</Option>
                    <Option value="Trevo">Trevo</Option>
                    <Option value="Vale">Vale</Option>
                    <Option value="Vereda">Vereda</Option>
                    <Option value="Via">Via</Option>
                    <Option value="Viaduto">Viaduto</Option>
                    <Option value="Viela">Viela</Option>
                    <Option value="Vila">Vila</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={4}></Col>

              <Col span={10}>
                <label style={{ color: '#002D62', fontWeight: '500' }}>Logradouro</label>
                <Form.Item
                  name="user_logradouro"
                  rules={[{ required: true, message: "Inserir Logradouro" }]}>
                  <Input
                    value={Client_Data.BillingStreet}
                    disabled={!cepSearched || (cepSearched && hasLog)}
                    placeholder={"Logradouro"}
                    style={{ borderRadius: '5px' }}
                    onChange={(e) => handleChangeData(e, 'BillingStreet')}
                  />
                </Form.Item>
              </Col>

              <Col span={4}>
                <label style={{ color: '#002D62', fontWeight: '500' }}>Número</label>
                <Form.Item
                  name="user_number"
                  rules={[{ required: true, message: "Inserir Numero" }]}
                  normalize={value => (value).replace(/\D/g, "")}>
                  <Input
                    disabled={!cepSearched}
                    value={parseInt(Client_Data.Numero__c)}
                    placeholder={'Nº do logradouro'}
                    maxLength={10}
                    onChange={(e) => handleChangeData(e, 'Numero__c')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 0]} style={{ height: '80px' }}>
              <Col span={10}>
                <label style={{ color: '#002D62', fontWeight: '500' }}>Bairro</label>
                <Form.Item
                  name="user_bairro"
                  rules={[{ required: true, message: "Inserir Bairro" }]}>
                  <Input
                    disabled={!cepSearched || (cepSearched && hasBairro)}
                    value={Client_Data.Bairro__c}
                    style={{ borderRadius: '5px' }}
                    placeholder={"Bairro"}
                    onChange={(e) => handleChangeData(e, 'Bairro__c')}
                  />
                </Form.Item>
              </Col>

              <Col span={10}>
                <label style={{ color: '#002D62', fontWeight: '500' }}>Cidade</label>
                <Form.Item
                  name="user_cidade"
                  rules={[{ required: true, message: "Inserir Cidade" }]}>
                  <Input
                    disabled={!cepSearched || (cepSearched && Client_Data.BillingCity !== '')}
                    value={Client_Data.BillingCity}
                    style={{ borderRadius: '5px' }}
                    placeholder={"Cidade"}
                    onChange={(e) => handleChangeData(e, 'BillingCity')}
                  />
                </Form.Item>
              </Col>

              <Col span={4}>
                <label style={{ color: '#002D62', fontWeight: '500' }}>UF</label>
                <Form.Item
                  name="user_estado"
                  rules={[{ required: true, message: "UF" }]}>
                  <Input
                    disabled={!cepSearched || (cepSearched && Client_Data.BillingState !== '')}
                    value={Client_Data.BillingState}
                    style={{ borderRadius: '5px' }}
                    placeholder={"UF"}
                    onChange={(e) => handleChangeData(e, 'BillingState')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 0]} style={{ height: '80px' }}>
              <Col span={10}>
                <label style={{ color: '#002D62', fontWeight: '500' }}>Referência</label>
                <Form.Item
                  name="user_ref"
                  rules={[{ required: false, message: "Inserir Referência" }]}>
                  <Input
                    disabled={!cepSearched}
                    value={Client_Data.Proximidade__c}
                    style={{ borderRadius: '5px' }}
                    onChange={(e) => handleChangeData(e, 'Proximidade__c')}
                    placeholder={"Referência do endereço"}
                  />
                </Form.Item>
              </Col>

              <Col span={14}>
                <label style={{ color: '#002D62', fontWeight: '500' }}>Complemento</label>
                <Form.Item
                  name="user_complement"
                  rules={[{ required: false, message: "Inserir Complemento" }]}>
                  <Input
                    disabled={!cepSearched}
                    value={Client_Data.Complemento__c}
                    placeholder={"Complemento do endereço"}
                    onChange={(e) => handleChangeData(e, 'Complemento__c')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" justify="center" align="end">
              <Form.Item
                style={{ marginTop: '25px' }}
              >
                <Button style={{ borderRadius: '5px' }} disabled={loadingUpateUser || loadingCep} loading={loadingUpateUser} type="primary" htmlType="submit">Salvar</Button>
              </Form.Item>
            </Row>
          </Form>
        </Modal>
      </>

      <Layout>
        <Card className="boxShadow cardColor">
          {(Client_Data.Id === null && Client_Data.Lead === null) ?
            <div>
              <Form
                name="buscaCPF"
                layout="inline"
                onFinish={getClient}
              >
                <Form.Item
                  name="cpf_cnpj"
                  style={{ marginRight: '20px' }}
                  hasFeedback
                  validateStatus={ValidateCPF_CNPJ_Search}
                  rules={[{ required: true, message: "Por favor insira o CPF/CNPJ." }
                  ]}
                  normalize={normalizeCPF_CNPJ}
                >
                  <Input
                    error={error}
                    onChange={(e) => handleChangeCPF_CNPJ(e)}
                    style={{ width: 240, borderRadius: '5px' }}
                    placeholder="Digite o CPF/CNPJ"
                  />

                </Form.Item>

                <Form.Item>
                  <Button style={{ background: '#002D62', borderRadius: '5px' }} icon={<SearchOutlined />} loading={loading} type="primary" htmlType="submit" onClick={()=>history.push ("/")}>Buscar</Button>
                </Form.Item>
              </Form>
            </div> :

            <div style={{ height: '80px' }}>
              {(Client_Data.Id !== null && pathname !== '/contaResumida') &&
                <>
                  <Row gutter={[16, 0]} style={{ height: '20px' }}>
                    <Col span={2}>
                      <h4 style={{ color: '#002D62', fontWeight: '600', textAlign: 'center' }}>Segmento</h4>
                    </Col>
                    <Divider style={{ color: '#002D62', height: '60px' }} type="vertical" />
                    <Col span={3}>
                      <h4 style={{ color: '#002D62', fontWeight: '600' }}>{Client.CPF__c == null ? 'CNPJ' : 'CPF'}</h4>
                    </Col>
                    <Col span={4}>
                      <h4 style={{ color: '#002D62', fontWeight: '600' }}>Nome</h4>
                    </Col>
                    <Col span={2}>
                      <h4 style={{ color: '#002D62', fontWeight: '600' }}>CEP</h4>
                    </Col>
                    <Col span={3}>
                      <h4 style={{ color: '#002D62', fontWeight: '600' }}>Endereço</h4>
                    </Col>
                    <Col span={2}>
                      <h4 style={{ color: '#002D62', fontWeight: '600' }}>Telefone 1:</h4>
                    </Col>
                    <Col span={3}>
                      <h4 style={{ color: '#002D62', fontWeight: '600' }}>Email</h4>
                    </Col>
                    <Col span={3}>
                      <Row onClick={() => setVisible(true)} >
                        <img alt={'seach'} src={edit} style={{ marginTop: '5%', marginLeft: '15px', alignSelf: 'center', justifySelf: 'center' }} />
                        <h4 style={{ marginTop: '10%', color: '#002D62', fontWeight: '600', alignSelf: 'center', justifySelf: 'center', cursor: 'pointer' }}>Atualizar Dados</h4>
                      </Row>
                    </Col>
                    <Col span={1}>
                      <img onClick={() => ClientDataClean()} src={exit} style={{ marginTop: '35%', alignSelf: 'center', justifySelf: 'center' }} alt="sair" />
                    </Col>
                  </Row>

                  <Row gutter={[16, 0]} style={{ height: '40px' }}>
                    <Col span={2}  >
                      <h3 style={{ color: '#002D62', fontWeight: '600', textAlign: 'center' }}>{Client.Segmenta_o_do_consumidor__c}</h3>
                    </Col>
                    <Divider style={{ color: '#002D62' }} type="vertical" />
                    <Col span={3}>
                      <h4 style={{ color: '#000000', fontWeight: '500' }}>{Client.CNPJ__c == null ? Client.CPF__c : Client.CNPJ__c}</h4>
                    </Col>
                    <Col span={4}>
                      <h4 style={{ color: '#000000', fontWeight: '500' }}>{Client.Name}</h4>
                    </Col>
                    <Col span={2}>
                      <h4 style={{ color: '#000000', fontWeight: '500' }}>{Client.BillingPostalCode}</h4>
                    </Col>
                    <Col span={3}>
                      <h4 style={{ color: '#000000', fontWeight: '500' }}>{Client.BillingStreet}</h4>
                    </Col>
                    <Col span={2}>
                      <h4 style={{ color: '#000000', fontWeight: '500' }}>{normalizePhone(Client.Phone.replace('+55', ""), '')}</h4>
                    </Col>
                    <Col span={3}>
                      <h4 style={{ color: '#000000', fontWeight: '500' }}>{Client.E_Mail__c}</h4>
                    </Col>
                  </Row>
                </>
              }
              {(Client_Data.Id !== null && pathname === '/contaResumida') &&
                <>
                  <Row gutter={[16, 0]} style={{ height: '20px' }}>
                    <Col span={2}>
                      <h4 style={{ color: '#002D62', fontWeight: '600', textAlign: 'center' }}>Segmento</h4>
                    </Col>
                    <Divider style={{ color: '#002D62', height: '60px' }} type="vertical" />
                    <Col span={3}>
                      <h4 style={{ color: '#002D62', fontWeight: '600' }}>{Client.CPF__c == null ? 'CNPJ' : 'CPF'}</h4>
                    </Col>
                    <Col span={4}>
                      <h4 style={{ color: '#002D62', fontWeight: '600' }}>Nome</h4>
                    </Col>
                    <Col span={2}>
                      <h4 style={{ color: '#002D62', fontWeight: '600' }}>CEP</h4>
                    </Col>
                    <Col span={3}>
                      <h4 style={{ color: '#002D62', fontWeight: '600' }}>Endereço</h4>
                    </Col>
                    <Col span={2}>
                      <h4 style={{ color: '#002D62', fontWeight: '600' }}>Telefone 1:</h4>
                    </Col>
                    <Col span={3}>
                      <h4 style={{ color: '#002D62', fontWeight: '600' }}>Email</h4>
                    </Col>
                    <Col span={3}>
                      <Row onClick={() => setVisible(true)} >
                        <img alt={'seach'} src={edit} style={{ marginTop: '5%', marginLeft: '15px', alignSelf: 'center', justifySelf: 'center' }} />
                        <h4 style={{ marginTop: '10%', color: '#002D62', fontWeight: '600', alignSelf: 'center', justifySelf: 'center', cursor: 'pointer' }}>Atualizar Dados</h4>
                      </Row>
                    </Col>
                    <Col span={1}>
                      <img onClick={() => ClientDataClean()} src={exit} style={{ marginTop: '35%', alignSelf: 'center', justifySelf: 'center' }} alt="sair" />
                    </Col>
                  </Row>

                  <Row gutter={[16, 0]} style={{ height: '40px' }}>
                    <Col span={2}  >
                      <h3 style={{ color: '#002D62', fontWeight: '600', textAlign: 'center' }}>{Client.Segmenta_o_do_consumidor__c}</h3>
                    </Col>
                    <Divider style={{ color: '#002D62' }} type="vertical" />
                    <Col span={3}>
                      <h4 style={{ color: '#000000', fontWeight: '500' }}>{Client.CNPJ__c == null ? Client.CPF__c : Client.CNPJ__c}</h4>
                    </Col>
                    <Col span={4}>
                      <h4 style={{ color: '#000000', fontWeight: '500' }}>{Client.Name}</h4>
                    </Col>
                    <Col span={2}>
                      <h4 style={{ color: '#000000', fontWeight: '500' }}>{Client.BillingPostalCode}</h4>
                    </Col>
                    <Col span={3}>
                      <h4 style={{ color: '#000000', fontWeight: '500' }}>{Client.BillingStreet}</h4>
                    </Col>
                    <Col span={2}>
                      <h4 style={{ color: '#000000', fontWeight: '500' }}>{normalizePhone(Client.Phone.replace('+55', ""), '')}</h4>
                    </Col>
                    <Col span={3}>
                      <h4 style={{ color: '#000000', fontWeight: '500' }}>{Client.E_Mail__c}</h4>
                    </Col>
                  </Row>
                </>
              }
              {(!Client_Data.Id && Client.Lead && pathname !== '/') &&
                <>
                  <Row gutter={[16, 0]} style={{ height: '20px' }}>
                    <Col span={6}>
                      <h4 style={{ color: '#002D62', fontWeight: '600' }}>{Client.Lead.CPF__c == null ? 'CNPJ' : 'CPF'}</h4>
                    </Col>
                    <Col span={6}>
                      <h4 style={{ color: '#002D62', fontWeight: '600' }}>Nome</h4>
                    </Col>
                    <Col span={6}>
                      <h4 style={{ color: '#002D62', fontWeight: '600' }}>Email</h4>
                    </Col>
                    <Col span={4}>
                      <Row onClick={() => setVisible(true)} >
                        <img alt={'seach'} src={edit} style={{ marginTop: '5%', marginLeft: '15px', alignSelf: 'center', justifySelf: 'center' }} />
                        <h4 style={{ marginTop: '10%', color: '#002D62', fontWeight: '600', alignSelf: 'center', justifySelf: 'center', cursor: 'pointer' }}>Atualizar Dados</h4>
                      </Row>
                    </Col>
                    <Col span={2}>
                      <img onClick={() => ClientDataClean()} src={exit} style={{ marginTop: '35%', alignSelf: 'center', justifySelf: 'center' }} alt="sair" />
                    </Col>
                  </Row>

                  <Row gutter={[16, 0]} style={{ height: '40px' }}>
                    <Col span={6}>
                      <h4 style={{ color: '#000000', fontWeight: '500' }}>{Client.Lead.CNPJ__c == null ? Client.Lead.CPF__c : Client.Lea.CNPJ__c}</h4>
                    </Col>
                    <Col span={6}>
                      <h4 style={{ color: '#000000', fontWeight: '500' }}>{Client.Lead.Name}</h4>
                    </Col>
                    <Col span={6}>
                      <h4 style={{ color: '#000000', fontWeight: '500' }}>{Client.Lead.E_Mail__c ? Client.Lead.E_Mail__c: 'Consumidor não possui e-mail' }</h4>
                    </Col>
                  </Row>

                  <Row gutter={[16, 0]} style={{ height: '40px' }}>
                    <Col span={6}>
                      <Button 
                        style={{ background: '#002D62', borderRadius: '5px', float: 'left' }} 
                        onClick={() => createClient()} 
                        type="primary"
                      > 
                        Criar conta detalhada  
                      </Button>
                    </Col>
                  </Row>
                  
                </>
              }

              <GenesysInfo />
            </div>
          }

        </Card>
      </Layout >
    </div >
  )
}
