import styled from "styled-components";

export const Layout = styled.div`
  background-color: #F1F2F5!important;

  .cardColor {
    background-color: #E4E8EE;
  }

  .boletoForm {
    width: 400px;
  }

  .boxShadow {
    box-shadow: 0 2px 3px #00000029;
  }


  .image {
    width: 200px;
    height: 200px;
    marginBotton:20px;
    alignSelf:center; 
    justifySelf:center;
    position: relative;
  }
`;

export const AccountBtn = styled.button`
  border-radius: 6px;
  width: 40%;
  min-height: 100px;
  font-size: 20px;
  background: #002D62;
  color: white;
  border: 0;
  ;
  margin: 25px 5%;

  img {
    width: 40px;
    margin: 10px;
  }
`;
