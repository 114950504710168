import { LOGOUT, LOGIN_USER_SUCCESS, SET_TOKEN } from "./constants";

export const setCurrentUser = (user_name) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: { user_name }
  };
};

export const logOut = () => {
  return {
    type: LOGOUT
  };
};

export const loginUser = user => {
  return {
    payload: { user },
    type: LOGIN_USER_SUCCESS
  };
};

export const setToken = (auth) => {
  return {
    payload: { auth },
    type: SET_TOKEN
  };
};
