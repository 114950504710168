import {
  CalendarOutlined,
  EnvironmentOutlined,
  ExceptionOutlined,
  FileTextOutlined,
  HistoryOutlined,
  ImportOutlined,
  InboxOutlined,
  InfoCircleFilled,
  LeftCircleOutlined,
  LoadingOutlined,
  LockFilled,
  PhoneOutlined,
  QuestionCircleFilled,
  QuestionCircleOutlined,
  RightCircleOutlined,
  SafetyOutlined,
  SearchOutlined,
  SettingOutlined,
  TeamOutlined,
  ToolOutlined,
  UnlockFilled,
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Card,
  Carousel,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
} from "antd";
import MaskedInput from "antd-mask-input";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import info from "../../Components/Images/eye.svg";
import griddis from "../../Components/Images/griddis.svg";
import manualproddis from "../../Components/Images/manualproddis.svg";
import photos from "../../Components/Images/photo.svg";
import placeholders from "../../Components/Images/placeholders.svg";
import plus from "../../Components/Images/plus.svg";
import search from "../../Components/Images/procurar.svg";
import searchWhite from "../../Components/Images/procurarSAE.svg";
import svo from "../../Components/Images/svo.svg";
import toolbox from "../../Components/Images/toolbox.svg";
import {
  CLEAN_CLIENT_PRODUCT_HISTORY,
  CLEAN_CLIENT_SELECT_PRODUCT,
  CLEAN_CLIENT_SELECT_SAE,
  SET_CLIENT_OPENSVO,
  SET_CLIENT_PRODUCTS,
  SET_CLIENT_PRODUCT_HISTORY,
  SET_CLIENT_SAE_LIST,
  SET_CLIENT_SELECTED_BKO,
  SET_CLIENT_SELECTED_CASE,
  SET_CLIENT_SELECTED_PRODUCT,
  SET_CLIENT_SELECTED_REGISTER,
  SET_CLIENT_SELECTED_SAE,
} from "../BuscaCPF/reducers";
import { LastCaseBKOModal, RegisterChamadaModal } from "../CasoBko";
import { Layout } from "./styles";

axios.defaults.withCredentials = true;

const { Option } = Select;

const TRANS_LINE = require("../../Components/data/TRANSLATE_LINE.json");

export const HomePage = () => {
  const history = useHistory();

  const Client = useSelector(({ Client }) => Client.client);
  const Products = useSelector(({ Client }) => Client.products);
  const SelectedProduct = useSelector(({ Client }) => Client.selected_product);
  const SelectedSAE = useSelector(({ Client }) => Client.selected_sae);
  const SelectedCase = useSelector(({ Client }) => Client.selected_case);
  const SelectedRegister = useSelector(
    ({ Client }) => Client.selected_register
  );
  const ProductHistory = useSelector(({ Client }) => Client.product_history);
  const SAEList = useSelector(({ Client }) => Client.sae_list);
  const SVOTrigger = useSelector(({ Client }) => Client.OPEN_SVO);
  const SelectedBKO = useSelector(({ Client }) => Client.selected_bko);

  const dispatch = useDispatch();

  let location = useLocation();

  const [form] = Form.useForm();

  const [productsListNames, setProductsListNames] = useState([]);
  const [addNewProduct, setAddNewProduct] = useState(false);
  const [needUpdate, setNeedUpdate] = useState(0);
  const [showProductPage, setShowProductPage] = useState(false);
  const [showHistoryPage, setShowHistoryPage] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [validateProductCode, setValidateProductCode] = useState("");
  const [validateDateInstall, setValidateDateInstall] = useState("");
  const [loadingCep, setloadingCep] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [loadingSAE, setLoadingSAE] = useState(false);
  const [loadingRequisition, setLoadingRequisition] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [needUpdateSAE, setNeedUpdateSAE] = useState(false);
  const [produtoAddressFlag, setProdutoAddressFlag] = useState(false);
  const [searchProductText, setSearchProductText] = useState("");
  const [cepSearched, setCepSearched] = useState(false);
  const [hasBairro, sethasBairro] = useState(false);
  const [hasLog, sethasLog] = useState(false);
  // Disable Button Chamada and Button Last Case
  const [chamadaDisable, setChamadaDisable] = useState(true);
  const [showLastCase, setShowLastCase] = useState(false);
  const [loadingBKO, setLoadingBKO] = useState(false);

  const RegistroChamadaData = {
    WhoId: "",
    WhatId: "",
    Subject: "",
    ActivityDate: "",
    Status: "",
    Priority: "",
    TaskSubtype: "",
    AreaResponsavel__c: "",
    Description: "",
  };

  const replaceName = {
    Color: "Cor__c",
    Tensao: "Tensao__c",
    N_NFe: "Numero_da_NF__c",
    Date_Install: "Date_Installed__c",
    Status: "Status__c",
    Address: {
      CEP: "Zip__c",
      Rua: "Street__c",
      Bairro: "Bairro__c",
      Proximidade: "Proximidade__c",
      City: "City__c",
      State: "State__c",
      Complemento: "complemento__c",
      Numero: "numero__c",
    },
  };

  const constNewProduct = {
    ID: "",
    Name: "",
    Color: "",
    Line: "",
    RecordType: "",
    Tensao: "",
    N_NFe: "",
    Date_Install: "",
    Status: "",
    Product_C: "",
    Code: "",
    Garantia: "",
    DaysOfBuy: "",
    Address: {
      CEP: "",
      TipoRua: "",
      Rua: "",
      Numero: "",
      Bairro: "",
      Proximidade: "",
      Complemento: "",
      City: "",
      State: "",
    },
  };

  const [productData, setProductData] = useState(constNewProduct);
  const [showRegistrarChamada, setShowRegistrarChamada] = useState(false);
  const slider = useRef();

  const validateBKO = async () => {
    setLoadingBKO(true);
    return await axios
      .get(process.env.REACT_APP_API_URL + `/api/search_BKO/`, {
        params: {
          cpf: Client.CPF__c ? Client.CPF__c : Client.CPNJ__c,
          product: SelectedProduct.RecordType,
          productId: SelectedProduct.ID,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setLoadingBKO(false);
        delete response.data.return.attributes;
        dispatch({
          type: SET_CLIENT_SELECTED_BKO,
          payload: response.data.return,
        });
        return response.data.return;
      })
      .catch((error) => {
        setLoadingBKO(false);
        return null;
      });
  };

  useEffect(() => {
    const bko = async () => {
      if (SelectedProduct !== null) {
        const existBko = await validateBKO();
        if (!existBko) {
          setChamadaDisable(true);
        } else {
          setChamadaDisable(false);
        }
      }
    };
    bko();
  }, [SelectedProduct]);

  useEffect(() => {
    if (Client.Id !== null && (Products.length === 0 || needUpdate !== 0)) {
      Products.length === 0 &&
        dispatch({ type: CLEAN_CLIENT_SELECT_SAE, payload: null });
      (Products.length === 0 || addNewProduct === true) &&
        dispatch({ type: CLEAN_CLIENT_SELECT_PRODUCT, payload: null });
      setLoadingProducts(true);
      var produtos = [];
      var date1 = new Date();

      axios
        .get(process.env.REACT_APP_API_URL + `/api/search_client_products`, {
          params: {
            data: Client.Id,
          },
        })
        .then((res) => {
          setLoadingProducts(false);
          if (res.data.return["totalSize"] === 0) {
            message.error(
              "Não foi encontrado nenhum produto para esse cliente"
            );
          } else {
            for (let index = 0; index < res.data.return["totalSize"]; index++) {
              var date2 = new Date(
                res.data.return["records"][index]["Date_Installed__c"]
              );
              var Difference_In_Days =
                (date1.getTime() - date2.getTime()) / (1000 * 3600 * 24);
              const Product = TRANS_LINE.find(
                (e) =>
                  e.LINHA_C === res.data.return["records"][index]["Linha__c"]
              );
              produtos.push({
                url:
                  "https://eluxproductsimages.s3-sa-east-1.amazonaws.com/images_by_code/" +
                  res.data.return["records"][index]["Name"] +
                  ".png",
                ID: res.data.return["records"][index]["Id"],
                Name: res.data.return["records"][index]["Nome_do_Produto__c"],
                Color: res.data.return["records"][index]["Cor__c"],
                Line: Product !== undefined ? Product["PRODUCT_LINE"] : null,
                RecordType:
                  Product !== undefined ? Product["RECORDTYPE"] : null,
                Tensao: res.data.return["records"][index]["Tensao__c"],
                N_NFe: res.data.return["records"][index]["Numero_da_NF__c"],
                Date_Install:
                  res.data.return["records"][index]["Date_Installed__c"],
                Status: res.data.return["records"][index]["Status__c"],
                Product_C: res.data.return["records"][index]["Product__c"],
                Code: res.data.return["records"][index]["Name"],
                Garantia: parseInt(Difference_In_Days) >= 365 ? false : true,
                Modelo_Comercial__c:
                  res.data.return["records"][index]["Modelo_Comercial__c"],
                PNC_ML__c: res.data.return["records"][index]["PNC_ML__c"],
                Data_NF__c: res.data.return["records"][index]["Data_NF__c"],
                Marca__c: res.data.return["records"][index]["Marca__c"],
                Serial_Lot_Number__c:
                  res.data.return["records"][index]["Serial_Lot_Number__c"],
                Premi__c: res.data.return["records"][index]["Premi__c"],
                DaysOfBuy: parseInt(Difference_In_Days),
                Address: {
                  CEP: res.data.return["records"][index]["Zip__c"],
                  Rua: res.data.return["records"][index]["Street__c"],
                  Numero: res.data.return["records"][index]["numero__c"],
                  Bairro: res.data.return["records"][index]["Bairro__c"],
                  Proximidade:
                    res.data.return["records"][index]["Proximidade__c"],
                  Complemento:
                    res.data.return["records"][index]["complemento__c"],
                  City: res.data.return["records"][index]["City__c"],
                  State: res.data.return["records"][index]["State__c"],
                },
              });
            }

            (Products.length === 0 || addNewProduct === true) &&
              dispatch({
                type: SET_CLIENT_SELECTED_PRODUCT,
                payload: produtos[0],
              });

            if (
              addNewProduct === false &&
              needUpdate !== 0 &&
              SelectedProduct !== null
            ) {
              produtos.forEach((product, index_) => {
                if (product["ID"] === SelectedProduct.ID) {
                  dispatch({
                    type: SET_CLIENT_SELECTED_PRODUCT,
                    payload: product,
                  });
                  setNeedUpdateSAE(true);
                }
              });
            }

            dispatch({ type: SET_CLIENT_PRODUCTS, payload: produtos });

            getProductsNames(produtos);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingProducts(false);
          message.error("Houve um erro ao buscar produtos do cliente");
        });
    } else if (productsListNames.length === 0 && Products.length !== 0) {
      getProductsNames(Products);

      if (slider.current !== undefined)
        Products.forEach((element, index_) => {
          if (element["ID"] === SelectedProduct.ID) {
            slider.current.goTo(index_);
          }
        });
    }

    if (
      SelectedProduct !== null &&
      SelectedProduct.Address.CEP !== SelectedSAE.AddressSearched.CEP
    ) {
      setNeedUpdateSAE(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Client, needUpdate]);

  useEffect(async () => {
    const date = new Date().toISOString();
    console.log('Test 1.7 => 07/02/2022 Sensidea Rev 6')
    // await axios.post(
    //   process.env.REACT_APP_API_URL + "/api/test",
    //   {
    //     message: date
    //   },
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }
    // ).then((res) => {
    //   console.log(res)
    // }).catch((err) => {
    //   console.log(err)
    // })
  }, [])

  function updateProduct() {
    let Product_changes = {};
    let withWarranty = false;
    let ProductData = productData;
    if (addNewProduct === false) {
      for (var key in ProductData) {
        if (ProductData[key] !== SelectedProduct[key]) {
          if (key === "Address")
            for (var key2 in ProductData[key]) {
              if (key2 === "Rua") {
                Product_changes = {
                  ...Product_changes,
                  [replaceName["Address"][key2]]:
                    ProductData["Address"]["TipoRua"] +
                    " " +
                    ProductData["Address"][key2],
                };
              } else if (key2 !== "TipoRua") {
                Product_changes = {
                  ...Product_changes,
                  [replaceName["Address"][key2]]: ProductData["Address"][key2],
                };
              }
            }
          else
            Product_changes = {
              ...Product_changes,
              [replaceName[key]]: ProductData[key],
            };
        }
      }
    }

    if (addNewProduct === true && validateDateInstall === "success") {
      var given = moment(ProductData.Date_Install, "YYYY-MM-DD");
      var current = moment().startOf("day");
      var Difference_In_Days = moment.duration(current.diff(given)).asDays();
      Difference_In_Days <= 365 && (withWarranty = true);

      if (withWarranty) {
        let exp_date = given.add(365, "d");

        ProductData = {
          ...ProductData,
          WarrantyEnd: exp_date.format("YYYY-MM-DD"),
        };
      } else delete ProductData["WarrantyEnd"];
    }

    if (addNewProduct === false && Object.keys(Product_changes).length === 0) {
      message.error("Nenhuma alteração feita");
    } else {
      setLoadingRequisition(true);
      axios
        .request({
          method: addNewProduct === true ? "post" : "patch",
          url:
            process.env.REACT_APP_API_URL +
            "/api/" +
            (addNewProduct === true ? "create_product" : "update_product"),
          data: {
            product_data:
              addNewProduct === true ? ProductData : Product_changes,
            product_id: addNewProduct === true ? null : ProductData.ID,
            createWarranty: withWarranty,
            client_id: Client["Id"],
          },
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          setNeedUpdate(needUpdate + 1);
          setLoadingRequisition(false);
          closeProductWindow();
          message.success(
            "Produto " +
              (addNewProduct === true ? "criado" : "atualizado") +
              " com sucesso."
          );
        })
        .catch((error) => {
          console.log(error.response);
          setLoadingRequisition(false);
          let error_msg =
            error.response !== undefined
              ? error.response.data.return
              : "Erro ao " +
                (addNewProduct === true ? "criar produto" : "atualizar dados") +
                ", tente novamente mais tarde.";
          message.error(error_msg);
        });
    }
  }

  async function searchProduct(name) {
    if (String(name).length >= 4) {
      setLoadingCode(true);
      axios
        .request({
          method: "get",
          url: process.env.REACT_APP_API_URL + "/api/searchproduct",
          params: {
            data: name.replace(/ /g, ""),
          },
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          setLoadingCode(false);
          setProductData({
            ...productData,
            Product_C: res.data.return.Id,
            Name: res.data.return.ProductCode,
          });

          form.setFieldsValue({
            product_name: res.data.return.ProductCode,
          });
          setValidateProductCode("sucess");
        })
        .catch((error) => {
          console.log(error.response);
          setLoadingCode(false);

          if (error.response.status === 404)
            message.error("Não foi encontrado um produto com este código.");
          else
            message.error(
              error.response !== undefined
                ? error.response.data.return
                : "Houve um erro ao buscar código tente novamente mais tarde."
            );
        });
    } else {
      message.info("Código incompleto");
    }
  }

  async function searchCep(cep) {
    if (String(cep).replace(/\D/g, "").length === 8) {
      setloadingCep(true);
      axios
        .get(process.env.REACT_APP_API_URL + `/api/searchcep`, {
          params: {
            data: cep.replace(/\D/g, ""),
          },
        })
        .then((res) => {
          setloadingCep(false);
          setCepSearched(true);
          if (res.data.return.length === 0) {
            message.info("Endereço não encontrado para o CEP informado");
          } else {
            if (
              res.data.return[0].Bairro_inicial__c === "" &&
              res.data.return[0].Logradouro__c === ""
            ) {
              message.info(
                "Verifique se a cidade tem CEP único, nesse caso, preencha o campo Bairro como 'Centro'"
              );
            }

            form.setFieldsValue({
              product_bairro: res.data.return[0].Bairro_inicial__c,
              product_street: res.data.return[0].Logradouro__c,
              product_type_street: res.data.return[0].Tipo_de_logradouro__c,
              product_estado: res.data.return[0].Estado__c,
              product_cidade: res.data.return[0].Cidade__c,
              product_numero: "",
              product_prox: "",
              product_complement: "",
            });
            setProductData({
              ...productData,
              Address: {
                CEP: cep,
                TipoRua: res.data.return[0].Tipo_de_logradouro__c,
                Rua: res.data.return[0].Logradouro__c,
                Numero: "",
                Bairro: res.data.return[0].Bairro_inicial__c,
                Proximidade: "",
                Complemento: "",
                City: res.data.return[0].Cidade__c,
                State: res.data.return[0].Estado__c,
              },
            });

            res.data.return[0].Logradouro__c.length < 2
              ? sethasLog(false)
              : sethasLog(true);
            res.data.return[0].Bairro_inicial__c.length < 2
              ? sethasBairro(false)
              : sethasBairro(true);
          }
        })
        .catch((error) => {
          setloadingCep(false);
          message.error("Houve um erro ao consultar dados do CEP");
        });
    } else {
      message.info("CEP Incompleto");
    }
  }

  function searchSAE() {
    setLoadingSAE(true);

    let searchType =
      SelectedProduct.Line === "Linha Eletroportáteis" ||
      SelectedProduct.Line === "Purificadores de Água" ||
      SelectedProduct.Line === "Fornos de microondas"
        ? "2"
        : "1";

    axios
      .get(process.env.REACT_APP_API_URL + `/api/search_sae`, {
        params: {
          type: searchType,
          searchType: "ZIP",
          data: SelectedProduct.Address.CEP,
        },
      })
      .then((res) => {
        setLoadingSAE(false);
        if (res.data.return.ResultsNumber > 0) {
          dispatch({
            type: SET_CLIENT_SAE_LIST,
            payload: {
              SAE_Results: res.data.return.ResultsNumber,
              SAE_Type:
                searchType === "1" ? "Eletrodomésticos" : "Eletroportáteis",
              SaeData: res.data.return.Results,
            },
          });
          dispatch({
            type: SET_CLIENT_SELECTED_SAE,
            payload: res.data.return.Results[0],
          });
          setNeedUpdateSAE(false);
        } else {
          message.error(
            "Não foi possivel encontrar um SAE para o Produto Selecionado"
          );
        }
      })
      .catch((error) => {
        setLoadingSAE(false);
        message.error(
          error.response.data.return !== undefined
            ? error.response.data.return
            : "Houve um erro ao buscar SAE"
        );
      });
  }

  function searchProductHistory() {
    dispatch({ type: CLEAN_CLIENT_PRODUCT_HISTORY, payload: null });

    setLoadingHistory(true);
    axios
      .get(process.env.REACT_APP_API_URL + `/api/search_product_history`, {
        params: {
          data: SelectedProduct.ID,
        },
      })
      .then((res) => {
        setLoadingHistory(false);
        let productHistory = res.data.return;

        if (res.data.ResultsNumber > 0) {
          message.success("Histórico carregado");
          productHistory.forEach(function (item, index1) {
            item.Case_History !== null &&
              item.Case_History.forEach(function (item, index2) {
                let HasCompletionDate = moment(item.CompletionDate).isValid()
                  ? true
                  : false;
                item.IsViolated = HasCompletionDate
                  ? moment(item.CompletionDate).isBefore(
                      moment(item.TargetDate)
                    )
                    ? true
                    : false
                  : moment(item.TargetDate).isBefore(moment(), "hour")
                  ? false
                  : true;
                item.TimeRemainingInDays = HasCompletionDate
                  ? null
                  : moment
                      .duration(moment().diff(moment(item.TargetDate)))
                      .format("D [dias] e H [horas] ");
                productHistory[index1].Case_History[index2] = item;
              });
          });
        } else message.warning("Não existe histórico para esse produto");

        dispatch({
          type: SET_CLIENT_PRODUCT_HISTORY,
          payload: {
            Product_ID: SelectedProduct.ID,
            Cases: productHistory,
          },
        });
        dispatch({
          type: SET_CLIENT_SELECTED_CASE,
          payload: res.data.ResultsNumber > 0 ? productHistory[0] : null,
        });
      })
      .catch((error) => {
        console.log(error);
        setLoadingHistory(false);
        message.error(
          error.response !== undefined &&
            error.response.data.return !== undefined
            ? error.response.data.return
            : "Houve um erro ao buscar o historico do produto"
        );
      });
  }

  function handleChangeData(event, type) {
    if (type === "Code") {
      let code = event.target.value.toUpperCase();
      setProductData({
        ...productData,
        Code: code,
      });
      if (validateProductCode === "sucess") {
        setValidateProductCode("");
        setProductData({
          ...productData,
          Product_C: "",
          Name: "",
        });

        form.setFieldsValue({
          product_name: "",
        });
      }
    } else if (type === "N_NFe") {
      setProductData({
        ...productData,
        N_NFe: event.target.value.replace(/\D/g, ""),
      });
    } else if (type === "Status" || type === "Tensao" || type === "Color") {
      setProductData({ ...productData, [type]: event });
    } else if (
      type === "CEP" ||
      type === "Rua" ||
      type === "Bairro" ||
      type === "Proximidade" ||
      type === "City" ||
      type === "State" ||
      type === "Numero" ||
      type === "Complemento"
    ) {
      setProductData({
        ...productData,
        Address: { ...productData.Address, [type]: event.target.value },
      });
    } else if (type === "TipoRua") {
      setProductData({
        ...productData,
        Address: { ...productData.Address, [type]: event },
      });
    } else if (type === "Date_Install") {
      if (
        String(event.target.value.replace(/_/g, "")).length === 10 &&
        moment(event.target.value.replace(/-/g, "/"), "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ) !== "Invalid date"
      ) {
        setValidateDateInstall("success");
        setProductData({
          ...productData,
          Date_Install: moment(
            event.target.value.replace(/-/g, "/"),
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD"),
        });
      } else {
        setValidateDateInstall("error");
        setProductData({ ...productData, Date_Install: event.target.value });
      }
    } else {
      setProductData({ ...productData, [type]: event.target.value });
    }
  }

  function closeProductWindow() {
    cleanProductWindow();
    setShowProductPage(false);
    setCepSearched(false);
  }

  function cleanProductWindow() {
    setProdutoAddressFlag(false);
    setProductData(constNewProduct);
    setValidateProductCode("");
    form.setFieldsValue({
      product_color: "",
      product_voltage: "",
      user_buydate: "",
      product_nfe: "",
      product_status: "",
      product_name: "",
      product_code: "",
      product_cep: "",
      product_bairro: "",
      product_type_street: "",
      product_street: "",
      product_numero: "",
      product_estado: "",
      product_cidade: "",
      product_prox: "",
      product_complement: "",
    });
  }

  const divideStreet = (street) => {
    const kindOfStreets = [
      "Aeroporto",
      "Alameda",
      "Área",
      "Avenida",
      "Campo",
      "Chácara",
      "Colônia",
      "Condomínio",
      "Conjunto",
      "Distrito",
      "Esplanada",
      "Estação",
      "Estrada",
      "Favela",
      "Fazen",
      "Feira",
      "Jardim",
      "Ladeira",
      "Lago",
      "Lagoa",
      "Largo",
      "Loteamento",
      "Morro",
      "Núcleo",
      "Parque",
      "Passarela",
      "Pátio",
      "Praça",
      "Quadra",
      "Recanto",
      "Residencial",
      "Rodovia",
      "Rua",
      "Setor",
      "Sítio",
      "Travessa",
      "Trecho",
      "Trevo",
      "Vale",
      "Vereda",
      "Via",
      "Viaduto",
      "Viela",
      "Vila",
    ];

    // Separate the street name by ',' and spaces
    const parts = street.replace(",", " ").split(" ");

    let kind = "",
      newStreet = street,
      num = "";

    // Check if the first element is a kind of street
    if (kindOfStreets.includes(parts[0])) {
      kind = parts[0];
    }

    // Check is the last element is a number
    if (!isNaN(parts[parts.length - 1])) {
      num = parts[parts.length - 1];
    }

    // If the street name contains a kind of street, we remove them
    if (kind !== "") newStreet = street.replace(kind + " ", "");

    // If the street name contains a number at end, we remove them
    if (num !== "") {
      if (newStreet.includes(", " + num))
        newStreet = newStreet.replace(", " + num, "");
      else if (newStreet.includes("," + num))
        newStreet = newStreet.replace("," + num, "");
      else newStreet = newStreet.replace(num, "");
    }

    return [kind, newStreet, num];
  };

  function openProductWindow(mode) {
    cleanProductWindow();
    setCepSearched(false);

    if (mode === "NEW") {
      const streetDivided = divideStreet(Client.BillingStreet);
      setAddNewProduct(true);
      form.setFieldsValue({
        product_cep: Client.BillingPostalCode,
        product_bairro: Client.Bairro__c,
        product_type_street: streetDivided[0],
        product_street: streetDivided[1],
        product_numero:
          Client.Numero__c === null ? streetDivided[2] : Client.Numero__c,
        product_estado: Client.BillingState,
        product_cidade: Client.BillingCity,
        product_prox: Client.Proximidade__c,
        product_complement: Client.Complemento__c,
      });
      setProductData({
        ...constNewProduct,
        Address: {
          CEP: Client.BillingPostalCode,
          TipoRua: Client.Tipo_de_logradouro__c,
          Rua: Client.BillingStreet,
          Numero: Client.Numero__c,
          Bairro: Client.Bairro__c,
          Proximidade: Client.Proximidade__c,
          Complemento: Client.Complemento__c,
          City: Client.BillingCity,
          State: Client.BillingState,
        },
      });
    } else {
      const streetDivided = divideStreet(
        SelectedProduct.Address.Rua,
        SelectedProduct.Address.Numero
      );
      setAddNewProduct(false);
      setProductData(SelectedProduct);
      form.setFieldsValue({
        product_color: SelectedProduct.Color,
        product_voltage: SelectedProduct.Tensao,
        user_buydate: moment(SelectedProduct.Date_Install, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        ),
        product_nfe: SelectedProduct.N_NFe,
        product_status: SelectedProduct.Status,
        product_name: SelectedProduct.Name,
        product_code: SelectedProduct.Code,
        product_cep: SelectedProduct.Address.CEP,
        product_bairro: SelectedProduct.Address.Bairro,
        product_type_street: streetDivided[0],
        product_street: streetDivided[1],
        product_numero:
          SelectedProduct.Address.Numero === null
            ? streetDivided[2]
            : SelectedProduct.Address.Numero,
        product_estado: SelectedProduct.Address.State,
        product_cidade: SelectedProduct.Address.City,
        product_prox: SelectedProduct.Address.Proximidade,
        product_complement: SelectedProduct.Address.Complemento,
      });

      setValidateProductCode("sucess");
      if (
        Client.BillingPostalCode.replace("-", "") ===
        SelectedProduct.Address.CEP.replace("-", "")
      )
        setProdutoAddressFlag(false);
      else setProdutoAddressFlag(true);
    }
    setShowProductPage(true);
  }

  function onChangeProdutoAddress(event) {
    setProdutoAddressFlag(!produtoAddressFlag);
  }

  function openSVO_Trigger() {
    let productType =
      SelectedProduct.Line === "Linha Eletroportáteis" ||
      SelectedProduct.Line === "Purificadores de Água" ||
      SelectedProduct.Line === "Fornos de microondas"
        ? "2"
        : "1";

    if (productType === "1")
      dispatch({ type: SET_CLIENT_OPENSVO, payload: true });
    else message.warning("SVOs não podem ser abertas para produtos de balcão");
  }

  function getProductsNames(productList) {
    let ProductsList = [];
    productList.forEach((product) => {
      let Name =
        product["Name"] +
        " - " +
        (product["N_NFe"] !== null
          ? product["N_NFe"]
          : "(SN/" + product["ID"].slice(-8, -3) + ")");
      ProductsList.push({ value: Name, key: product["ID"] });
    });

    setProductsListNames(ProductsList);
  }

  const onChangeSearchText = (value) => {
    setSearchProductText(value);
  };

  const onSelectProduct = (value, data) => {
    let SelectIndex = null;
    Products.forEach((element, index_) => {
      if (element["ID"] === data.key) {
        SelectIndex = index_;
      }
    });

    setSearchProductText("");
    slider.current.goTo(SelectIndex);
  };

  const ChangeCase = (current) => {
    if (SelectedCase.Id !== ProductHistory.Cases[current].Id) {
      dispatch({
        type: SET_CLIENT_SELECTED_CASE,
        payload: ProductHistory.Cases[current],
      });
    }
  };

  const ChangeRegister = (current) => {
    if (SelectedRegister.Id !== Client.LeadRegisters[current].Id) {
      dispatch({
        type: SET_CLIENT_SELECTED_REGISTER,
        payload: Client.LeadRegisters[current],
      });
    }
  };

  function openCaseDetails() {
    setShowHistoryPage(true);
  }

  function closeCaseDetails() {
    setShowHistoryPage(false);
  }

  const afterChange = (current) => {
    setChamadaDisable(true);
    if (SelectedProduct.ID !== Products[current].ID) {
      dispatch({
        type: SET_CLIENT_SELECTED_PRODUCT,
        payload: Products[current],
      });
      setLoadingSAE(true);
      let productType =
        Products[current].Line === "Linha Eletroportáteis" ||
        Products[current].Line === "Purificadores de Água" ||
        Products[current].Line === "Fornos de microondas"
          ? "Eletroportáteis"
          : "Eletrodomésticos";
      if (
        Products[current].Address.CEP !== SelectedSAE.AddressSearched.CEP ||
        productType !== SelectedSAE.Type
      ) {
        setNeedUpdateSAE(true);
        setLoadingSAE(false);
      } else {
        setNeedUpdateSAE(false);
        setLoadingSAE(false);
      }
    }
  };

  const RightArrow = (COLOR) => {
    return (
      <Button
        style={{ padding: "1rem", height: "auto", width: "auto" }}
        icon={<RightCircleOutlined style={{ color: COLOR, fontSize: 30 }} />}
      />
    );
  };

  const LeftArrow = (COLOR) => {
    return (
      <Button
        style={{ padding: "1rem", height: "auto", width: "auto" }}
        icon={
          <LeftCircleOutlined
            style={{ color: COLOR, fontSize: 30, marginLeft: "-15px" }}
          />
        }
      />
    );
  };

  const RightArrowDown = (COLOR) => {
    return (
      <Button
        style={{ height: "bottom", width: "auto" }}
        icon={<RightCircleOutlined style={{ color: COLOR, fontSize: 30 }} />}
      />
    );
  };

  const LeftArrowDown = (COLOR) => {
    return (
      <Button
        style={{ height: "auto", width: "auto" }}
        icon={
          <LeftCircleOutlined
            style={{ color: COLOR, fontSize: 30, marginLeft: "-15px" }}
          />
        }
      />
    );
  };

  if (SVOTrigger === true) {
    return (
      <Redirect
        to={{
          pathname: "/svo",
          state: { from: location.pathname },
        }}
      ></Redirect>
    );
  }

  return (
    <Layout style={{ backgroundColor: "#FFF" }}>
      <RegisterChamadaModal
        visible={showRegistrarChamada}
        onCancel={() => setShowRegistrarChamada(false)}
      />

      <LastCaseBKOModal
        visible={showLastCase}
        onCancel={() => setShowLastCase(false)}
      />

      <Modal
        visible={showHistoryPage}
        onCancel={() => closeCaseDetails()}
        width={"65%"}
        footer={null}
      >
        <h2
          style={{
            color: "#002D62",
            fontWeight: "600",
            textAlign: "center",
            fontSize: "26px",
          }}
        >
          Detalhes das SVOs
        </h2>
        <Divider style={{ color: "#002D62" }} type="horizontal" />
        {SelectedCase !== null && SelectedCase.SVO !== null && (
          <div>
            {SelectedCase.SVO.map((SVO, index) => (
              <div
                style={{
                  marginTop: "2%",
                  marginBottom: "2%",
                  borderRadius: "5px",
                  background: "#efeff5",
                }}
              >
                <h3
                  style={{
                    color: "#002D62",
                    fontWeight: "600",
                    fontSize: "20px",
                    marginBottom: "0px",
                    marginTop: "5px",
                    textAlign: "center",
                  }}
                >
                  {SVO.Name}
                </h3>

                <div style={{ justifyContent: "center", display: "flex" }}>
                  <h3
                    style={{
                      color: "#000000",
                      fontWeight: "600",
                      fontSize: "17px",
                      marginBottom: "0px",
                      marginTop: "8px",
                      marginRight: "15px",
                    }}
                  >
                    Aberta: {moment(SVO.CreatedDate).format("DD/MM/YYYY HH:mm")}
                  </h3>
                  <h3
                    style={{
                      color: "#000000",
                      fontWeight: "600",
                      fontSize: "17px",
                      marginBottom: "0px",
                      marginTop: "8px",
                      marginLeft: "15px",
                    }}
                  >
                    Atualizada:{" "}
                    {moment(SVO.LastModifiedDate).format("DD/MM/YYYY HH:mm")}
                  </h3>
                </div>

                {SVO.Order_Status__c !== null && (
                  <div>
                    <h3
                      style={{
                        marginLeft: "1%",
                        color: "#BB8C48",
                        fontWeight: "600",
                        fontSize: "18px",
                        marginBottom: "0px",
                        marginTop: "-2px",
                      }}
                    >
                      Status:
                    </h3>
                    <h3
                      style={{
                        marginLeft: "2%",
                        color: "black",
                        fontSize: "18px",
                        marginTop: "-5px",
                      }}
                    >
                      {SVO.Order_Status__c}
                    </h3>{" "}
                  </div>
                )}
                {SVO.Motivo_cancelamento__c !== null && (
                  <div>
                    <h3
                      style={{
                        marginLeft: "1%",
                        color: "#BB8C48",
                        fontWeight: "600",
                        fontSize: "18px",
                        marginBottom: "0px",
                        marginTop: "-2px",
                      }}
                    >
                      Motivo do cancelamento:
                    </h3>
                    <h3
                      style={{
                        marginLeft: "2%",
                        color: "black",
                        fontSize: "18px",
                        marginTop: "-5px",
                      }}
                    >
                      {SVO.Motivo_cancelamento__c}
                    </h3>{" "}
                  </div>
                )}
                {SVO.Defeito_Reclamado__c !== null && (
                  <div>
                    <h3
                      style={{
                        marginLeft: "1%",
                        color: "#BB8C48",
                        fontWeight: "600",
                        fontSize: "18px",
                        marginBottom: "0px",
                        marginTop: "-2px",
                      }}
                    >
                      Defeito Reclamado:
                    </h3>
                    <h3
                      style={{
                        marginLeft: "2%",
                        color: "black",
                        fontSize: "18px",
                        marginTop: "-5px",
                      }}
                    >
                      {SVO.Defeito_Reclamado__c}
                    </h3>{" "}
                  </div>
                )}
                {SVO.DescricaoDefeito__c !== null && (
                  <div>
                    <h3
                      style={{
                        marginLeft: "1%",
                        color: "#BB8C48",
                        fontWeight: "600",
                        fontSize: "18px",
                        marginBottom: "0px",
                        marginTop: "-2px",
                      }}
                    >
                      Descrição:
                    </h3>
                    <h3
                      style={{
                        marginLeft: "2%",
                        color: "black",
                        fontSize: "18px",
                        marginTop: "-5px",
                      }}
                    >
                      {SVO.DescricaoDefeito__c}
                    </h3>{" "}
                  </div>
                )}
                {SVO.Problem_Description__c !== null && (
                  <div>
                    <h3
                      style={{
                        marginLeft: "1%",
                        color: "#BB8C48",
                        fontWeight: "600",
                        fontSize: "18px",
                        marginBottom: "0px",
                        marginTop: "-2px",
                      }}
                    >
                      Descrição Detalhada:
                    </h3>
                    <h3
                      style={{
                        marginLeft: "2%",
                        color: "black",
                        fontSize: "18px",
                        marginTop: "-5px",
                      }}
                    >
                      {SVO.Problem_Description__c}
                    </h3>{" "}
                  </div>
                )}
                {SVO.Detalhamento_do_servico_realizado__c !== null && (
                  <div>
                    <h3
                      style={{
                        marginLeft: "1%",
                        color: "#BB8C48",
                        fontWeight: "600",
                        fontSize: "18px",
                        marginBottom: "0px",
                        marginTop: "-2px",
                      }}
                    >
                      Serviço Realizado:
                    </h3>
                    <h3
                      style={{
                        marginLeft: "2%",
                        color: "black",
                        fontSize: "18px",
                        marginTop: "-5px",
                        marginBottom: "10px",
                      }}
                    >
                      {SVO.Detalhamento_do_servico_realizado__c}
                    </h3>{" "}
                  </div>
                )}
              </div>
            ))}
            <h2
              style={{
                color: "#002D62",
                fontWeight: "600",
                textAlign: "center",
                fontSize: "26px",
              }}
            >
              Histórico do caso
            </h2>
            <Divider style={{ color: "#002D62" }} type="horizontal" />
            <h3
              style={{
                color: "#BB8C48",
                fontWeight: "600",
                fontSize: "17px",
                marginBottom: "0px",
                marginTop: "-2px",
              }}
            >
              Problema Descrito:
            </h3>
            <h3
              style={{
                color: "#000000",
                fontSize: "19px",
                marginTop: "-5px",
              }}
            >
              {SelectedCase.Descri__c}
            </h3>
            <h3
              style={{
                color: "#BB8C48",
                fontWeight: "600",
                fontSize: "17px",
                marginBottom: "0px",
                marginTop: "-2px",
              }}
            >
              Status do caso:
            </h3>
            <h3
              style={{
                color: "#000000",
                fontSize: "19px",
                marginTop: "-5px",
              }}
            >
              {SelectedCase.status_do_caso__c}
            </h3>
            {SelectedCase.Case_History !== null &&
              SelectedCase.Case_History.map(
                (History, index) =>
                  History.NewValue !== null && (
                    <div
                      style={{
                        borderRadius: "5px",
                        background: "#CFAE7E",
                        marginBottom: "1%",
                      }}
                    >
                      <Row gutter={[16, 0]}>
                        <Col span={6} style={{ marginLeft: "10px" }}>
                          <h3
                            style={{
                              color: "#FFFF",
                              fontWeight: "600",
                              fontSize: "18px",
                              marginBottom: "0px",
                              marginTop: "-2px",
                            }}
                          >
                            Marco:
                          </h3>
                          <h3
                            style={{
                              color: "black",
                              fontWeight: "600",
                              fontSize: "18px",
                              marginTop: "-5px",
                            }}
                          >
                            {History.Milestone}
                          </h3>
                        </Col>
                        <Col span={5}>
                          <h3
                            style={{
                              color: "#FFFF",
                              fontWeight: "600",
                              fontSize: "18px",
                              marginBottom: "0px",
                              marginTop: "-2px",
                            }}
                          >
                            Início:
                          </h3>
                          <h3
                            style={{
                              color: "black",
                              fontWeight: "600",
                              fontSize: "18px",
                              marginTop: "-5px",
                            }}
                          >
                            {moment(History.NewVStartDatealue).isValid()
                              ? moment(History.StartDate).format(
                                  "DD/MM/YYYY HH:mm"
                                )
                              : History.StartDate}
                          </h3>
                        </Col>
                        <Col span={5}>
                          <h3
                            style={{
                              color: "#FFFF",
                              fontWeight: "600",
                              fontSize: "18px",
                              marginBottom: "0px",
                              marginTop: "-2px",
                            }}
                          >
                            Data destino:
                          </h3>
                          <h3
                            style={{
                              color: "black",
                              fontWeight: "600",
                              fontSize: "18px",
                              marginTop: "-5px",
                            }}
                          >
                            {moment(History.TargetDate).isValid()
                              ? moment(History.TargetDate).format(
                                  "DD/MM/YYYY HH:mm"
                                )
                              : History.TargetDate}
                          </h3>
                        </Col>
                        <Col span={5}>
                          <h3
                            style={{
                              color: "#FFFF",
                              fontWeight: "600",
                              fontSize: "18px",
                              marginBottom: "0px",
                              marginTop: "-2px",
                            }}
                          >
                            Conclusão:
                          </h3>
                          <h3
                            style={{
                              color: "black",
                              fontWeight: "600",
                              fontSize: "18px",
                              marginTop: "-5px",
                            }}
                          >
                            {moment(History.CompletionDate).isValid()
                              ? moment(History.CompletionDate).format(
                                  "DD/MM/YYYY HH:mm"
                                )
                              : History.CompletionDate}
                          </h3>
                        </Col>
                        {/* <Col span={2.5}>
                                        <h3 style={{ color: '#FFFF', fontWeight: '600', fontSize: '18px', marginBottom: '0px', marginTop: '-2px' }}>Expirou:</h3>
                                        <h3 style={{ color: History.IsViolated === false ? 'red' : 'green', fontWeight: '600', fontSize: '18px', marginTop: '-5px' }}>{History.IsViolated === true ? 'NÃO' : 'SIM'}</h3>
                                    </Col>
                                    {(History.TimeRemainingInDays !== null && History.TimeRemainingInDays !== 0) &&
                                        <Col span={4}>
                                            <h3 style={{ color: '#FFFF', fontWeight: '600', fontSize: '18px', marginBottom: '0px', marginTop: '-2px', marginRight: '8px' }}>Atraso:</h3>
                                            <h3 style={{ color: 'black', fontWeight: '600', fontSize: '18px', marginTop: '-5px', marginRight: '8px' }}>{History.TimeRemainingInDays}</h3>
                                        </Col>} */}
                      </Row>
                    </div>
                  )
              )}
          </div>
        )}
      </Modal>

      <Modal
        visible={showProductPage}
        onCancel={() => closeProductWindow()}
        width={"55%"}
        footer={null}
      >
        <h2
          style={{
            color: "#002D62",
            fontWeight: "600",
            textAlign: "center",
            fontSize: "26px",
          }}
        >
          {addNewProduct === true
            ? "Cadastrar novo produto"
            : "Informações do produto"}
        </h2>
        <Divider style={{ color: "#002D62" }} type="horizontal" />
        <Form
          form={form}
          name="updateProduct"
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          onFinish={() => updateProduct()}
        >
          <Row gutter={[16, 0]} style={{ height: "85px" }}>
            <Col span={8}>
              <label style={{ color: "#002D62", fontWeight: "500" }}>
                Código
              </label>
              <Form.Item
                name="product_code"
                hasFeedback
                validateStatus={validateProductCode}
                rules={[{ required: true, message: "Inserir o Código" }]}
                normalize={(value) => value.replace(" ", "").toUpperCase()}
              >
                <Input
                  suffix={
                    loadingCode === true ? (
                      <LoadingOutlined />
                    ) : (
                      <img
                        onClick={() => searchProduct(productData.Code)}
                        alt={"+"}
                        src={search}
                        style={{ height: "75%", marginRight: "2px" }}
                      />
                    )
                  }
                  size="medium"
                  onChange={(e) => handleChangeData(e, "Code")}
                  value={productData.Code}
                  disabled={!addNewProduct}
                  placeholder="Código"
                  maxLength={5}
                  onPressEnter={() => searchProduct(productData.Code)}
                />
              </Form.Item>
            </Col>

            <Col span={16}>
              <label style={{ color: "#002D62", fontWeight: "500" }}>
                Nome
              </label>
              <Form.Item
                name="product_name"
                rules={[{ required: true, message: "Pesquise o produto" }]}
              >
                <Input
                  disabled={true}
                  value={productData.Name}
                  maxLength={18}
                  placeholder={"Nome do produto"}
                  onChange={(e) => handleChangeData(e, "Name")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 1]} style={{ height: "80px" }}>
            <Col span={4}>
              <label style={{ color: "#002D62", fontWeight: "500" }}>
                Status
              </label>
              <Form.Item
                name="product_status"
                hasFeedback
                rules={[{ required: true, message: "Selecionar Status" }]}
              >
                <Select
                  disabled={validateProductCode === "sucess" ? false : true}
                  onChange={(e) => handleChangeData(e, "Status")}
                >
                  <Option value="Instalado">Instalado</Option>
                  <Option value="Não Instalado">Não Instalado</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <label style={{ color: "#002D62", fontWeight: "500" }}>
                Nota
              </label>
              <Form.Item
                name="product_nfe"
                rules={[{ required: false, message: "Inserir NFe" }]}
                normalize={(value) => value.replace(/\D/g, "")}
              >
                <Input
                  disabled={validateProductCode === "sucess" ? false : true}
                  value={productData.N_NFe}
                  placeholder={"Nº da nota Fiscal"}
                  maxLength={10}
                  onChange={(e) => handleChangeData(e, "N_NFe")}
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <label style={{ color: "#002D62", fontWeight: "500" }}>
                Data Compra
              </label>
              <Form.Item
                name="user_buydate"
                validateStatus={validateDateInstall}
                rules={[{ required: false, message: "Inserir Data" }]}
              >
                <MaskedInput
                  disabled={validateProductCode === "sucess" ? false : true}
                  value={moment(productData.Date_Install, "YYYY-MM-DD").format(
                    "DD-MM-YYYY"
                  )}
                  onChange={(e) => handleChangeData(e, "Date_Install")}
                  mask="11-11-1111"
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <label style={{ color: "#002D62", fontWeight: "500" }}>
                Tensão
              </label>
              <Form.Item
                name="product_voltage"
                hasFeedback
                rules={[{ required: true, message: "Selecionar Tensao" }]}
              >
                <Select
                  disabled={validateProductCode === "sucess" ? false : true}
                  value={productData.Tensao}
                  onChange={(e) => handleChangeData(e, "Tensao")}
                >
                  <Option value="110V">110V</Option>
                  <Option value="220V">220V</Option>
                  <Option value="Bivolt">Bivolt</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={4}>
              <label style={{ color: "#002D62", fontWeight: "500" }}>Cor</label>
              <Form.Item
                name="product_color"
                hasFeedback
                rules={[{ required: true, message: "Selecionar Cor" }]}
              >
                <Select
                  showSearch
                  disabled={validateProductCode === "sucess" ? false : true}
                  value={productData.Color}
                  onChange={(e) => handleChangeData(e, "Color")}
                >
                  <Option value="Branco">Branco</Option>
                  <Option value="Bege">Bege</Option>
                  <Option value="Inox">Inox</Option>
                  <Option value="Preto">Preto</Option>
                  <Option value="Outros">Outros</Option>
                </Select>
              </Form.Item>
              </Col>
            </Row>

          <Checkbox
            onChange={onChangeProdutoAddress}
            checked={produtoAddressFlag}
          >
            {" "}
            O produto foi instalado em endereço diferente da conta{" "}
          </Checkbox>

          <Row
            gutter={[16, 0]}
            style={{ height: "70px", marginTop: "25px", marginBottom: "5px" }}
          >
            <Col span={6}>
              <label style={{ color: "#002D62", fontWeight: "500" }}>CEP</label>
              <Form.Item
                name="product_cep"
                rules={[{ required: true, message: "Inserir CEP" }]}
              >
                <MaskedInput
                  value={productData.Address.CEP}
                  onChange={(e) => handleChangeData(e, "CEP")}
                  placeholder="CEP"
                  disabled={!produtoAddressFlag}
                  onPressEnter={() => {
                    searchCep(productData.Address.CEP);
                  }}
                  style={{ borderRadius: "5px" }}
                  maxLength={9}
                  mask="11111-111"
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <label style={{ color: "#002D62", fontWeight: "500" }}></label>
              <Button
                style={{ marginTop: "22px", borderRadius: "5px" }}
                icon={<SearchOutlined />}
                loading={loadingCep}
                disabled={loadingCep || !produtoAddressFlag}
                onClick={() => {
                  searchCep(productData.Address.CEP);
                }}
              >
                Buscar
              </Button>
            </Col>
          </Row>

          {produtoAddressFlag && (
            <Row
              gutter={[16, 0]}
              style={{
                height: "30px",
                marginTop: "5px",
                marginBottom: "15px",
                padding: "0px 10px",
                color: "rgba(0, 0, 0, 0.7)",
                fontSize: "14px",
              }}
            >
              {cepSearched === false ? (
                <>
                  Faza a busca pelo CEP para alterar as informações do endereço
                </>
              ) : (
                <>Complete as informações solicitadas no formulario</>
              )}
            </Row>
          )}

          <Row gutter={[16, 0]} style={{ height: "80px" }}>
            <Col span={6}>
              <label style={{ color: "#002D62", fontWeight: "500" }}>
                Tipo logradouro
              </label>
              <Form.Item
                name="product_type_street"
                rules={[
                  { required: true, message: "Inserir tipo de logradouro" },
                ]}
              >
                <Select
                  disabled={
                    !cepSearched ||
                    (cepSearched && productData.Address.TipoRua !== "")
                  }
                  onChange={(e) => handleChangeData(e, "TipoRua")}
                >
                  <Option value="" disabled={true} selected>
                    Selecione um tipo
                  </Option>
                  <Option value="Aeroporto">Aeroporto</Option>
                  <Option value="Alameda">Alameda</Option>
                  <Option value="Área">Área</Option>
                  <Option value="Avenida">Avenida</Option>
                  <Option value="Campo">Campo</Option>
                  <Option value="Chácara">Chácara</Option>
                  <Option value="Colônia">Colônia</Option>
                  <Option value="Condomínio">Condomínio</Option>
                  <Option value="Conjunto">Conjunto</Option>
                  <Option value="Distrito">Distrito</Option>
                  <Option value="Esplanada">Esplanada</Option>
                  <Option value="Estação">Estação</Option>
                  <Option value="Estrada">Estrada</Option>
                  <Option value="Favela">Favela</Option>
                  <Option value="Fazenda">Fazenda</Option>
                  <Option value="Feira">Feira</Option>
                  <Option value="Jardim">Jardim</Option>
                  <Option value="Ladeira">Ladeira</Option>
                  <Option value="Lago">Lago</Option>
                  <Option value="Lagoa">Lagoa</Option>
                  <Option value="Largo">Largo</Option>
                  <Option value="Loteamento">Loteamento</Option>
                  <Option value="Morro">Morro</Option>
                  <Option value="Núcleo">Núcleo</Option>
                  <Option value="Parque">Parque</Option>
                  <Option value="Passarela">Passarela</Option>
                  <Option value="Pátio">Pátio</Option>
                  <Option value="Praça">Praça</Option>
                  <Option value="Quadra">Quadra</Option>
                  <Option value="Recanto">Recanto</Option>
                  <Option value="Residencial">Residencial</Option>
                  <Option value="Rodovia">Rodovia</Option>
                  <Option value="Rua">Rua</Option>
                  <Option value="Setor">Setor</Option>
                  <Option value="Sítio">Sítio</Option>
                  <Option value="Travessa">Travessa</Option>
                  <Option value="Trecho">Trecho</Option>
                  <Option value="Trevo">Trevo</Option>
                  <Option value="Vale">Vale</Option>
                  <Option value="Vereda">Vereda</Option>
                  <Option value="Via">Via</Option>
                  <Option value="Viaduto">Viaduto</Option>
                  <Option value="Viela">Viela</Option>
                  <Option value="Vila">Vila</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={4}></Col>

            <Col span={10}>
              <label style={{ color: "#002D62", fontWeight: "500" }}>
                Logradouro
              </label>
              <Form.Item
                name="product_street"
                rules={[{ required: true, message: "Inserir Logradouro" }]}
              >
                <Input
                  value={productData.Address.Rua}
                  placeholder={"Logradouro"}
                  disabled={!cepSearched || (cepSearched && hasLog)}
                  onChange={(e) => handleChangeData(e, "Rua")}
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <label style={{ color: "#002D62", fontWeight: "500" }}>
                Número
              </label>
              <Form.Item
                name="product_numero"
                rules={[{ required: true, message: "Inserir Numero" }]}
                normalize={(value) => value.replace(/\D/g, "")}
              >
                <Input
                  disabled={!cepSearched}
                  value={parseInt(productData.Address.Numero)}
                  placeholder={"Nº do logradouro"}
                  maxLength={10}
                  onChange={(e) => handleChangeData(e, "Numero")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 0]} style={{ height: "80px" }}>
            <Col span={10}>
              <label style={{ color: "#002D62", fontWeight: "500" }}>
                Bairro
              </label>
              <Form.Item
                name="product_bairro"
                rules={[{ required: true, message: "Inserir Bairro" }]}
              >
                <Input
                  disabled={!cepSearched || (cepSearched && hasBairro)}
                  value={productData.Address.Bairro}
                  placeholder={"Bairro"}
                  onChange={(e) => handleChangeData(e, "Bairro")}
                />
              </Form.Item>
            </Col>

            <Col span={10}>
              <label style={{ color: "#002D62", fontWeight: "500" }}>
                Cidade
              </label>
              <Form.Item
                name="product_cidade"
                rules={[{ required: true, message: "Inserir Cidade" }]}
              >
                <Input
                  disabled={
                    !cepSearched ||
                    (cepSearched && productData.Address.City !== "")
                  }
                  value={productData.Address.City}
                  placeholder={"Cidade"}
                  onChange={(e) => handleChangeData(e, "City")}
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <label style={{ color: "#002D62", fontWeight: "500" }}>UF</label>
              <Form.Item
                name="product_estado"
                rules={[{ required: true, message: "UF" }]}
              >
                <Input
                  disabled={
                    !cepSearched ||
                    (cepSearched && productData.Address.State !== "")
                  }
                  placeholder={"UF"}
                  value={productData.Address.State}
                  onChange={(e) => handleChangeData(e, "State")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 0]} style={{ height: "80px" }}>
            <Col span={10}>
              <label style={{ color: "#002D62", fontWeight: "500" }}>
                Referência
              </label>
              <Form.Item
                name="product_prox"
                rules={[{ required: false, message: "Inserir Referência" }]}
              >
                <Input
                  disabled={!cepSearched}
                  placeholder={"Referência do endereço"}
                  value={productData.Address.Proximidade}
                  onChange={(e) => handleChangeData(e, "Proximidade")}
                />
              </Form.Item>
            </Col>
            <Col span={14}>
              <label style={{ color: "#002D62", fontWeight: "500" }}>
                Complemento
              </label>
              <Form.Item
                name="product_complement"
                rules={[{ required: false, message: "Inserir Complemento" }]}
              >
                <Input
                  disabled={!cepSearched}
                  value={productData.Address.Complemento}
                  placeholder={"Complemento do endereço"}
                  onChange={(e) => handleChangeData(e, "Complemento")}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* <Row gutter={[16, 1]} style={{ height: "80px" }}>
              <Col span={24}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Assistencia Tecnica Autorizada
                </label>
                <Form.Item
                  name="Razao_Social__c"
                  rules={[{ required: false, message: "Inserir " }]}
                  normalize={(value) => value.replace(/\D/g, "")}
                >
                  <Input
                    // disabled={validateProductCode === "sucess" ? false : true}
                    placeholder={"Assistencia Tecnica Autorizada"}
                    // maxLength={10}
                    onChange={(e) => handleChangeData(e, "Razao_Social__c")}
                  />
                </Form.Item>
              </Col>
            </Row> */}

          <Row type="flex" justify="center" align="end">
            <Form.Item style={{ marginTop: "25px" }}>
              <Button
                style={{ borderRadius: "5px" }}
                disabled={loadingRequisition}
                loading={loadingRequisition}
                type="primary"
                htmlType="submit"
              >
                Salvar
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>

      {Client.Id && (
        <div style={{ height: "100%", width: "100%" }}>
          <Row gutter={[0, 0]} style={{ marginLeft: "2%", paddingTop: "2%" }}>
            <Col span={11} style={{}}>
              <Row style={{ justifyContent: "space-between" }}>
                <h1
                  style={{
                    color: "#002D62",
                    fontWeight: "600",
                    textAlign: "left",
                    fontSize: "30px",
                  }}
                >
                  Produtos
                </h1>
                <AutoComplete
                  dropdownMatchSelectWidth={"52%"}
                  style={{
                    width: "52%",
                    marginTop: 10,
                    marginRight: 15,
                    borderRadius: "10px",
                  }}
                  disabled={loadingProducts || Products.length === 0}
                  value={searchProductText}
                  onSelect={onSelectProduct}
                  onChange={onChangeSearchText}
                  options={productsListNames}
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                >
                  <Input
                    suffix={
                      <img
                        alt={"+"}
                        src={search}
                        style={{
                          height: "16px",
                          marginRight: "2px",
                          alignSelf: "center",
                          justifySelf: "center",
                        }}
                      />
                    }
                    size="large"
                    placeholder="Buscar Produto"
                  />
                </AutoComplete>
              </Row>
              <Spin
                style={{
                  justifyItems: "center",
                  alignItems: "center",
                  marginTop: "18%",
                }}
                spinning={loadingProducts}
              >
                {Products.length > 0 ? (
                  <Carousel
                    dotPosition={"botton"}
                    draggable={true}
                    arrows={true}
                    prevArrow={LeftArrow("#002D62")}
                    nextArrow={RightArrow("#002D62")}
                    afterChange={afterChange}
                    ref={(ref) => {
                      slider.current = ref;
                    }}
                    style={{
                      width: "90%",
                      marginLeft: "5%",
                      paddingTop: "20px",
                    }}
                  >
                    {Products.map((product, index) => (
                      <div
                        key={product.ID}
                        style={{ marginTop: "20px", width: "80%" }}
                      >
                        <Row
                          gutter={[16, 0]}
                          style={{ justifyContent: "space-between" }}
                        >
                          <Col span={17}>
                            <h1
                              style={{
                                color: "#002D62",
                                fontWeight: "500",
                                textAlign: "left",
                                fontSize: "25px",
                                width: "100%",
                              }}
                            >
                              {product.Name}
                            </h1>
                            <Row style={{ justifyContent: "space-between" }}>
                              <h1
                                style={{
                                  color: "black",
                                  fontWeight: "300",
                                  textAlign: "left",
                                  fontSize: "23px",
                                }}
                              >
                                Código: {product.Code}
                              </h1>
                              <h1
                                style={{
                                  color: "black",
                                  fontWeight: "300",
                                  textAlign: "left",
                                  fontSize: "23px",
                                  marginRight: "30px",
                                }}
                              >
                                NF-e: {product.N_NFe}
                              </h1>
                            </Row>
                            <Divider
                              style={{
                                color: "#000000",
                                width: "90%",
                                marginTop: "-5px",
                              }}
                            />
                            <Row
                              gutter={[16, 0]}
                              style={{
                                justifyContent: "space-between",
                                paddingTop: "15px",
                              }}
                            >
                              <Col span={5}>
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "600",
                                    fontSize: "20px",
                                  }}
                                >
                                  Tensão:
                                </h3>
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "300",
                                    fontSize: "20px",
                                    marginTop: "-5px",
                                  }}
                                >
                                  {product.Tensao}
                                </h3>
                              </Col>
                              <Col span={4}>
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "600",
                                    fontSize: "20px",
                                  }}
                                >
                                  Cor:
                                </h3>
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "300",
                                    fontSize: "20px",
                                    marginTop: "-5px",
                                  }}
                                >
                                  {product.Color}
                                </h3>
                              </Col>
                              <Col span={5}>
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "600",
                                    fontSize: "20px",
                                  }}
                                >
                                  Garantia:
                                </h3>
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "300",
                                    fontSize: "20px",
                                    marginTop: "-5px",
                                  }}
                                >
                                  {product.Garantia === true ? "Sim" : "Não"}
                                </h3>
                              </Col>
                              <Col span={8}>
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "600",
                                    fontSize: "20px",
                                  }}
                                >
                                  Status:
                                </h3>
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "300",
                                    fontSize: "20px",
                                    marginTop: "-5px",
                                  }}
                                >
                                  {product.Status}
                                </h3>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={7}>
                            <img
                              alt={product.Code}
                              src={product.url}
                              style={{ width: "100%", marginTop: "5px" }}
                            />
                          </Col>
                        </Row>
                        <Row
                          gutter={[16, 0]}
                          style={{
                            height: "45px",
                            marginTop: "15px",
                            marginBottom: "30px",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            alt={"+"}
                            src={info}
                            style={{
                              marginLeft: "15px",
                              alignSelf: "center",
                              justifySelf: "center",
                            }}
                          />
                          <h4
                            onClick={() => {
                              openProductWindow("EDIT");
                            }}
                            style={{
                              marginTop: "5px",
                              marginLeft: "5px",
                              color: "#002D62",
                              fontWeight: "600",
                              alignSelf: "center",
                              justifySelf: "center",
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            Ver mais informações do produto
                          </h4>
                        </Row>
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  loadingProducts === false && (
                    <div
                      style={{
                        justifyItems: "center",
                        alignItems: "center",
                        marginTop: "15%",
                      }}
                    >
                      <h1
                        style={{
                          color: "#002D62",
                          fontWeight: "600",
                          textAlign: "center",
                          fontSize: "27px",
                        }}
                      >
                        Cliente sem produto cadastrado...
                      </h1>
                    </div>
                  )
                )}
              </Spin>
            </Col>
            <Col span={5} style={{}}>
              <Row
                gutter={[0, 0]}
                style={{
                  height: "23%",
                  marginBottom: "10px",
                  justifyContent: "center",
                }}
              >
                <Col span={11}>
                  <Button
                    href="https://meu.electrolux.com.br/manuais"
                    target="_blank"
                    className={"buttons"}
                    style={{
                      borderRadius: "6px",
                      color: "#ffff",
                      fontWeight: "500",
                      fontSize: "12px",
                      background: "#CFAE7E",
                      height: "90%",
                      width: "90%",
                      margin: "5%",
                    }}
                  >
                    <img
                      alt={"manualp"}
                      style={{ height: "40%", marginTop: 5 }}
                      src={manualproddis}
                    />
                    <div style={{ height: "15%", marginTop: 2 }}>
                      Manual do{" "}
                    </div>
                    <div style={{ height: "15%", marginTop: 0 }}>produto</div>
                  </Button>
                </Col>
                <Col span={11}>
                  <Button
                    href="https://eluxcity-login.sabacloud.com/Saba/Web_spf/NA1PRD0102/app/dashboard"
                    target="_blank"
                    className={"buttons"}
                    style={{
                      borderRadius: "6px",
                      color: "#ffff",
                      fontWeight: "500",
                      fontSize: "12px",
                      background: "#CFAE7E",
                      height: "90%",
                      width: "90%",
                      margin: "5%",
                    }}
                  >
                    <img
                      alt={"tools"}
                      style={{ height: "40%", marginTop: 5 }}
                      src={toolbox}
                    />
                    <div style={{ height: "15%", marginTop: 2 }}>
                      Boletim Técnico
                    </div>
                    <div style={{ height: "15%", marginTop: 0 }}>
                      Manual Serviço
                    </div>
                  </Button>
                </Col>
              </Row>
              <Row
                gutter={[0, 0]}
                style={{
                  height: "23%",
                  marginBottom: "10px",
                  justifyContent: "center",
                }}
              >
                <Col span={11}>
                  <Button
                    className={"buttons"}
                    onClick={() => {
                      openSVO_Trigger();
                    }}
                    style={{
                      borderRadius: "6px",
                      color: "#ffff",
                      fontWeight: "500",
                      fontSize: "12px",
                      background:
                        loadingProducts || Products.length === 0 || loadingSAE
                          ? "#b1b1af"
                          : "#CFAE7E",
                      height: "90%",
                      width: "90%",
                      margin: "5%",
                    }}
                    disabled={
                      loadingProducts || Products.length === 0 || loadingSAE
                    }
                  >
                    <img
                      alt={"svo"}
                      style={{ height: "40%", marginTop: 5 }}
                      src={svo}
                    />
                    <div style={{ height: "20%", marginTop: 2 }}>
                      Abertura de
                    </div>
                    <div style={{ height: "20%", marginTop: 0 }}>SVO</div>
                  </Button>
                </Col>
                <Col span={11}>
                  <Button
                    href="http://cppeletwebap01/ComparacaoProduto/"
                    target="_blank"
                    className={"buttons"}
                    style={{
                      borderRadius: "6px",
                      color: "#ffff",
                      fontWeight: "500",
                      fontSize: "12px",
                      background: "#CFAE7E",
                      height: "90%",
                      width: "90%",
                      margin: "5%",
                    }}
                  >
                    <img
                      alt={"grid"}
                      style={{ height: "40%", marginTop: 5 }}
                      src={griddis}
                    />
                    <div style={{ height: "20%", marginTop: 2 }}>
                      Compare os
                    </div>
                    <div style={{ height: "20%", marginTop: 0 }}>produtos</div>
                  </Button>
                </Col>
              </Row>
              <Row
                gutter={[0, 0]}
                style={{
                  height: "24%",
                  marginBottom: "5px",
                  justifyContent: "center",
                }}
              >
                <Col span={11}>
                  <Button
                    className={"buttons"}
                    onClick={() => history.push("/casoBKO")}
                    style={{
                      borderRadius: "6px",
                      color: "#ffff",
                      fontWeight: "500",
                      fontSize: "12px",
                      background:
                        loadingProducts || Products.length === 0 || loadingSAE
                          ? "#b1b1af"
                          : "#CFAE7E",
                      height: "90%",
                      width: "90%",
                      margin: "5%",
                    }}
                    disabled={
                      loadingProducts || Products.length === 0 || loadingSAE
                    }
                  >
                    <img
                      alt={"svo"}
                      style={{ height: "40%", marginTop: 5 }}
                      src={photos /* TODO: TROCAR O ICONE */}
                    />
                    <div style={{ height: "20%", marginTop: 2 }}>
                      Abertura de
                    </div>
                    <div style={{ height: "20%", marginTop: 0 }}>Caso</div>
                  </Button>
                </Col>
                <Col span={11}>
                  <Button
                    className={"buttons"}
                    onClick={() => setShowRegistrarChamada(true)}
                    style={{
                      borderRadius: "6px",
                      color: "#ffff",
                      fontWeight: "500",
                      fontSize: "12px",
                      background:
                        loadingProducts ||
                        Products.length === 0 ||
                        chamadaDisable
                          ? "#b1b1af"
                          : "#CFAE7E",
                      height: "90%",
                      width: "90%",
                      margin: "5%",
                    }}
                    disabled={
                      loadingProducts || Products.length === 0 || chamadaDisable
                    }
                    loading={loadingBKO}
                  >
                    <img
                      alt={"grid"}
                      style={{ height: "40%", marginTop: 5 }}
                      src={photos /* TODO: TROCAR O ICONE */}
                    />
                    <div style={{ height: "20%", marginTop: 2 }}>
                      Registro de
                    </div>
                    <div style={{ height: "20%", marginTop: 0 }}>Chamada</div>
                  </Button>
                </Col>
              </Row>
              <Col span={24} style={{ height: "15%", width: "100%" }}>
                <Button
                  className={"buttons"}
                  disabled={
                    loadingProducts || Products.length === 0 || chamadaDisable
                  }
                  loading={loadingBKO}
                  style={{
                    borderRadius: "6px",
                    color: "#ffff",
                    fontWeight: "600",
                    fontSize: "16px",
                    background:
                      loadingProducts || Products.length === 0 || chamadaDisable
                        ? "#b1b1af"
                        : "#CFAE7E",
                    height: "90%",
                    width: "93%",
                    marginLeft: 10,
                    marginTop: 6,
                  }}
                  onClick={() => {
                    setShowLastCase(true);
                  }}
                >
                  <img
                    alt={"BKO"}
                    style={{ height: "50%", marginRight: "10%" }}
                    src={photos /* TODO: TROCAR O ICONE */}
                  />
                  último caso aberto
                </Button>
              </Col>
              <Col span={24} style={{ height: "15%", width: "100%" }}>
                <Button
                  className={"buttons"}
                  disabled={loadingProducts || loadingSAE}
                  style={{
                    borderRadius: "6px",
                    color: "#ffff",
                    fontWeight: "600",
                    fontSize: "16px",
                    background:
                      loadingProducts || loadingSAE ? "#b1b1af" : "#33AF75",
                    height: "90%",
                    width: "93%",
                    marginLeft: 10,
                    marginTop: 6,
                  }}
                  onClick={() => {
                    openProductWindow("NEW");
                  }}
                >
                  <img
                    alt={"+"}
                    style={{ height: "50%", marginRight: "5%" }}
                    src={plus}
                  />
                  Cadastrar novo produto
                </Button>
              </Col>
            </Col>
            <Col span={7}>
              <Card
                className="boxShadowCard borderRadius cardColor card"
                loading={loadingSAE}
                cover={
                  <div
                    style={{
                      backgroundColor: "#CFAE7E",
                      borderRadius: "10px 10px 0px 0px",
                      height: "60px",
                      width: "100%",
                    }}
                  >
                    <Row gutter={[16, 0]} style={{ justifyContent: "center" }}>
                      <img
                        alt={"-"}
                        src={placeholders}
                        style={{
                          marginRight: "15px",
                          alignSelf: "center",
                          justifySelf: "center",
                        }}
                      />
                      <h4
                        style={{
                          marginTop: "12px",
                          marginLeft: "5px",
                          fontSize: "22px",
                          color: "#FFF",
                          fontWeight: "600",
                          alignSelf: "center",
                          justifySelf: "center",
                        }}
                      >
                        SAE deste produto
                      </h4>
                    </Row>
                  </div>
                }
              >
                {SAEList.SAE_Results > 0 && needUpdateSAE === false ? (
                  <Carousel
                    dotPosition={"botton"}
                    draggable={true}
                    arrows={true}
                    prevArrow={LeftArrow("#CFAE7E")}
                    nextArrow={RightArrow("#CFAE7E")}
                    style={{
                      width: "90%",
                      marginLeft: "5%",
                    }}
                  >
                    {SAEList.SaeData.map((SAE, index) => (
                      <div key={SAE.Name} style={{ width: "104%" }}>
                        <h3
                          style={{
                            color: "#BB8C48",
                            fontWeight: "600",
                            fontSize: "17px",
                            marginBottom: "0px",
                            marginTop: "-2px",
                          }}
                        >
                          Nome do SAE:
                        </h3>
                        <h3
                          style={{
                            color: "#000000",
                            fontSize: "19px",
                            marginTop: "-5px",
                          }}
                        >
                          {SAE.Name2}
                        </h3>
                        <h3
                          style={{
                            color: "#BB8C48",
                            fontWeight: "600",
                            fontSize: "17px",
                            marginBottom: "0px",
                            marginTop: "-2px",
                          }}
                        >
                          Telefone:
                        </h3>
                        <h3
                          style={{
                            color: "#000000",
                            fontSize: "19px",
                            marginTop: "-5px",
                          }}
                        >
                          {SAE.Phone}
                        </h3>
                        <h3
                          style={{
                            color: "#BB8C48",
                            fontWeight: "600",
                            fontSize: "17px",
                            marginBottom: "0px",
                            marginTop: "-2px",
                          }}
                        >
                          E-mail:
                        </h3>
                        <h3
                          style={{
                            color: "#000000",
                            fontSize: "19px",
                            marginTop: "-5px",
                          }}
                        >
                          {SAE.Email}
                        </h3>
                        <h3
                          style={{
                            color: "#BB8C48",
                            fontWeight: "600",
                            fontSize: "17px",
                            marginBottom: "0px",
                            marginTop: "-2px",
                          }}
                        >
                          Endereço:
                        </h3>
                        <h3
                          style={{
                            color: "#000000",
                            fontSize: "19px",
                            marginTop: "-5px",
                          }}
                        >
                          {SAE.Address}
                        </h3>
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      disabled={SelectedProduct === null ? true : false}
                      onClick={() => searchSAE()}
                      className={"sae_button"}
                      style={{
                        justifyContent: "center",
                        borderRadius: "6px",
                        width: "60%",
                        height: "15%",
                        background:
                          SelectedProduct === null ? "#b1b1af" : "#CFAE7E",
                        marginTop: "80px",
                        color: "#FFF",
                        fontWeight: "600",
                        fontSize: "24px",
                        textAlign: "center",
                      }}
                    >
                      <img
                        alt={"-"}
                        src={searchWhite}
                        style={{
                          width: "10%",
                          alignSelf: "center",
                          justifySelf: "center",
                          marginRight: "15px",
                        }}
                      />
                      Buscar SAE
                      {/* <h3 style={{ color: '#FFF', fontWeight: '600', fontSize: '24px', textAlign: 'center', marginTop: '10px' }}>Buscar SAE</h3> */}
                    </Button>
                  </div>
                )}
              </Card>
            </Col>
          </Row>

          <Row
            gutter={[16, 0]}
            style={{ paddingTop: "10px", height: "5%", marginLeft: "2%" }}
          >
            <Col span={23}>
              <Divider style={{ color: "#000000", width: "90%" }} />
            </Col>
          </Row>

          <h1
            style={{
              color: "#002D62",
              fontWeight: "600",
              paddingTop: "1%",
              marginLeft: "3%",
              textAlign: "left",
              fontSize: "30px",
            }}
          >
            Históricos do produto
          </h1>
          <Row
            gutter={[16, 0]}
            style={{ height: "40%", marginLeft: "2%", paddingTop: "1%" }}
          >
            <Col span={12}>
              <Card
                className="boxShadowCard borderRadius cardColor card"
                loading={loadingHistory}
                cover={
                  <div
                    style={{
                      backgroundColor: "#002D62",
                      borderRadius: "10px 10px 0px 0px",
                      height: "60px",
                      width: "100%",
                    }}
                  >
                    <Row
                      gutter={[16, 0]}
                      style={{ justifyContent: "space-between" }}
                    >
                      <h4
                        style={{
                          marginTop: "12px",
                          marginLeft: "5%",
                          fontSize: "22px",
                          color: "#FFF",
                          fontWeight: "600",
                        }}
                      >
                        Casos e SVOs
                      </h4>
                      <h4
                        style={{
                          marginTop: "12px",
                          marginRight: "5%",
                          fontSize: "22px",
                          color: "#FFF",
                          fontWeight: "600",
                          alignSelf: "center",
                          justifySelf: "center",
                        }}
                      >
                        {SelectedCase !== null &&
                          "Nº do caso: " + SelectedCase.CaseNumber}
                      </h4>
                    </Row>
                  </div>
                }
              >
                {SelectedProduct !== null &&
                ProductHistory.Cases.length > 0 &&
                ProductHistory.Product_ID === SelectedProduct.ID ? (
                  <Carousel
                    dotPosition={"botton"}
                    draggable={true}
                    arrows={false}
                    dots={true}
                    prevArrow={LeftArrowDown("#002D62")}
                    nextArrow={RightArrowDown("#002D62")}
                    afterChange={ChangeCase}
                    style={{
                      width: "100%",
                    }}
                  >
                    {ProductHistory.Cases.map((Case, index) => (
                      <div key={Case.Id}>
                        <Row gutter={[16, 0]} style={{ paddingBottom: "40px" }}>
                          <Col span={12}>
                            <h3
                              style={{
                                color:
                                  (Case.Type === "Reclamação" && "#FF0000") ||
                                  (Case.Type === "Elogio" && "#33AF75") ||
                                  (Case.Type === "Informação" && "#FFB300") ||
                                  (Case.Type === "Solicitação" && "blue") ||
                                  "black",
                                fontWeight: "600",
                                fontSize: "17px",
                                marginBottom: "0px",
                                marginTop: "-2px",
                              }}
                            >
                              <InfoCircleFilled
                                style={{
                                  color:
                                    (Case.Type === "Reclamação" && "#FF0000") ||
                                    (Case.Type === "Elogio" && "#33AF75") ||
                                    (Case.Type === "Informação" && "#FFB300") ||
                                    (Case.Type === "Solicitação" && "blue") ||
                                    "black",
                                }}
                              />{" "}
                              {Case.Type}
                            </h3>
                            <h3
                              style={{
                                color: "#000000",
                                fontWeight: "600",
                                fontSize: "17px",
                                marginBottom: "0px",
                                marginTop: "8px",
                              }}
                            >
                              {Case.Status === "Fechado" ? (
                                <LockFilled />
                              ) : (
                                <UnlockFilled />
                              )}{" "}
                              {Case.Status}
                            </h3>
                            <h3
                              style={{
                                color: "#000000",
                                fontWeight: "600",
                                fontSize: "17px",
                                marginBottom: "0px",
                                marginTop: "8px",
                              }}
                            >
                              <CalendarOutlined /> Aberto:{" "}
                              {moment(Case.CreatedDate).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </h3>
                            <h3
                              style={{
                                color: "#000000",
                                fontWeight: "600",
                                fontSize: "17px",
                                marginBottom: "0px",
                                marginTop: "8px",
                              }}
                            >
                              <HistoryOutlined /> Atualizado:{" "}
                              {moment(Case.LastModifiedDate).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </h3>
                            <h3
                              style={{
                                color: "#000000",
                                fontWeight: "600",
                                fontSize: "17px",
                                marginBottom: "0px",
                                marginTop: "8px",
                              }}
                            >
                              <TeamOutlined /> {Case.Equipe__c}
                            </h3>
                            <h3
                              style={{
                                color: "#000000",
                                fontWeight: "600",
                                fontSize: "17px",
                                marginBottom: "0px",
                                marginTop: "8px",
                              }}
                            >
                              <PhoneOutlined /> {Case.Origin}
                            </h3>
                            <h3
                              style={{
                                color: "#000000",
                                fontWeight: "600",
                                fontSize: "17px",
                                marginBottom: "0px",
                                marginTop: "8px",
                              }}
                            >
                              <ToolOutlined /> {Case.Sub_Motivo__c}
                            </h3>
                          </Col>

                          <Divider
                            style={{ height: "230px" }}
                            type="vertical"
                          />

                          <Col span={11}>
                            {Case.SVO !== null ? (
                              <div>
                                <div
                                  style={{
                                    justifyContent: "space-between",
                                    display: "flex",
                                  }}
                                >
                                  <h3
                                    style={{
                                      color: "#002D62",
                                      fontWeight: "600",
                                      fontSize: "17px",
                                      marginBottom: "0px",
                                      marginTop: "-2px",
                                    }}
                                  >
                                    {Case.SVO[Case.SVO.length - 1].Name}
                                  </h3>
                                  <h3
                                    onClick={() => openCaseDetails()}
                                    style={{
                                      color: "#BB8C48",
                                      fontWeight: "600",
                                      fontSize: "16px",
                                      marginBottom: "0px",
                                      marginTop: "-5px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Detalhes
                                  </h3>
                                </div>
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "600",
                                    fontSize: "17px",
                                    marginBottom: "0px",
                                    marginTop: "8px",
                                  }}
                                >
                                  <CalendarOutlined /> Aberta:{" "}
                                  {moment(
                                    Case.SVO[Case.SVO.length - 1].CreatedDate
                                  ).format("DD/MM/YYYY HH:mm")}
                                </h3>
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "600",
                                    fontSize: "17px",
                                    marginBottom: "0px",
                                    marginTop: "8px",
                                  }}
                                >
                                  <HistoryOutlined /> Atualizada:{" "}
                                  {moment(
                                    Case.SVO[Case.SVO.length - 1]
                                      .LastModifiedDate
                                  ).format("DD/MM/YYYY HH:mm")}
                                </h3>
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "600",
                                    fontSize: "17px",
                                    marginBottom: "0px",
                                    marginTop: "8px",
                                  }}
                                >
                                  <EnvironmentOutlined /> {Case.Authorized}
                                </h3>
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "600",
                                    fontSize: "17px",
                                    marginBottom: "0px",
                                    marginTop: "8px",
                                  }}
                                >
                                  <SafetyOutlined />{" "}
                                  {
                                    Case.SVO[Case.SVO.length - 1]
                                      .Order_Status__c
                                  }
                                </h3>
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "600",
                                    fontSize: "17px",
                                    marginBottom: "0px",
                                    marginTop: "8px",
                                  }}
                                >
                                  <ExceptionOutlined />{" "}
                                  {Case.SVO[Case.SVO.length - 1].Garantia__c ===
                                  true
                                    ? "Garantia"
                                    : "Fora de garantia"}
                                </h3>
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "600",
                                    fontSize: "17px",
                                    marginBottom: "0px",
                                    marginTop: "8px",
                                  }}
                                >
                                  <SettingOutlined />{" "}
                                  {
                                    Case.SVO[Case.SVO.length - 1]
                                      .Defeito_Reclamado__c
                                  }
                                </h3>
                              </div>
                            ) : (
                              <h3
                                style={{
                                  color: "#656565",
                                  marginTop: "30%",
                                  fontWeight: "600",
                                  fontSize: "17px",
                                  textAlign: "center",
                                  alignSelf: "center",
                                }}
                              >
                                Não existe Ordem de Serviço para esse caso.
                              </h3>
                            )}
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    {SelectedProduct !== null &&
                    ProductHistory.Cases.length === 0 &&
                    ProductHistory.Product_ID === SelectedProduct.ID ? (
                      <h3
                        style={{
                          color: "#b1b1af",
                          fontWeight: "600",
                          fontSize: "28px",
                          textAlign: "center",
                          marginTop: "13%",
                        }}
                      >
                        Produto sem histórico
                      </h3>
                    ) : (
                      <Button
                        disabled={SelectedProduct === null ? true : false}
                        onClick={() => searchProductHistory()}
                        className={"sae_button"}
                        style={{
                          justifyContent: "center",
                          borderRadius: "6px",
                          width: "60%",
                          height: "15%",
                          background:
                            SelectedProduct === null ? "#b1b1af" : "#002D62",
                          marginTop: "80px",
                          color: "#FFF",
                          fontWeight: "600",
                          fontSize: "24px",
                          textAlign: "center",
                        }}
                      >
                        <img
                          alt={"-"}
                          src={searchWhite}
                          style={{
                            width: "8%",
                            alignSelf: "center",
                            justifySelf: "center",
                            marginRight: "15px",
                          }}
                        />
                        Buscar Histórico
                      </Button>
                    )}
                  </div>
                )}
              </Card>
            </Col>
            <Col span={11}>
              <Card
                className="boxShadowCard borderRadius cardColor card"
                loading={loadingProducts}
                cover={
                  <div
                    style={{
                      backgroundColor: "#002D62",
                      borderRadius: "10px 10px 0px 0px",
                      height: "60px",
                      width: "100%",
                    }}
                  >
                    <Row
                      gutter={[16, 0]}
                      style={{ justifyContent: "space-between" }}
                    >
                      <h4
                        style={{
                          marginTop: "12px",
                          marginLeft: "5%",
                          fontSize: "22px",
                          color: "#FFF",
                          fontWeight: "600",
                        }}
                      >
                        Conta Resumida
                      </h4>
                      <h4
                        style={{
                          marginTop: "12px",
                          marginRight: "5%",
                          fontSize: "22px",
                          color: "#FFF",
                          fontWeight: "600",
                          alignSelf: "center",
                          justifySelf: "center",
                        }}
                      >
                        {SelectedRegister !== null && SelectedRegister.Name}
                      </h4>
                    </Row>
                  </div>
                }
              >
                {Client !== null && Client.Lead !== null ? (
                  Client.LeadRegisters !== null ? (
                    <Carousel
                      dotPosition={"botton"}
                      draggable={true}
                      arrows={false}
                      dots={true}
                      prevArrow={LeftArrowDown("#002D62")}
                      nextArrow={RightArrowDown("#002D62")}
                      afterChange={ChangeRegister}
                      style={{
                        width: "100%",
                      }}
                    >
                      {Client.LeadRegisters.map((Register, index) => (
                        <div key={Register.Id}>
                          <Row
                            gutter={[16, 0]}
                            style={{ paddingBottom: "40px" }}
                          >
                            <Col span={12}>
                              <h3
                                style={{
                                  color:
                                    (Register.Tipo__c === "Reclamação" &&
                                      "#FF0000") ||
                                    (Register.Tipo__c === "Elogio" &&
                                      "#33AF75") ||
                                    (Register.Tipo__c === "Informação" &&
                                      "#FFB300") ||
                                    (Register.Tipo__c === "Solicitação" &&
                                      "blue") ||
                                    "black",
                                  fontWeight: "600",
                                  fontSize: "17px",
                                  marginBottom: "0px",
                                  marginTop: "-2px",
                                }}
                              >
                                <InfoCircleFilled
                                  style={{
                                    color:
                                      (Register.Tipo__c === "Reclamação" &&
                                        "#FF0000") ||
                                      (Register.Tipo__c === "Elogio" &&
                                        "#33AF75") ||
                                      (Register.Tipo__c === "Informação" &&
                                        "#FFB300") ||
                                      (Register.Tipo__c === "Solicitação" &&
                                        "blue") ||
                                      "black",
                                  }}
                                />{" "}
                                {Register.Tipo__c}
                              </h3>
                              <h3
                                style={{
                                  color: "#000000",
                                  fontWeight: "600",
                                  fontSize: "17px",
                                  marginBottom: "0px",
                                  marginTop: "8px",
                                }}
                              >
                                <CalendarOutlined /> Aberto:{" "}
                                {moment(Register.Data_Hora_de_cria_o__c).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </h3>
                              <h3
                                style={{
                                  color: "#000000",
                                  fontWeight: "600",
                                  fontSize: "17px",
                                  marginBottom: "0px",
                                  marginTop: "8px",
                                }}
                              >
                                <HistoryOutlined /> Atualizado:{" "}
                                {moment(Register.LastModifiedDate).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </h3>
                              <h3
                                style={{
                                  color: "#000000",
                                  fontWeight: "600",
                                  fontSize: "17px",
                                  marginBottom: "0px",
                                  marginTop: "8px",
                                }}
                              >
                                {" "}
                                <InboxOutlined /> {Register.Linha__c}
                              </h3>
                              <h3
                                style={{
                                  color: "#000000",
                                  fontWeight: "600",
                                  fontSize: "17px",
                                  marginBottom: "0px",
                                  marginTop: "8px",
                                }}
                              >
                                <TeamOutlined /> {Register.Equipe__c}
                              </h3>
                            </Col>

                            <Divider
                              style={{ height: "230px" }}
                              type="vertical"
                            />

                            <Col span={11}>
                              <div>
                                {Register.Canal_de_entrada__c !== null && (
                                  <h3
                                    style={{
                                      color: "#000000",
                                      fontWeight: "600",
                                      fontSize: "17px",
                                      marginBottom: "0px",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <ImportOutlined />{" "}
                                    {Register.Canal_de_entrada__c}
                                  </h3>
                                )}
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "600",
                                    fontSize: "17px",
                                    marginBottom: "0px",
                                    marginTop: "8px",
                                  }}
                                >
                                  <QuestionCircleFilled />{" "}
                                  {Register.Motivo_da_Ligacao__c}
                                </h3>
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "600",
                                    fontSize: "17px",
                                    marginBottom: "0px",
                                    marginTop: "8px",
                                  }}
                                >
                                  <QuestionCircleOutlined />{" "}
                                  {Register.Sub_Motivo_do_Atendimento__c}
                                </h3>
                                <h3
                                  style={{
                                    color: "#000000",
                                    fontWeight: "600",
                                    fontSize: "17px",
                                    marginBottom: "0px",
                                    marginTop: "8px",
                                  }}
                                >
                                  <SafetyOutlined /> {Register.Conclusao__c}
                                </h3>
                                {Register.Descricao_da_Solicitao__c !==
                                  null && (
                                  <h3
                                    style={{
                                      color: "#000000",
                                      fontWeight: "600",
                                      fontSize: "17px",
                                      marginBottom: "0px",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <FileTextOutlined />{" "}
                                    {Register.Descricao_da_Solicitao__c.substring(
                                      0,
                                      50
                                    )}
                                  </h3>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </Carousel>
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3
                        style={{
                          color: "#b1b1af",
                          fontWeight: "600",
                          fontSize: "28px",
                          textAlign: "center",
                          marginTop: "16%",
                        }}
                      >
                        Cliente não possui Atendimentos
                      </h3>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h3
                      style={{
                        color: "#b1b1af",
                        fontWeight: "600",
                        fontSize: "28px",
                        textAlign: "center",
                        marginTop: "16%",
                      }}
                    >
                      Cliente não possui conta resumida
                    </h3>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      )}

      {!Client.Id === true &&
        !Client.Lead === false &&
        history.replace("/contaResumida")}
      {!Client.Id === true && !Client.Lead === true && (
        <Card className="boxShadow borderRadius cardColor card">
          <h1
            style={{
              color: "#002D62",
              fontWeight: "300",
              textAlign: "center",
              fontSize: "30px",
            }}
          >
            Consumidor não identificado
          </h1>
        </Card>
      )}
    </Layout>
  );
};
