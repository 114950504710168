/**
 * This function validate the caracters and format a phone number in a (XX)XXXXX-XXXX format, is used into a normalize attribute of Form.Item
 * @param {String} value The current value of the input or variable
 * @param {String} previousValue The previous value of the input, is empty when the function is called outside a Form.Item
 * @returns A String with the number formatted
 */
export const normalizePhone = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 2) {
            return '(' + onlyNums + ') '
        }
        if (onlyNums.length === 6) {
            return '(' + onlyNums.slice(0, 2) + ') ' + onlyNums.slice(2, 6) + '-'
        }
        if (onlyNums.length === 10) {
            return '(' + onlyNums.slice(0, 2) + ') ' + onlyNums.slice(2, 6) + '-' + onlyNums.slice(6, 10)
        }
        if (onlyNums.length === 11) {
            return '(' + onlyNums.slice(0, 2) + ') ' + onlyNums.slice(2, 7) + '-' + onlyNums.slice(7, 11)
        }
    }
    if (onlyNums.length <= 2) {
        return onlyNums
    }
    if (onlyNums.length <= 6) {
        return '(' + onlyNums.slice(0, 2) + ') ' + onlyNums.slice(2, 6)
    }
    if (onlyNums.length <= 10) {
        return '(' + onlyNums.slice(0, 2) + ') ' + onlyNums.slice(2, 6) + '-' + onlyNums.slice(6, 10)
    }
    return '(' + onlyNums.slice(0, 2) + ') ' + onlyNums.slice(2, 7) + '-' + onlyNums.slice(7, 11)
}

/**
 * This function validate the caracters and format for an CPF/CNPJ value, is used into a normalize attribute of Form.Item
 * @param {String} value The current value of the input or variable
 * @param {String} previousValue The previous value of the input, is empty when the function is called outside a Form.Item
 * @returns A String with the CPF/CNPJ formatted
 */
export const normalizeCPF_CNPJ = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 3) {
            return onlyNums + '.'
        }
        if (onlyNums.length === 6) {
            return onlyNums.slice(0, 3) + '.' + onlyNums.slice(3) + '.'
        }
        if (onlyNums.length === 9) {
            return onlyNums.slice(0, 3) + '.' + onlyNums.slice(3, 6) + '.' + onlyNums.slice(6, 9) + '-'
        }
        if (onlyNums.length === 11) {
            return onlyNums.slice(0, 3) + '.' + onlyNums.slice(3, 6) + '.' + onlyNums.slice(6, 9) + '-' + onlyNums.slice(9, 11)
        }
        if (onlyNums.length === 12) {
            return onlyNums.slice(0, 2) + '.' + onlyNums.slice(2, 5) + '.' + onlyNums.slice(5, 8) + '/' + onlyNums.slice(8, 12) + '-'
        }
        if (onlyNums.length === 14) {
            return onlyNums.slice(0, 2) + '.' + onlyNums.slice(2, 5) + '.' + onlyNums.slice(5, 8) + '/' + onlyNums.slice(8, 12) + '-' + onlyNums.slice(12, 14)
        }
    }
    if (onlyNums.length <= 3) {
        return onlyNums
    }
    if (onlyNums.length <= 6) {
        return onlyNums.slice(0, 3) + '.' + onlyNums.slice(3)
    }
    if (onlyNums.length <= 9) {
        return onlyNums.slice(0, 3) + '.' + onlyNums.slice(3, 6) + '.' + onlyNums.slice(6, 9)
    }
    if (onlyNums.length <= 9) {
        return onlyNums.slice(0, 3) + '.' + onlyNums.slice(3, 6) + '.' + onlyNums.slice(6, 9)
    }
    if (onlyNums.length <= 11) {
        return onlyNums.slice(0, 3) + '.' + onlyNums.slice(3, 6) + '.' + onlyNums.slice(6, 9) + '-' + onlyNums.slice(9, 11)
    }
    if (onlyNums.length <= 12) {
        return onlyNums.slice(0, 2) + '.' + onlyNums.slice(2, 5) + '.' + onlyNums.slice(5, 8) + '/' + onlyNums.slice(8, 12)
    }
    return onlyNums.slice(0, 2) + '.' + onlyNums.slice(2, 5) + '.' + onlyNums.slice(5, 8) + '/' + onlyNums.slice(8, 12) + '-' + onlyNums.slice(12, 14)
}

/**
 * This function take an hour, minutes and seconds to format and gives a format HH:MM:SS
 * @param {Number} hrs The hours count
 * @param {Number} min The minutes count
 * @param {Number} sec The seconds count
 * @returns A String with the time in format HH:MM:SS
 */
export const normalize_Clock = (hrs, min, sec) => {
    let clock = '';

    if(hrs === undefined || min === undefined || sec === undefined){
        return '00:00:00';
    }

    clock += (hrs.toString().length === 1) ? ('0'+hrs.toString()) : hrs.toString();
    clock += ':';
    clock += (min.toString().length === 1) ? ('0'+min.toString()) : min.toString();
    clock += ':';
    clock += (sec.toString().length === 1) ? ('0'+sec.toString()) : sec.toString();

    return clock;
}

/**
 * Thsi function removes all non numeric characters of a CPF
 * @param {String} value The CPF to format
 * @returns An String with the CPF without special characters
 */
export const remove_Punctuation_CPF = (value) => {
    // Remove points
    let array = value.split('.');
    let formated = "";
    for(let i = 0; i < array.length; i++){
        formated += array[i];
    }
    // Remove dashes
    array = formated.split('-');
    formated = "";
    for(let i = 0; i < array.length; i++){
        formated += array[i];
    }
    return formated;
}