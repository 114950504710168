import styled from "styled-components";

export const Layout = styled.div`
  margin-top: 1%;

  .boletoForm {
    width: 400px;
  }

  .boxShadow {
    box-shadow: 0 2px 3px #00000029;
  }

  .image {
    width: 200px;
    height: 200px;
    marginbotton: 20px;
    alignself: center;
    justifyself: center;
    position: relative;
  }

  .cardColor {
    background-color: #ffffff;
  }

  .card {
    width: 100%;
    align-self: center;
    justify-self: center;
  }
`;

export const AccountBtn = styled.button`
  width: 40%;
  min-height: 100px;
  font-size: 20px;
  background: blue;
  color: green;
  border: 0;
  cursor: pointer;
  margin: 25px 5%;

  img {
    width: 40px;
    margin: 10px;
  }
`;
