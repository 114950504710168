import React, { useState } from "react";
import axios from 'axios'
import { Form, Input, Button, Checkbox, Card, notification, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setCurrentUser, setToken } from "./actions";
import { Wrapper } from "./styles";
import logo3 from "../../Components/Images/logo2.png";

export const LoginPage = () => {

  const dispatch = useDispatch();
  const auth = useSelector(state => state.Login.auth);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  let location = useLocation();
  const onFinish = ({ username, password }) => {
    setLoading(true);
    setError(false);

    axios.post(
      process.env.REACT_APP_API_URL + `/api/authenticate`,
      {
        user: username,
        pass: password,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
        },
      }
    ).then(res => {
      message.success("Bem-vindo ")
      dispatch(setCurrentUser(username));
      dispatch(setToken(res.data.auth));
    })
      .catch((error) => {
        message.error('Erro ao efetuar login, verifique suas credenciais')
        setError(true);
        setLoading(false);
        dispatch(setToken(false, null));
      })

  };

  if (auth) return <Redirect to={"/"}></Redirect>;

  return (
    <Wrapper>
      <div className="site-space-background"></div>
      <Card className="boxShadow borderRadius" style={{ backgroundColor: '#EBEBED' }}>
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item>
            <h1 style={{ color: '#002D62' }} >Seja bem-vindo à Tela Única da</h1>
          </Form.Item>
          <Form.Item>
            <img src={logo3} className="logo" alt="logo"></img>
          </Form.Item>
          <Form.Item
            name="username"
            rules={[
              { required: true, message: "Por favor insira seu usuário." }
            ]}
          >
            <Input prefix={<UserOutlined />} error={error} placeholder="Usuário" allowClear />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Por favor insira sua senha." }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              error={error}
              type="password"
              placeholder="Senha"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item noStyle>
              <Button
                type="link"
                className="lostPass"
                onClick={() =>
                  notification.info({
                    message: "Esqueceu sua senha?",
                    description:
                      "Sua senha é a senha da rede. Para alterá-la, utilize o comando CTRL + ALT + DEL e em seguida 'Mudar uma senha'",
                    duration: 10,
                  })
                }
              >
                Esqueci minha senha
            </Button>
            </Form.Item >
            <Form.Item className="rememberPass" name="remember" valuePropName="checked">
              <Checkbox >Lembrar de mim</Checkbox>
            </Form.Item>
          </Form.Item>
          <Form.Item >
            <Button block className="loginBottom" loading={loading} type="primary" htmlType="submit">
              Acessar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Wrapper>
  );
};
