import axios from 'axios';
import React, { useEffect, useState } from "react";
import { Layout, Menu, Dropdown, message } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link, Redirect, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { ClientInfos } from "../BuscaCPF";
import { setCurrentUser, setToken, logOut } from "../LoginPage/actions";

import user from "../../Components/Images/user.png";
import logo from "../../Components/Images/logo-white.png";
import home from "../../Components/Images/home.svg";
import svo from "../../Components/Images/gear.svg";
import search from "../../Components/Images/search.svg";
import book from "../../Components/Images/book.png";

import { StyledLayout } from "./styles";

axios.defaults.withCredentials = true

const { Header, Sider, Content } = Layout;

export const PrivateRoute = ({ component: Component }) => {

  let location = useLocation();

  const { auth } = useSelector((state) => state.Login);
  const client = useSelector(state => state.Client.client);
  const [loading, setLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(true);

  const dispatch = useDispatch();

  // This Effect check the sesion only when the page is loaded, if cookie sesion is valid, LogIn the user
  useEffect(() => {
    if (auth === null) {
      setLoading(true);
      axios.get(
        process.env.REACT_APP_API_URL + `/api/validatesession`,
        { withCredentials: true, credentials: 'include' },
        {
          headers: {
            "Cache-Control": "no-cache",
            "Content-Type": "application/json",
          },
        }
      ).then(res => {
        setLoading(false)
        dispatch(setCurrentUser(res.data.user));
        dispatch(setToken(res.data.auth));

      }).catch((error) => {
        console.log(error)
        setLoading(false)
        if (error.response !== undefined && error.response.status !== 401) {
          message.error(error.response !== undefined ? error.response.data.message : 'Sua sessão expirou, faça login novamente.')
        }
        dispatch(logOut())
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If the auth value is false, return the user to Login page
  if (auth === false){
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location.pathname },
        }}
      ></Redirect>
    );
  }

  return (
    <StyledLayout>
      <Header className="header">
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          }
        )}
        <img className="logo center" src={logo} alt="logo"></img>
        <div>
          <Dropdown
            trigger="click"
            overlay={
              <Menu>
                <Menu.Item onClick={() => dispatch(logOut())}>
                  <span>
                    <LogoutOutlined />
                    <span>Encerrar sessão</span>
                  </span>
                </Menu.Item>
              </Menu>
            }
          >
            <img src={user} className="user" alt="user" />
          </Dropdown>
        </div>
      </Header>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[location.pathname]}
          >

            <Menu.Item key="/">
              <Link to="/">
                <div style={{ marginLeft: '-10px', left: '-50%', position: 'relative', transform: 'translateX(50%)' }}  >
                  <img src={home} style={{ marginRight: '25px' }} alt="home" />
                  <span style={{color: 'white'}}>Conta detalhada</span>
                </div>
              </Link>
            </Menu.Item>

            {
              !client.Id && client.Lead || !client.Id && !client.Lead ?
              null : 
              <>

                <Menu.Item key="/svo">
                  <Link to="/svo">
                    <div style={{ marginLeft: '-10px', left: '-50%', position: 'relative', transform: 'translateX(50%)' }}  >
                      <img src={svo} style={{ marginRight: '25px' }} alt="svo" />
                      <span style={{color: 'white'}}>Abrir SVO</span>
                    </div>
                  </Link>
                </Menu.Item>

                <Menu.Item key="/SAE">
                  <Link to="/SAE">
                    <div style={{ marginLeft: '-10px', left: '-50%', position: 'relative', transform: 'translateX(50%)' }}  >
                      <img src={search} style={{ marginRight: '25px' }} alt="search" />
                      <span style={{color: 'white'}}>Buscar SAE</span>
                    </div>
                  </Link>
                </Menu.Item>

                <Menu.Item key="/contaResumida">
                  <Link to="/contaResumida">
                    <div style={{ marginLeft: '-10px', left: '-50%', position: 'relative', transform: 'translateX(50%)' }}  >
                      <img src={book} style={{ marginRight: '25px' }} alt="conta resumida" />
                      <span style={{color: 'white'}}>Conta resumida</span>
                    </div>
                  </Link>
                </Menu.Item>
              </>
            }
          </Menu>
        </Sider>
        <div style={collapsed ? {minWidth: '65px'} : {minWidth: '200px'}} />
        <Content style={{ minHeight:'90vh', background:'#f0f2f5' }}>
          <div>
            <ClientInfos></ClientInfos>
            <Component></Component>
          </div>
        </Content>
      </Layout>
    </StyledLayout>
  );
};
