import { LOGIN_USER_SUCCESS, SET_TOKEN, LOGOUT } from "./constants";

const initialState = {
  auth: null,
  user: '',
};

export const Login = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case LOGOUT:
      return {
        ...state,
        auth: false,
        user: '',
      };

    case SET_TOKEN:
      return {
        ...state,
        auth: payload.auth,
      }
    default:
      return state;
  }
};
