import styled from "styled-components";

export const Layout = styled.div`
  margin-top: 1%;
  display: grid;
  place-content: center;

  .boletoForm {
    width: 400px;
  }

  .boxShadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  .cardColor {
    background-color: #FFFFFF;
  }

  .cardSize {
    width: 60%;
    align-self: center;
    justify-self: center;
  }

  .cardSize2 {
    width: 50%;
    align-self: center;
    justify-self: center;
  }

  .card {
    align-self: center;
    justify-self: center;
  }

  .borderRadius {
    border-radius: 10px;
  }

  .image {
    width: 200px;
    height: 200px;
    marginBotton:20px;
    alignSelf:center; 
    justifySelf:center;
    position: relative;
  }
`;
