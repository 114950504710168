import axios from 'axios';
import React, { useState } from "react";
import { Carousel, Spin, Modal, Radio, Button, message, Card, Divider, Form } from "antd";
import MaskedInput from 'antd-mask-input';
import { SearchOutlined, RightCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { Layout } from "./styles";

axios.defaults.withCredentials = true

export const SearchSAE = () => {
  const [loading, setLoading] = useState(false);
  const [searchCep, setSearchCep] = useState('');
  const [searchType, setSearchType] = useState(1);
  const [validateCEP, setValidateCEP] = useState('');
  const [visible, setVisible] = useState(false);
  const [saeInfo, setSaeInfo] = useState({
    SAE_Results: 0,
    SAE_Type: '',
    SaeData: [{
      Name: '',
      Phone: '',
      Phone2: '',
      Email: '',
      Address: '',
      PostalCode: '',
      Service_Team: '',
      OwnerId: '',
      Type: '',
      AddressSearched: {
        CEP: '',
        Bairro: '',
        Cidade: '',
        Estado: ''
      }
    }]
  });

  const changeCEP = event => {
    if (String(event.target.value.replace(/_/g, "")).length === 9) {
      setValidateCEP('success')
      setSearchCep(event.target.value)
    }
    else {
      setValidateCEP('error')
      setSearchCep(event.target.value)
    }
  }


  function onChangeType(e) {
    resetData()
    setSearchType(e.target.value)
  };

  function showModal() {
    setVisible(true);
  }

  function closeModal() {
    setVisible(false);
  }

  function resetData() {
    setSaeInfo({
      SAE_Results: 0,
      SAE_Type: '',
      SaeData: [{
        Name: '',
        Name2: '',
        Phone: '',
        Phone2: '',
        Consultor: '',
        Email: '',
        Address: '',
        PostalCode: '',
        Service_Team: '',
        OwnerId: '',
        Type: '',
        AddressSearched: {
          CEP: '',
          Bairro: '',
          Cidade: '',
          Estado: ''
        }
      }]
    });
  }

  function searchSAE() {

    setLoading(true);
    resetData()

    axios.get(
      process.env.REACT_APP_API_URL + `/api/search_sae`,
      {
        params: {
          type: searchType,
          searchType: 'ZIP',
          data: searchCep
        }
      }
    ).then(res => {
      setLoading(false)
      if (res.data.return.ResultsNumber > 0) {
        setSaeInfo({
          SAE_Results: res.data.return.ResultsNumber,
          SAE_Type: searchType === '1' ? 'Eletrodomésticos' : 'Eletroportáteis',
          SaeData: res.data.return.Results
        })
        showModal();
      }
      else {
        message.error("Não foi possivel encontrar um SAE para o CEP informado");
      }
    })
      .catch((error) => {
        setLoading(false)
        message.error(error.response.data.return !== undefined ? error.response.data.return : "Houve um erro ao buscar SAE");
      })
  }

  const RightArrow = () => {
    return (
      <Button
        style={{ padding: "1rem", height: "auto", width: "auto" }}
        icon={<RightCircleOutlined style={{ color: '#002D62', fontSize: 30 }} />}
      />
    )
  }

  const LeftArrow = () => {
    return (
      <Button
        style={{ padding: "1rem", height: "auto", width: "auto" }}
        icon={<LeftCircleOutlined style={{ color: '#002D62', fontSize: 30, marginLeft: '-15px' }} />}
      />

    )
  }



  return (
    <Layout>
      <Modal
        visible={visible}
        onOk={closeModal}
        onCancel={closeModal}
        width={800}
      >
        <h1>Não esqueça de realizar o registro no Salesforce!</h1>
      </Modal>

      <Card className="boxShadow borderRadius cardColor card">
        <Form
          name="buscaCEP"
          onFinish={searchSAE}
          layout="vertical"
          style={{
            width: '600px',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h1 style={{ color: '#002D62', fontWeight: 'bold', textAlign: 'center', fontSize: '25px' }}>Localizar SAE</h1>

          <Radio.Group onChange={onChangeType} value={searchType} style={{ marginTop: '10px', marginLeft: '30%' }}>
            <Radio value={1}>Eletrodomésticos</Radio>
            <Radio value={2}>Eletroportáteis</Radio>
          </Radio.Group>

          <Divider style={{ color: '#000000', width: '95%' }} />

          <div>
            <Form.Item
              name="cep"
              label="Digite o CEP do cliente:"
              hasFeedback
              validateStatus={validateCEP}
              style={{ display: 'inline-block', width: '30%', marginRight: '3%' }}
              rules={[
                { required: true, message: "Digite o CEP" }
              ]}

            >
              <MaskedInput
                hasFeedback
                style={{ borderRadius: '5px' }}
                onChange={changeCEP} placeholder="Ex: 38400-000" mask="11111-111" />
            </Form.Item>

            <Form.Item
              label=" "
              style={{ display: 'inline-block', width: '50%' }}>
              <Button style={{ borderRadius: '5px' }} icon={<SearchOutlined />} loading={loading} disabled={validateCEP === 'success' ? false : true} type="primary" htmlType="submit">Buscar SAE</Button>
            </Form.Item>


            {(saeInfo.SaeData[0].Name !== '' && searchCep !== '') &&
                  <div style={{ display: 'inline-block', width: '100%', marginLeft: '5%', marginTop: '0px' }}>
                    <Form.Item
                      name="CepBairro"
                      label="Bairro"
                      style={{ display: 'inline-block', width: '40%' }}
                    >
                      <h2 style={{ fontWeight: '600', textAlign: 'left', fontSize: '16px', marginTop: '-10px'  }}>{saeInfo.SaeData[0].AddressSearched.Bairro}</h2>
                    </Form.Item>

                    <Form.Item
                      name="CepCidade"
                      label="Cidade"
                      style={{ display: 'inline-block', width: '45%' }}
                    >
                      <h2 style={{ fontWeight: '600', textAlign: 'left', fontSize: '16px', marginTop: '-10px'  }}>{saeInfo.SaeData[0].AddressSearched.Cidade}</h2>
                    </Form.Item>

                    <Form.Item
                      name="CepEstado"
                      label="UF"
                      style={{ display: 'inline-block', width: '6%' }}
                    >
                      <h2 style={{ fontWeight: '600', textAlign: 'left', fontSize: '16px', marginTop: '-10px'  }}>{saeInfo.SaeData[0].AddressSearched.Estado}</h2>
                    </Form.Item>
                  </div>}

            <Spin spinning={loading} size="large" style={{ marginTop: '20%' }} >
              {saeInfo.SAE_Results > 0 &&
                <Carousel dotPosition={'botton'}
                  draggable={true} arrows={true}
                  prevArrow={LeftArrow()}
                  nextArrow={RightArrow()}
                  style={{
                    width: '90%',
                    marginLeft: '5%',
                  }}
                >
                  {saeInfo.SaeData.map((Item, index) => (
                    <div style={{ marginTop: '0', width: '80%' }}>

                      <h3 style={{ color: '#002D62', fontWeight: '600', fontSize: '20px', marginBottom: '-5px', }}>Nome do SAE:</h3>
                      <h3 style={{ color: '#000000', fontSize: '20px', marginTop: '-5px' }}>{Item.Name2}</h3>

                      {/* {Item.Consultor !== '' && 
                      <>
                      <h3 style={{ color: '#002D62', fontWeight: '600', fontSize: '20px', marginBottom: '-5px', }}>Consultor:</h3>
                      <h3 style={{ color: '#000000', fontSize: '20px', marginTop: '-5px' }}>{Item.Consultor}</h3> </>} */}

                      <h3 style={{ color: '#002D62', fontWeight: '600', fontSize: '20px', marginBottom: '0px', marginTop: '20px' }}>Telefone:</h3>
                      <h3 style={{ color: '#000000', fontSize: '20px', marginTop: '-5px' }}>{Item.Phone}</h3>


                      <h3 style={{ color: '#002D62', fontWeight: '600', fontSize: '20px', marginBottom: '0px', marginTop: '20px' }}>E-mail:</h3>
                      <h3 style={{ color: '#000000', fontSize: '20px', marginTop: '-5px' }}>{Item.Email}</h3>


                      <h3 style={{ color: '#002D62', fontWeight: '600', fontSize: '20px', marginBottom: '0px', marginTop: '20px' }}>Endereço:</h3>
                      <h3 style={{ color: '#000000', fontSize: '20px', marginTop: '-5px', marginBottom: '10%' }}>{Item.Address}</h3>
                    </div>

                  ))}
                </Carousel>
              }</Spin>
          </div>
        </Form>
      </Card>
    </Layout>
  )
};
