import styled from "styled-components";

export const Layout = styled.div`
  margin-top: 1%;

  place-content: center;

  .boxShadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  .cardColor {
    background-color: #ffffff;
  }

  .card {
    width: 100%;
    justify-self: center;
  }
`;
