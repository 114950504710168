import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
} from "antd";
import axios from "axios";
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { UPDATE_LEAD_REGISTERS } from "../BuscaCPF/reducers";
import { Layout } from "./styles";
import optionsTest from "./options.json";

export const RegistrarAtendimento = () => {
  const history = useHistory();

  const { Option } = Select;
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const client = useSelector(({ Client }) => Client);
  const selectedProduct = useSelector(({ Client }) => Client.selected_product);

  const atendimentoData = {
    Equipe__c: "Front Office",
    Classe__c: "Atendimento ao Consumidor",
    Canal_de_entrada__c: "Electrolux",
    Linha__c: null,
    Tipo__c: null,
    Motivo_da_Ligacao__c: null,
    Sub_Motivo_do_Atendimento__c: null,
    Descricao__c: null,
    Conclusao__c: null,
    Descricao_da_Solicitao__c: "",
    nome: client === null ? null : client.client.Lead.Name
  };

  const [registerAtendimentoData, setRegisterAtendimentoData] =
    useState(atendimentoData);
  const [loadingButton, setLoadingButton] = useState(false);
  const [motivoOptions, setMotivoOptions] = useState([]);
  const [submotivoOptions, setSubmotivoOptions] = useState([]);
  const [descricaoOptions, setDescricaoOptions] = useState([]);
  const [conclusaoOptions, setConclusaoOptions] = useState([]);
  const [conclusaoAvailable, setConclusaoAvailable] = useState(true);

  const handleSelectTipo = (value) => {
    const options = optionsTest["motivo"].find(
      (item) => item.tipodeAtendimento === value
    );

    setMotivoOptions(options.options);
    setSubmotivoOptions([]);
    setDescricaoOptions([]);
    setConclusaoOptions([]);
    form.setFieldsValue({
      Motivo_da_Ligacao__c: null,
      Sub_Motivo_do_Atendimento__c: "",
      Descricao__c: "",
      Conclusao__c: "",
    });
  };

  const handleSelectMotivo = (value) => {
    const options = optionsTest["submotivo"].find(
      (item) => item.motivo === value
    );

    setSubmotivoOptions(options.options);
    setDescricaoOptions([]);
    setConclusaoOptions([]);
    form.setFieldsValue({
      Sub_Motivo_do_Atendimento__c: null,
      Descricao__c: "",
      Conclusao__c: "",
    });
  };

  const handleSelectSubmotivo = (value) => {
    const options = optionsTest["descricao"].find(
      (item) => item.submotivo === value
    );

    setDescricaoOptions(options.options);
    setConclusaoOptions([]);
    form.setFieldsValue({
      Descricao__c: null,
      Conclusao__c: "",
    });
  };

  const handleSelectDescricao = (value) => {
    const options = optionsTest["conclusao"].find(
      (item) => item.descricao === value
    );

    if (options.options.length === 0) {
      setConclusaoAvailable(false);
    }

    setConclusaoOptions(options.options);
    form.setFieldsValue({
      Conclusao__c: null,
    });
  };

  const searchAtendimentos = async () => {
    return await axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/search_atendimentos?client=${client.client.Lead.Id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.return.records.length > 0) {
          console.log(response.data.return)
          dispatch({
            type: UPDATE_LEAD_REGISTERS,
            payload: response.data.return.records,
          });
        } else {
          message.error("Houve um erro 1");
        }
      })
      .catch((error) => {
        message.error("Houve um erro 2");
      });
  };

  const registrarAtendimento = async () => {
    setLoadingButton(true);
    const data = form.getFieldsValue();
    const product = selectedProduct ? selectedProduct.Product_C : null;
    return await axios
      .post(
        process.env.REACT_APP_API_URL + "/api/create_atendimento",
        {
          client: client.client.Lead.Id,
          product,
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        if (response.data.status === 205) {
          message.warning(response.data.statusText);
        } else {
          await searchAtendimentos();
          message.success(response.data.statusText);
          history.replace("/contaResumida");
        }
        setLoadingButton(false);
      })
      .catch((error) => {
        message.error("Houve um erro");
        setLoadingButton(false);
      });
  };

  return (
    <Layout>
      {!client.client.Id && !client.client.Lead ? (
        <Fragment style={{ height: "100%", width: "100%" }}>
          <Card style={{ width: "55%", margin: "auto" }}>
            <h1
              style={{
                color: "#002D62",
                fontWeight: "300",
                textAlign: "center",
                fontSize: "25px",
              }}
            >
              Consumidor não identificado
            </h1>
          </Card>
        </Fragment>
      ) : (
        <Form
          form={form}
          initialValues={atendimentoData}
          onFinish={() => registrarAtendimento()}
          style={{ height: "100%", width: "100%" }}
        >
          {/* Card Registrar Atendimento */}
          <Card style={{ width: "55%", margin: "auto" }}>
            <h1
              style={{
                color: "#002D62",
                fontWeight: "300",
                textAlign: "center",
                fontSize: "25px",
              }}
            >
              Registrar Atendimento
            </h1>
            <Divider style={{ color: "#000000", width: "95%" }} />
            <Row gutter={[16, 0]} style={{ height: "80px" }}>
              {/* Input Nome do Contato */}
              <Col span={8}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Nome do contato
                </label>
                <Form.Item name='nome'>
                  <Input
                    // value={client === null ? "" : client.client.Name}
                    placeholder="Nome do contato"
                    disabled={client === null ? false : true}
                  />
                </Form.Item>
              </Col>

              {/* Input Equipe_C */}
              <Col span={8}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Equipe
                </label>
                <Form.Item name="Equipe__c">
                  <Select placeholder="Equipe" disabled />
                </Form.Item>
              </Col>

              {/* Select Clase_C */}
              <Col span={8}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Clase
                </label>
                <Form.Item name="Classe__c">
                  <Select placeholder="Clase" disabled></Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 0]} style={{ height: "80px" }}>
              {/* Select Linha__c */}
              <Col span={12}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Linha
                </label>
                <Form.Item
                  name="Linha__c"
                  rules={[
                    {
                      required: true,
                      message: "Selecionar linha",
                    },
                  ]}
                >
                  <Select placeholder="--Selecionar linha--">
                    {optionsTest["linha"].map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* Select Canal de entrada */}
              <Col span={12}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Canal de entrada
                </label>
                <Form.Item name="Canal_de_entrada__c">
                  <Select placeholder="Canal de entrada" disabled></Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 0]} style={{ height: "80px" }}>
              {/* Select Tipo de Atendimento */}
              <Col span={8}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Tipo de Atendimento
                </label>
                <Form.Item
                  name="Tipo__c"
                  rules={[
                    {
                      required: true,
                      message: "Selecione o tipo do atendimento",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption
                    placeholder="--Selecione o tipo--"
                    onSelect={(value) => {
                      handleSelectTipo(value);
                    }}
                  >
                    {optionsTest["tipoAtendimento"].map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* Select Motivo de ligação*/}
              <Col span={8}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Motivo
                </label>
                <Form.Item
                  name="Motivo_da_Ligacao__c"
                  rules={[
                    {
                      required: motivoOptions.length !== 0,
                      message: "Selecione o motivo",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption
                    placeholder="--Selecione o motivo--"
                    disabled={motivoOptions.length === 0}
                    onSelect={(value) => {
                      handleSelectMotivo(value);
                    }}
                  >
                    {motivoOptions.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* Select Sub Movito atendimento */}
              <Col span={8}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Submotivo do atendimento
                </label>
                <Form.Item
                  name="Sub_Motivo_do_Atendimento__c"
                  rules={[
                    {
                      required: submotivoOptions.length !== 0,
                      message: "Selecione o submotivo",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption
                    placeholder="--Selecione o submotivo--"
                    disabled={submotivoOptions.length === 0}
                    onSelect={(value) => {
                      handleSelectSubmotivo(value);
                    }}
                  >
                    {submotivoOptions.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 0]} style={{ height: "80px" }}>
              {/* Select Descricao */}
              <Col span={12}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Descrição
                </label>
                <Form.Item
                  name="Descricao__c"
                  rules={[
                    {
                      required: descricaoOptions.length !== 0,
                      message: "Selecione a descrição",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption
                    placeholder="--Selecione a descrição--"
                    disabled={descricaoOptions.length === 0}
                    onSelect={(value) => {
                      handleSelectDescricao(value);
                    }}
                  >
                    {descricaoOptions.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* Select Conclusão */}
              <Col span={12}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Conclusão
                </label>
                <Form.Item
                  name="Conclusao__c"
                  rules={[
                    {
                      required: conclusaoOptions.length !== 0,
                      message: "Selecione a conclusão",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption
                    placeholder={
                      conclusaoAvailable
                        ? "--Selecione a conclusão--"
                        : "--Nenhuma conclusão--"
                    }
                    disabled={conclusaoOptions.length === 0}
                  >
                    {conclusaoOptions.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 0]}>
              {/* TextArea Descrição da Solicitação */}
              <Col span={24}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Descrição da Solicitação
                </label>
                <Form.Item name="Descricao_da_Solicitao__c">
                  <TextArea placeholder="Descrição da Solicitação" rows={2} />
                </Form.Item>
              </Col>
            </Row>

            {/* Send Button */}
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Button
                  type="primary"
                  loading={loadingButton}
                  style={{
                    borderRadius: "6px",
                    background: "#002D62",
                    height: "38px",
                    marginTop: "10px",
                    color: "#FFF",
                    fontWeight: "350",
                    fontSize: "15px",
                  }}
                  htmlType="submit"
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Card>
        </Form>
      )}
    </Layout>
  );
};
