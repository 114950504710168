import styled from "styled-components";

export const Layout = styled.div`
  margin-top: 1%;
  display: grid;
  place-content: center;

  .boxShadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  .cardColor {
    background-color: #FFFFFF;
  }

  .card {
    width: 100%;
    align-self: center;
    justify-self: center;
  }
  .ant-carousel .slick-dots li button {
    background: #002D62;
    height: 6px;
    border-radius: 20%;
    opacity: 0.4;
  }
  
  .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    height: 7px;
    border-radius: 20%;
    background: #002D62;
  }
  

`;
