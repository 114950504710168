export const SET_SAE_DATA = "SET_SAE_DATA";

const initialState = {
    sae: null
}

export const SAE = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_SAE_DATA:
            return {
                ...state,
                sae: payload.data
            }
        default:
            return state;
    }
}