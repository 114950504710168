import React from "react";
import ReactDOM from "react-dom";
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";

import App from "./App";
import GlobalStyles from "./GlobalStyles";
import { Login } from "./containers/LoginPage/reducers";
import { Client } from "./containers/BuscaCPF/reducers";
import { SAE } from "./containers/SearchSAE/reducers";
import { SVO } from "./containers/SvoPage/reducers";
import "./fonts/Roboto-Regular.ttf";

// Combine all app reducers
const rootReducer = combineReducers({ Login, SVO, Client, SAE });

// Create store and enable Redux Devtools extension
const store = createStore(rootReducer,  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

ReactDOM.render(
  <Provider store={store}>
    <App></App>
    <GlobalStyles></GlobalStyles>
  </Provider>,
  document.getElementById("root")
);

console.log("Version => Sprint 3 (31/01)")