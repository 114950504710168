import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  Typography,
} from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TOP_service from "../../Components/data/TOPS.json";
import {
  SET_CLIENT_SELECTED_SAE,
  CLEAN_CLIENT_SELECT_SAE,
} from "../BuscaCPF/reducers";
import options from "./Options.json";
import { Layout } from "./styles";

export const CasoBko = () => {
  const history = useHistory();

  const { TextArea, Search } = Input;
  const { Title } = Typography;
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const SelectedProduct = useSelector(({ Client }) => Client.selected_product);
  const client = useSelector(({ Client }) => Client.client);
  const SelectedSAE = useSelector(({ Client }) => Client.selected_sae);

  const casoBkoData = {
    Equipe__c: "Grupo I", // fixed
    Classe_t__c: "Atendimento ao consumidor", // fixed
    status_do_caso__c: "Aguardando atendimento",
    Backoffice__c: "00580000008ca7LAAQ", // "Mauricio Belle Schmatz"
    Status: "Aberto", // fixed
    Type: SelectedProduct && SelectedProduct.Line,
    Motivo__c: null,
    Sub_Motivo__c: null,
    Descri__c: null,
    RecordTypeId: SelectedProduct && SelectedProduct.RecordType,
    Origin: "Telefone", // fixed
    AccountId: client && client.Id,
    Top_Level_DSM__c: SelectedProduct && SelectedProduct.ID,
    Servi_o_Autorizado_DSM__c: "",
    Garantia__c: "", // send product to check
    Garantia_Produto_DSM__c: "", // send product to check
    InitialCustomerReport__c: "",
    AbrirTarefa: false,
    AbrirPRM: false,

    vozConsumidor: "", // only used for local search
  };

  const tablecolumns = [
    {
      title: "Linha de produto",
      dataIndex: "linha",
      key: "linha",
    },
    {
      title: "Tipo de Atendimento",
      dataIndex: "tipo",
      key: "tipo",
    },
    {
      title: "Motivo de Abertura",
      dataIndex: "motivo",
      key: "motivo",
    },
    {
      title: "Submotivo",
      dataIndex: "submotivo",
      key: "submotivo",
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
      key: "descricao",
    },
  ];

  const [loadingButton, setLoadingButton] = useState(false);
  const [openTable, setOpenTable] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [optionsSelected, setOptionsSelected] = useState({});
  const [callTypesOptions, setCallTypesOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [callTypes, setCallTypes] = useState([]);
  const [abrirPRMSwitch, setAbrirPRMSwicth] = useState(false);
  const [abrirTarefaSwitch, setAbrirTarefaSwicth] = useState(false);
  const [topService, setTopService] = useState(null);
  const [disableButton, setDisableButton] = useState(true);
  const [tarefaVisible, setTarefaVisible] = useState(false);
  const [PRMVisible, setPRMVisible] = useState(false);

  useEffect(async () => {
    dispatch({ type: CLEAN_CLIENT_SELECT_SAE, payload: null });
    await searchSAE();
  }, []);

  const renderTitle = (title, id) => (
    <span key={id} value={id}>
      {title}
    </span>
  );

  const renderItem = (submotive, title, id) => ({
    value: `${submotive}${title}${id}`,
    label: (
      <div
        key={id}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {`${title} - (${submotive})`}
      </div>
    ),
  });

  // To use CallTypes API, now CallTypes options are fixed on options.json
  const searchCallTypes = async () => {
    let recordType = "";
    const selectedProduct = SelectedProduct.Line;
    if (!selectedProduct) {
      history.replace("/");
    }
    // Especial
    if (selectedProduct.includes("Lavadoras (Roupas/ Louças) e Secadoras")) {
      recordType = "Lavadoras (Roupas/Louças) e Secadoras";
      // Especial
    } else if (selectedProduct.includes("Fornos de microondas")) {
      recordType = "Microondas";
    } else if (selectedProduct.includes("/")) {
      const temp = selectedProduct.split("/");
      recordType = temp.pop();
      recordType = recordType.trim();
    } else if (selectedProduct.includes(",")) {
      const temp = selectedProduct.split(",");
      recordType = temp.pop();
    } else {
      recordType = selectedProduct;
    }

    return await axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/search_call_types?caseRecordType=${
            recordType === "" ? SelectedProduct.Line : recordType
          }`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setCallTypes(response.data.return);
        const motives = [];
        response.data.return.map((item) => {
          if (!motives.includes(item.OpeningMotiveTxt__c)) {
            motives.push(item.OpeningMotiveTxt__c);
          }
        });

        const motivesTemp = [];
        motives.map((item) => {
          const filteredMotives = response.data.return.filter(
            (i) => item == i.OpeningMotiveTxt__c
          );
          motivesTemp.push(filteredMotives);
        });

        const labeledOptions = motivesTemp.map((motive, index) => {
          return {
            label: renderTitle(motive[0].OpeningMotiveTxt__c, index),
            options: motive.map((item) =>
              renderItem(item.SubmotiveTxt__c, item.DescriptionTxt__c, item.Id)
            ),
          };
        });
        setCallTypesOptions(labeledOptions);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        message.error("Houve um erro");
      });
  };

  const createBKO = async () => {
    setLoadingButton(true);
    const data = {
      product: SelectedProduct,
      data: form.getFieldsValue(true),
      SAE: {
        Name: SelectedSAE["Name"],
        TOPSAE: topService !== null ? topService.ID : false,
        OwnerId: SelectedSAE["OwnerId"],
        Service_Team: SelectedSAE["Service_Team"],
      },
    };

    return await axios
      .post(process.env.REACT_APP_API_URL + "/api/create_BKO", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(
        (response) => {
          setLoadingButton(false);
          if (response.data.status === 205) {
            message.success("Houve um erro");
          } else {
            message.success("Exito");
            history.replace("/");
          }
        },
        (error) => {
          setLoadingButton(false);
          message.error("Houve um erro");
        }
      );
  };

  const searchOptions = (value) => {
    setDataTable([]);
    setOpenTable(true);

    const type = SelectedProduct.Line.replaceAll("/ ", "/").replaceAll(
      ",",
      "/"
    );

    const regex = new RegExp(value, "i");

    const data = options.options.filter(
      (item) =>
        item.tipoRegistro === type &&
        (regex.test(item.descricao) ||
          regex.test(item.submotivo) ||
          regex.test(item.motivo))
    );

    setDataTable(data);
  };

  const handleOptionsSelected = () => {
    setOpenTable(false);
    setDataTable([]);

    form.setFieldsValue({
      Motivo__c: optionsSelected.motivo,
      Sub_Motivo__c: optionsSelected.submotivo,
      Descri__c: optionsSelected.descricao,
      vozConsumidor: null,
    });
  };

  const handleSwitches = (switchName, checked) => {
    if (switchName === "AbrirTarefa" && checked) {
      setAbrirTarefaSwicth(true);
      setAbrirPRMSwicth(false);
      form.setFieldsValue({
        AbrirTarefa: true,
        AbrirPRM: false,
      });
    } else if (switchName === "AbrirPRM" && checked) {
      setAbrirTarefaSwicth(false);
      setAbrirPRMSwicth(true);
      form.setFieldsValue({
        AbrirTarefa: false,
        AbrirPRM: true,
      });
    }
    if (switchName === "AbrirTarefa" && !checked) {
      setAbrirTarefaSwicth(false);
    } else if (switchName === "AbrirPRM" && !checked) {
      setAbrirPRMSwicth(false);
    }
  };

  const searchSAE = async () => {
    setDisableButton(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/search_sae`, {
        params: {
          type: "1",
          searchType: "ZIP",
          data: SelectedProduct.Address.CEP,
        },
      })
      .then((res) => {
        setDisableButton(false);
        dispatch({
          type: SET_CLIENT_SELECTED_SAE,
          payload: res.data.return.Results[0],
        });

        // Search on TOPS.json
        const TOPService = TOP_service.find(
          (item) => item.NAME === res.data.return.Results[0].Name
        );

        setTopService(TOPService !== undefined ? TOPService : null);

        if (TOPService !== undefined) {
          setTarefaVisible(true);
        } else {
          setPRMVisible(true);
        }
      })

      .catch((error) => {
        setDisableButton(false);
        message.error("Houve um erro ao pesquisar sae");
      });
  };

  return (
    <Layout>
      {client.Id === null ||
      (client.Id !== null && SelectedProduct === null) ? (
        history.replace("/")
      ) : (
        <Form
          onFinish={() => createBKO()}
          form={form}
          initialValues={casoBkoData}
        >
          {/* CallTypes filter */}
          <Modal
            width={"80%"}
            visible={openTable}
            footer={false}
            onCancel={() => {
              setOpenTable(false);
            }}
          >
            <Row gutter={[16, 0]}>
              <Col span={8}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Pesquisa
                </label>
                <Form.Item name="vozConsumidor">
                  <Search
                    placeholder="Pesquisar"
                    onSearch={(value) => {
                      searchOptions(value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Table
              bordered
              size="small"
              dataSource={dataTable}
              columns={tablecolumns}
              rowSelection={{
                type: "radio",
                onChange: (key, data) => {
                  setOptionsSelected(data[0]);
                },
              }}
            />
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Button
                  type="primary"
                  onClick={handleOptionsSelected}
                  style={{
                    borderRadius: "6px",
                    background: "#002D62",
                    height: "38px",
                    marginTop: "10px",
                    color: "#FFF",
                    fontWeight: "350",
                    fontSize: "15px",
                  }}
                >
                  Salvar seleção
                </Button>
              </Col>
            </Row>
          </Modal>

          {/* Abertura do Caso */}
          <Card style={{ width: "55%", margin: "auto" }}>
            <h1
              style={{
                color: "#002D62",
                fontWeight: "300",
                textAlign: "center",
                fontSize: "35px",
              }}
            >
              Abertura do Caso
            </h1>

            <Divider style={{ color: "#000000", width: "95%" }}>
              <Title level={4}>Informações sobre o caso</Title>
            </Divider>

            <Row gutter={[16, 0]}>
              {/* Select Produto*/}
              <Col span={12}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Produto
                </label>
                <Form.Item>
                  <Input
                    value={
                      SelectedProduct.ID !== null
                        ? `${SelectedProduct.Name} ${
                            SelectedProduct.N_NFe == null
                              ? ""
                              : "NF- " + SelectedProduct.N_NFe
                          }`
                        : ""
                    }
                    disabled={true}
                  ></Input>
                </Form.Item>
              </Col>

              <Col span={12}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Classe
                </label>
                <Form.Item name="Classe_t__c">
                  <Input placeholder="Classe" disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 0]}>
              <Col span={12}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Equipe
                </label>
                <Form.Item name="Equipe__c">
                  <Input placeholder="Equipe" disabled />
                </Form.Item>
              </Col>

              <Col span={12}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Status do caso
                </label>
                <Form.Item name="status_do_caso__c">
                  <Input placeholder="Status do caso" disabled />
                </Form.Item>
              </Col>
            </Row>

            <Divider style={{ color: "#000000", width: "95%" }}>
              <Title level={4}>Dados de abertura do caso</Title>
            </Divider>

            <Row gutter={[16, 0]} style={{ minHeight: "80px" }}>
              <Col span={12}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Backoffice
                </label>
                <Form.Item name="Backoffice__c">
                  <Input placeholder="Backoffice" disabled />
                </Form.Item>
              </Col>

              <Col span={12}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Status
                </label>
                <Form.Item name="Status">
                  <Input placeholder="Status" disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 0]} style={{ minHeight: "80px" }}>
              <Col span={12}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Tipo de Registro de Caso
                </label>
                <Form.Item name="Type" extra="De acordo com o Produto">
                  <Input placeholder="Tipo de registro do caso" disabled />
                </Form.Item>
              </Col>

              <Col span={12}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Voz do Consumidor
                </label>
                <Form.Item
                  name="vozConsumidor"
                  extra="Para preencher os campos Motivo, Submotivo e Descrição"
                >
                  <Search
                    placeholder="Pesquisar"
                    onSearch={(value) => {
                      searchOptions(value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 0]} style={{ minHeight: "80px" }}>
              <Col span={8}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Motivo de Abertura
                </label>
                <Form.Item
                  name="Motivo__c"
                  rules={[
                    {
                      required: true,
                      message: "Selecione o Motivo",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Submotivo
                </label>
                <Form.Item
                  name="Sub_Motivo__c"
                  rules={[
                    {
                      required: true,
                      message: "Selecione o Submotivo",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Descrição
                </label>
                <Form.Item
                  name="Descri__c"
                  rules={[
                    {
                      required: true,
                      message: "Selecione o Descrição",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 0]} style={{ minHeight: "80px" }}>
              <Col span={24}>
                <label style={{ color: "#002D62", fontWeight: "500" }}>
                  Relato inicial do consumidor
                </label>
                <Form.Item
                  name="InitialCustomerReport__c"
                  rules={[
                    {
                      required: true,
                      message: "Digite o Relato incial do consumidor",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Relato inicial do consumidor"
                    rows={4}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 0]} style={{ minHeight: "80px" }}>
              {tarefaVisible ? (
                <Col span={6}>
                  <Form.Item label="Abrir Tarefa" name="AbrirTarefa">
                    <Switch
                      checked={abrirTarefaSwitch}
                      checkedChildren="Sim"
                      unCheckedChildren="Não"
                      onChange={(checked) => {
                        handleSwitches("AbrirTarefa", checked);
                      }}
                    />
                  </Form.Item>
                </Col>
              ) : null}

              {PRMVisible ? (
                <Col span={6}>
                  <Form.Item label="Abrir PRM" name="AbrirPRM">
                    <Switch
                      checked={abrirPRMSwitch}
                      checkedChildren="Sim"
                      unCheckedChildren="Não"
                      onChange={(checked) => {
                        handleSwitches("AbrirPRM", checked);
                      }}
                    />
                  </Form.Item>
                </Col>
              ) : null}
            </Row>

            {/* Send Button */}
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Button
                  type="primary"
                  loading={loadingButton}
                  disabled={disableButton}
                  style={{
                    borderRadius: "6px",
                    background: "#002D62",
                    height: "38px",
                    marginTop: "10px",
                    color: "#FFF",
                    fontWeight: "350",
                    fontSize: "15px",
                  }}
                  htmlType="submit"
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Card>
        </Form>
      )}
    </Layout>
  );
};

export const RegisterChamadaModal = ({ visible, onCancel = false }) => {
  const Client = useSelector(({ Client }) => Client.client);
  const selectedBKO = useSelector(({ Client }) => Client.selected_bko);
  const [loadingButton, setLoadingButton] = useState(false);

  const registrarChamadaClient = async () => {
    setLoadingButton(true);

    const date = new Date().toISOString();

    const data = {
      WhoId: null,
      WhatId: selectedBKO ? selectedBKO.Id : null, // id Caso abierto
      Subject: "Acionamento da Área Responsável",
      ActivityDate: date.split("T")[0],
      Status: "Concluído",
      Priority: "Normal",
      TaskSubtype: "Call",
      AreaResponsavel__c: "Tela de resumo",
      Description: "Teste registro de call",
    };

    if (!selectedBKO) {
      message.error("Houve um erro");
    } else {
      axios
        .post(process.env.REACT_APP_API_URL + "/api/create_chamada", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setLoadingButton(false);
          if (res.status === 200 && res.data.statusText) {
            onCancel();
            message.success("Exito");
          } else {
            message.error("Houve um erro");
          }
        })
        .catch((error) => {
          setLoadingButton(false);
          message.error("Houve um erro");
        });
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      style={{ width: "55%", margin: "auto" }}
      width={"55%"}
      footer={null}
    >
      {/* Registro Chamada */}
      <h1
        style={{
          color: "#002D62",
          fontWeight: "300",
          textAlign: "center",
          fontSize: "25px",
        }}
      >
        Registrar chamada
      </h1>

      <Divider style={{ color: "#000000", width: "95%" }} />

      <Row gutter={[16, 0]} style={{ height: "80px" }}>
        <Col span={12}>
          <label style={{ color: "#002D62", fontWeight: "500" }}>
            Atribuido a
          </label>
          <Form.Item>
            <Input
              placeholder="Atribuido a"
              value={`${Client.Name}`}
              disabled={true}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <label style={{ color: "#002D62", fontWeight: "500" }}>Assunto</label>
          <Form.Item>
            <Select placeholder="Assunto" value="Concluído" disabled></Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 0]} style={{ height: "80px" }}>
        <Col span={12}>
          <label style={{ color: "#002D62", fontWeight: "500" }}>
            Area Responsavel
          </label>
          <Form.Item>
            <Select
              placeholder="Área Resposável"
              value="Tela de resumo"
              disabled={true}
            ></Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <label style={{ color: "#002D62", fontWeight: "500" }}>
            Descrição
          </label>
          <Form.Item name="Descrição">
            <Input placeholder="Descrição" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span={24}>
          <Button
            onClick={() => registrarChamadaClient()}
            type="primary"
            loading={loadingButton}
            style={{
              borderRadius: "6px",
              background: "#002D62",
              height: "38px",
              marginTop: "10px",
              color: "#FFF",
              fontWeight: "350",
              fontSize: "15px",
            }}
          >
            Salvar
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export const LastCaseBKOModal = ({ visible, onCancel }) => {
  const { Title, Text, Paragraph } = Typography;

  const SelectedProduct = useSelector(({ Client }) => Client.selected_product);
  const SelectedBKO = useSelector(({ Client }) => Client.selected_bko);

  return (
    <Modal visible={visible} width={"55%"} footer={null} onCancel={onCancel}>
      <h2
        style={{
          color: "#002D62",
          fontWeight: "300",
          textAlign: "center",
          fontSize: "30px",
        }}
      >
        Detalhes do Caso
      </h2>
      <Row gutter={[16, 0]} style={{ marginTop: "25px", marginBottom: "25px" }}>
        <Col span={8}>
          <Title level={5}>Número do Caso</Title>
          <Text
            style={{
              textAlign: "center",
              fontSize: "20px",
            }}
            code
          >
            {SelectedBKO ? SelectedBKO.CaseNumber : null}
          </Text>
        </Col>
        <Col span={8}>
          <Title level={5}>Produto</Title>
          <Text
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {SelectedProduct ? SelectedProduct.Name : null}
          </Text>
        </Col>
        <Col span={8}>
          <Title level={5}>Status do Caso</Title>
          <Text
            style={{
              textAlign: "center",
            }}
          >
            {SelectedBKO ? SelectedBKO.status_do_caso__c : null}
          </Text>
        </Col>
      </Row>

      <Divider style={{ color: "#000000", width: "95%" }}>
        Dados de abertura do caso
      </Divider>

      <Row gutter={[16, 0]} style={{ marginTop: "25px" }}>
        <Col span={8}>
          <Title level={5}>Tipo de Registro de Caso</Title>
          <Text
            style={{
              textAlign: "center",
            }}
          >
            {SelectedBKO ? SelectedBKO.Type : null}
          </Text>
        </Col>
        <Col span={8}>
          <Title level={5}>Status</Title>
          <Text
            code
            type={
              SelectedBKO && SelectedBKO.Status === "Fechado"
                ? "danger"
                : "success"
            }
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: "17px",
            }}
          >
            {SelectedBKO ? SelectedBKO.Status : null}
          </Text>
        </Col>
        <Col span={8}>
          <Title level={5}>Backoffice</Title>
          <Text
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {SelectedBKO ? SelectedBKO.Backoffice__c : null}
          </Text>
        </Col>
      </Row>

      <Row gutter={[16, 0]} style={{ marginTop: "25px" }}>
        <Col span={8}>
          <Title level={5}>Motivo</Title>
          <Text
            style={{
              textAlign: "center",
            }}
          >
            {SelectedBKO ? SelectedBKO.Motivo__c : null}
          </Text>
        </Col>
        <Col span={8}>
          <Title level={5}>Submotivo</Title>
          <Text
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {SelectedBKO ? SelectedBKO.Sub_Motivo__c : null}
          </Text>
        </Col>
        <Col span={8}>
          <Title level={5}>Descrição</Title>
          <Text
            style={{
              textAlign: "center",
            }}
          >
            {SelectedBKO ? SelectedBKO.Descri__c : null}
          </Text>
        </Col>
      </Row>

      <Row gutter={[16, 0]} style={{ marginTop: "25px" }}>
        <Col span={24}>
          <Title level={5}>Relato inicial do consumidor</Title>
          <Paragraph>
            {SelectedBKO
              ? SelectedBKO.InitialCustomerReport__c
              : null}
          </Paragraph>
        </Col>
      </Row>
    </Modal>
  );
};
