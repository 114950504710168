import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { PrivateRoute } from "./containers/PrivateRoute";
import { LoginPage } from "./containers/LoginPage";
import { HomePage } from "./containers/HomePage";
import { SVO } from "./containers/SvoPage";
import { SearchSAE } from "./containers/SearchSAE";
import { Maintenance } from "./containers/Maintenance/Maintenance";
import { ContaResumida } from "./containers/ContaResumida";
import { RegistrarAtendimento } from "./containers/RegistrarAtendimento";
import { CasoBko } from "./containers/CasoBko"

function App() {
  // If you want to disable all the platform, put it in true
  const maintenance = false;

  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={LoginPage}></Route>
        <PrivateRoute exact path="/" component={HomePage}></PrivateRoute>
        <PrivateRoute exact path="/svo" component={SVO}></PrivateRoute>
        <PrivateRoute exact path="/SAE" component={SearchSAE}></PrivateRoute>
        <PrivateRoute exact path="/contaResumida" component={ContaResumida}></PrivateRoute>
        <PrivateRoute exact path="/registrarAtendimento" component={RegistrarAtendimento}></PrivateRoute>
        <PrivateRoute exact path="/casoBko" component={CasoBko}></PrivateRoute>
      </Switch>
    </Router>
  );
}

export default App;
