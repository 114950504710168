import styled from "styled-components";
import { Layout } from "antd";

export const StyledLayout = styled(Layout)`
  min-height: 100vh;

  aside{
    background-color: #041E41;
    min-height: 100vh;
    top: 65px;
    z-index: 1;
    position: fixed;
    ul{
      background-color: #041E41 !important;
      height: 120vh;
    }
    
  }

  .header {
    color: white;
    background-color: #041E41;
    padding: 0;
    display: grid;
    grid-template-columns: 80px 1fr 50px 15px;
    place-content: center;
    height: 65px;

    top: 0px;
    z-index: 1;
    position: sticky;

    .center {
      margin: auto 0px;
    }

    .user {
      margin-right:10px
    }

    menuitem {
      margin-right: 30px;
      left: 50%;
      position: relative;
      transform: translateX(-50%);

    }

    .tempoAtendimento {
      height: 64px;
      line-height: 20px;
      margin: auto;
      display: grid;
      place-content: center;
      text-align: center;
    }

    .anticon {
      font-size: 30px !important;
      margin-left: 30%;
      margin-top: 20%;
    }

    .trigger {
      display: grid;

    }

    .logo {
      width: 180px;
    }
  }
  .informacoesAtendimento {
    padding: 10px 40px;
  }
`;
