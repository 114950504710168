import {
  CalendarOutlined,
  FileTextOutlined,
  HistoryOutlined,
  ImportOutlined,
  InboxOutlined,
  InfoCircleFilled,
  QuestionCircleFilled,
  QuestionCircleOutlined,
  SafetyOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  List,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { normalizeCPF_CNPJ, normalizePhone } from "../BuscaCPF/normalizeEntry";
import { Layout } from "./styles";

const { cpf } = require("cpf-cnpj-validator");
const { cnpj } = require("cpf-cnpj-validator");

export const CreateContaResumida = ({
  visible,
  onCancel = false,
  onSuccess = null,
}) => {
  const { Option: AutoCompleteOption } = AutoComplete;
  const { Option } = Select;
  const [form] = Form.useForm();

  const client = useSelector(({ Client }) => Client.client);
  const SelectedProduct = useSelector(({ Client }) => Client.selected_product);

  const cResmuidaData = {
    RecordTypeId: SelectedProduct && SelectedProduct.RecordType,
    CPF__c: !client.CNPJ__c
      ? !client.CPF__c
        ? ""
        : client.CPF__c
      : client.CPF__c,
    FirstName: !client.Name ? "" : client.Name.split(" ")[0],
    LastName: !client.Name
      ? ""
      : `${client.Name.split(" ")[1]} ${client.Name.split(" ")[2]}`,
    TipoRegistroAtendimento__c: "Elogio",
    Salutation: "Sr",
    Sexo__c: "Masculino",
    Email: !client.E_Mail__c ? "" : client.E_Mail__c,
    Phone:
      client.Phone === null
        ? ""
        : normalizePhone(client.Phone.replace("+55", ""), ""),
  };

  const [ValidateCPF_CNPJ_Search, setValidateCPF_CNPJ_Search] = useState("");
  const [error, setError] = useState(false);
  const [contaResmuidaData, setContaResumidaData] = useState(cResmuidaData);
  const [clienteNaoPoseeEmail, setClienteNaoPoseeEmail] = useState(false);
  const [emailResult, setEmailResult] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    // try {
    //   document.querySelector(".ant-modal-root").style.cssText = "display: none;"
    // } catch (error) {}
    form.resetFields();
  }, [visible]);

  const handleSearchGmail = (value) => {
    let res = [];
    if (!value || value.indexOf("@") >= 0) {
      res = [];
    } else {
      res = ["gmail.com", "hotmail.com", "outlook.com"].map(
        (domain) => `${value}@${domain}`
      );
    }
    setEmailResult(res);
  };

  const tipoAtendimentos = [
    "Elogio",
    "Informação",
    "Reclamação",
    "Solicitação",
    "Sugestão",
    "Teste de Atendimento",
    "Engano",
    "Queda/Muda",
  ];

  const handleChangeCPF_CNPJ = (event) => {
    if (
      String(event.target.value).length === 14 ||
      String(event.target.value).length === 18
    ) {
      if (cpf.isValid(event.target.value) || cnpj.isValid(event.target.value)) {
        handleChangeContaResmida(event.target.value, "CPF__c");
        setValidateCPF_CNPJ_Search("success");
      } else {
        setValidateCPF_CNPJ_Search("error");
      }
    }
  };

  const handleChangeContaResmida = (value, type) => {
    if (type === "CPF__c") {
      setContaResumidaData({
        ...contaResmuidaData,
        CPF__c: value,
      });
    }else if (type === "Phone") {
      setContaResumidaData({
        ...contaResmuidaData,
        Phone: value.target.value,
      });
    } else if (type === "First Name") {
      setContaResumidaData({
        ...contaResmuidaData,
        FirstName: value.target.value,
      });
    } else if (type === "Last Name") {
      setContaResumidaData({
        ...contaResmuidaData,
        LastName: value.target.value,
      });
    } else if (type === "TipoRegistro") {
      setContaResumidaData({
        ...contaResmuidaData,
        TipoRegistroAtendimento__c: value,
      });
    } else if (type === "Salutation") {
      setContaResumidaData({
        ...contaResmuidaData,
        Salutation: value,
      });
    } else if (type === "Sexo") {
      setContaResumidaData({
        ...contaResmuidaData,
        Sexo__c: value,
      });
    } else if (type === "Email") {
      clienteNaoPoseeEmail == true
        ? setContaResumidaData({
          ...contaResmuidaData,
          Email: "",
        })
        : setContaResumidaData({
          ...contaResmuidaData,
          Email: value,
        });
    }
  };

  const checkSumAccount = async () => {
    setLoadingButton(true);
    return await axios
      .post(
        process.env.REACT_APP_API_URL + "/api/check_sum_account",
        contaResmuidaData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        console.log("Check sum => \n", response);
        if (response.data.return.contaResumida == false) {
          createSumAccount();
        } else {
          setLoadingButton(false);
          message.error("Já existe uma conta resumida para esse cpf");
        }
      })
      .catch((error) => {
        if (error.response.status === 409) {
          message.error(error.response.data.return);
        } else {
          message.error("Houve um erro ao verificar a conta");
        }
        setLoadingButton(false);
      });
  };

  const createSumAccount = async () => {
    setLoadingButton(true);
    return await axios
      .post(
        process.env.REACT_APP_API_URL + "/api/create_sum_account",
        contaResmuidaData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        console.log("Create sum => \n", response);
        setLoadingButton(false);
        onCancel(false);
        form.resetFields();
        if (onSuccess) {
          await onSuccess({ cpf_cnpj: contaResmuidaData.CPF__c });
          message.success("Exito");
        }
      })
      .catch((error) => {
        if (error.response.status === 409) {
          message.error(error.response.data.return);
        } else {
          message.error("Houve um erro");
        }
        setLoadingButton(false);
      });
  };

  return (
    // Modal Criar Conta Resumida
    <Modal
      width={"55%"}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      afterClose={() => {
        form.resetFields();
      }}
    >
      <h2
        style={{
          color: "#002D62",
          fontWeight: "300",
          textAlign: "center",
          fontSize: "26px",
        }}
      >
        Criar conta resumida
      </h2>
      <Divider style={{ color: "#002D62" }} type="horizontal" />
      <Form
        form={form}
        // onFinish={() => createContaResumida()}
        onFinish={() => createSumAccount()}
        initialValues={cResmuidaData}
      >
        <Row gutter={[16, 0]} style={{ height: "80px" }}>
          {/* Input CPF */}
          <Col span={8}>
            <label style={{ color: "#002D62", fontWeight: "500" }}>
              CPF/CNPJ
            </label>

            <Form.Item
              style={{ marginRight: "20px" }}
              hasFeedback
              validateStatus={ValidateCPF_CNPJ_Search}
              label=""
              name="CPF__c"
              rules={[
                {
                  required: true,
                  message: "Por favor insira o CPF/CNPJ.",
                },
              ]}
              normalize={normalizeCPF_CNPJ}
            >
              <Input
                error={error}
                onChange={(e) => handleChangeCPF_CNPJ(e)}
                disabled={
                  client.CNPJ__c == null
                    ? client.CPF__c == null
                      ? false
                      : true
                    : true
                }
                placeholder="CPF/CNPJ do usuario"
              />
            </Form.Item>
          </Col>

          {/* Input First Name */}
          <Col span={8}>
            <label style={{ color: "#002D62", fontWeight: "500" }}>Nome</label>
            <Form.Item
              label=""
              name="FirstName"
              rules={[{ required: true, message: "Por favor insira o Nome" }]}
            >
              <Input
                disabled={client.Name === "" ? false : true}
                placeholder="Nome"
                onChange={(value) =>
                  handleChangeContaResmida(value, "First Name")
                }
              />
            </Form.Item>
          </Col>

          {/* Input Last Name */}
          <Col span={8}>
            <label style={{ color: "#002D62", fontWeight: "500" }}>
              Sobrenome
            </label>
            <Form.Item
              label=""
              name="LastName"
              rules={[
                {
                  required: true,
                  message: "Por favor insira o sobrenome",
                },
                { min: "3", message: "" },
                { max: "20", message: "" },
              ]}
            >
              <Input
                disabled={client.Name === "" ? false : true}
                onChange={(value) =>
                  handleChangeContaResmida(value, "Last Name")
                }
                placeholder="Sobrenome"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]} style={{ height: "80px" }}>
          {/* Select Tipo Tipo Atendimento */}
          <Col span={16}>
            <label style={{ color: "#002D62", fontWeight: "500" }}>
              Tipo Atendimento
            </label>
            <Form.Item name="TipoRegistroAtendimento__c">
              <Select
                placeholder="Tipo Atendimento"
                onChange={(value) =>
                  handleChangeContaResmida(value, "TipoRegistro")
                }
              >
                {tipoAtendimentos.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* Select Salutation Sr / Sra */}
          <Col span={8}>
            <label style={{ color: "#002D62", fontWeight: "500" }}>
              Tratamento
            </label>
            <Form.Item name="Salutation">
              <Select
                placeholder="Tratamento"
                onChange={(value) =>
                  handleChangeContaResmida(value, "Salutation")
                }
              >
                <Option value="Sr">Sr</Option>
                <Option value="Sra">Sra</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]} style={{ height: "80px" }}>
          {/* Input Telefone */}
          <Col span={12}>
            <label style={{ color: "#002D62", fontWeight: "500" }}>
              Telefone
            </label>
            <Form.Item
              label=""
              name="Phone"
              rules={[
                {
                  required: true,
                  min: 5,
                  message: "Por favor insira o Telefone",
                },
              ]}
            >
              <Input placeholder="Telefone do usuario" 
              onChange={(e) => handleChangeContaResmida(e, "Phone")}/>
            </Form.Item>
          </Col>

          {/* Select Sexo */}
          <Col span={12}>
            <label style={{ color: "#002D62", fontWeight: "500" }}>Sexo</label>
            <Form.Item name="Sexo__c">
              <Select
                placeholder="Sexo"
                defaultValue={"Masculino"}
                onChange={(value) => handleChangeContaResmida(value, "Sexo")}
              >
                <Option value="Masculino">Masculino</Option>
                <Option value="Feminino">Feminino</Option>
                <Option value="Outro">Outro</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]} style={{ height: "80px" }}>
          {/* Input Email */}
          <Col span={12}>
            <label style={{ color: "#002D62", fontWeight: "500" }}>Email</label>
            <Form.Item
              label=""
              name="Email"
              rules={[
                {
                  required: !clienteNaoPoseeEmail,
                  message: "Por favor insira o Email",
                },
              ]}
            >
              <AutoComplete
                disabled={clienteNaoPoseeEmail}
                onSearch={handleSearchGmail}
                onChange={(value) => handleChangeContaResmida(value, "Email")}
                placeholder="Email do usuario"
              >
                {emailResult.map((email) => (
                  <AutoCompleteOption key={email} value={email}>
                    {email}
                  </AutoCompleteOption>
                ))}
              </AutoComplete>
            </Form.Item>
          </Col>

          {/* CheckBox consumidor não possui e-mail */}
          <Col span={12}>
            <label style={{ color: "#002D62", fontWeight: "500" }}>‎ ‏‏‎</label>
            <Form.Item>
              <Checkbox
                onClick={() =>
                  clienteNaoPoseeEmail == true
                    ? setClienteNaoPoseeEmail(false)
                    : setClienteNaoPoseeEmail(true)
                }
              >
                Consumidor não possui e-mail
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        {/* Send Button */}
        <Row gutter={[16, 0]} style={{ height: "80px" }}>
          <Col span={24}>
            <Button
              type="primary"
              style={{
                borderRadius: "6px",
                background: "#002D62",
                height: "38px",
                marginTop: "10px",
                color: "#FFF",
                fontWeight: "350",
                fontSize: "15px",
              }}
              htmlType="submit"
              loading={loadingButton}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const ContaResumida = () => {
  const history = useHistory();
  const client = useSelector(({ Client }) => Client.client);

  const [createContaResmuidaModal, setCreateContaResumidaModal] = useState(
    client.Id && !client.Lead
  );
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [modalRA, setModalRA] = useState(false);
  const [modalData, setModalData] = useState(null);

  // Use to close Register Atendimento Modal
  function closeRAModal() {
    setModalRA(false);
  }

  const listPaginationConf = {
    defaultCurrent: 1,
    defaultPageSize: 4,
    pageSizeOptions: [4, 8, 16, 20],
  };

  return (
    <Layout>
      {!client.Lead && !client.Id && history.replace("/")}

      {/* Modal Criar Conta Resumida */}
      <CreateContaResumida
        visible={createContaResmuidaModal}
        onCancel={() => setCreateContaResumidaModal(false)}
      />

      {(client.Id && client.Lead) || (!client.Id && client.Lead) ? (
        <Fragment>
          <Row gutter={[16, 0]} style={{ marginLeft: "2%", paddingTop: "1%" }}>
            <Col span={4}>
              <Button
                type="primary"
                style={{
                  borderRadius: "6px",
                  background: "#002D62",
                  height: "38px",
                  marginTop: "10px",
                  color: "#FFF",
                  fontWeight: "350",
                  fontSize: "15px",
                }}
                onClick={() => history.push("/registrarAtendimento")}
              >
                Registrar Atendimento
              </Button>
            </Col>
          </Row>
          {/* Card Conta Resumida */}
          <Row
            gutter={[16, 0]}
            style={{ height: "40%", marginLeft: "2%", paddingTop: "1%" }}
          >
            <Col span={23}>
              <Card
                style={{
                  width: "100%",
                  minHeight: "400px",
                  backgroundColor: "#E4E8EE",
                }}
                loading={loadingProducts}
                cover={
                  <div
                    style={{
                      backgroundColor: "#002D62",
                      borderRadius: "10px 10px 0px 0px",
                      height: "60px",
                      width: "100%",
                    }}
                  >
                    <Row
                      gutter={[16, 0]}
                      style={{ justifyContent: "space-between" }}
                    >
                      <h4
                        style={{
                          marginTop: "12px",
                          marginLeft: "5%",
                          fontSize: "22px",
                          color: "#FFF",
                          fontWeight: "600",
                        }}
                      >
                        Histórico dos atendimentos
                      </h4>
                    </Row>
                  </div>
                }
              >
                {/* List Atendimentos */}
                <List
                  itemLayout="vertical"
                  pagination={listPaginationConf}
                  dataSource={client.LeadRegisters ? client.LeadRegisters : []}
                  style={
                    !client.LeadRegisters
                      ? {
                        height: "50px",
                      }
                      : {}
                  }
                  renderItem={(item) => (
                    <List.Item key={item.Id}>
                      <List.Item.Meta
                        title={
                          <a>
                            <h3
                              style={{
                                color: "#000000",
                                fontWeight: "400",
                                fontSize: "19px",
                              }}
                            >
                              {item.Name}
                            </h3>
                          </a>
                        }
                        onClick={() => {
                          setModalRA(true);
                          setModalData({ ...item });
                        }}
                        description={
                          <Fragment>
                            <h3
                              style={{
                                color:
                                  (item.Tipo__c === "Reclamação" &&
                                    "#FF0000") ||
                                  (item.Tipo__c === "Elogio" && "#33AF75") ||
                                  (item.Tipo__c === "Informação" &&
                                    "#FFB300") ||
                                  (item.Tipo__c === "Solicitação" && "blue") ||
                                  "black",
                                fontWeight: "400",
                                fontSize: "15px",
                                marginBottom: "0px",
                                marginTop: "-2px",
                              }}
                            >
                              <InfoCircleFilled
                                style={{
                                  color:
                                    (item.Tipo__c === "Reclamação" &&
                                      "#FF0000") ||
                                    (item.Tipo__c === "Elogio" && "#33AF75") ||
                                    (item.Tipo__c === "Informação" &&
                                      "#FFB300") ||
                                    (item.Tipo__c === "Solicitação" &&
                                      "blue") ||
                                    "black",
                                }}
                              />{" "}
                              {item.Tipo__c}
                            </h3>
                            <h3
                              style={{
                                color: "#b1b1af",
                                fontWeight: "300",
                                fontSize: "14px",
                                marginBottom: "0px",
                                marginTop: "8px",
                              }}
                            >
                              <CalendarOutlined /> Aberto:{" "}
                              {moment(item.Data_Hora_de_cria_o__c).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </h3>
                            <h3
                              style={{
                                color: "#b1b1af",
                                fontWeight: "300",
                                fontSize: "14px",
                                marginBottom: "0px",
                                marginTop: "8px",
                              }}
                            >
                              <HistoryOutlined /> Atualizado:{" "}
                              {moment(item.LastModifiedDate).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </h3>
                          </Fragment>
                        }
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          </Row>
        </Fragment>
      ) : (
        <Fragment>
          <Button
            type="primary"
            onClick={() => setCreateContaResumidaModal(true)}
            style={{
              borderRadius: "6px",
              background: "#002D62",
              height: "38px",
              marginTop: "10px",
              marginLeft: "2%",
              color: "#FFF",
              fontWeight: "350",
              fontSize: "15px",
            }}
          >
            Create Conta Resumida
          </Button>
          <h1
            style={{
              color: "#002D62",
              fontWeight: "300",
              textAlign: "center",
              fontSize: "30px",
            }}
          >
            Consumidor não possui Conta Resumida
          </h1>
        </Fragment>
      )}

      {modalData !== null && (
        <Fragment>
          {/* Modal Atendimiento */}
          <Modal
            width={"55%"}
            visible={modalRA}
            footer={null}
            onCancel={() => closeRAModal()}
          >
            <h1
              style={{
                color: "#002D62",
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "25px",
              }}
            >
              Antendimento
            </h1>
            <Divider style={{ color: "#000000", width: "95%" }} />
            <Row gutter={[16, 0]} style={{ paddingBottom: "40px" }}>
              <Col span={12}>
                {/* Tipo c */}
                <h3
                  style={{
                    color:
                      (modalData.Tipo__c === "Reclamação" && "#FF0000") ||
                      (modalData.Tipo__c === "Elogio" && "#33AF75") ||
                      (modalData.Tipo__c === "Informação" && "#FFB300") ||
                      (modalData.Tipo__c === "Solicitação" && "blue") ||
                      "black",
                    fontWeight: "600",
                    fontSize: "17px",
                    marginBottom: "0px",
                    marginTop: "-2px",
                  }}
                >
                  <InfoCircleFilled
                    style={{
                      color:
                        (modalData.Tipo__c === "Reclamação" && "#FF0000") ||
                        (modalData.Tipo__c === "Elogio" && "#33AF75") ||
                        (modalData.Tipo__c === "Informação" && "#FFB300") ||
                        (modalData.Tipo__c === "Solicitação" && "blue") ||
                        "black",
                    }}
                  />{" "}
                  {modalData.Tipo__c}
                </h3>
                {/* Hora de cria_o__c */}
                <h3
                  style={{
                    color: "#000000",
                    fontWeight: "600",
                    fontSize: "17px",
                    marginBottom: "0px",
                    marginTop: "8px",
                  }}
                >
                  <CalendarOutlined /> Aberto:{" "}
                  {moment(modalData.Data_Hora_de_cria_o__c).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                </h3>
                {/* Last Modified Date */}
                <h3
                  style={{
                    color: "#000000",
                    fontWeight: "600",
                    fontSize: "17px",
                    marginBottom: "0px",
                    marginTop: "8px",
                  }}
                >
                  <HistoryOutlined /> Atualizado:{" "}
                  {moment(modalData.LastModifiedDate).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                </h3>
                {/* Linha c */}
                <h3
                  style={{
                    color: "#000000",
                    fontWeight: "600",
                    fontSize: "17px",
                    marginBottom: "0px",
                    marginTop: "8px",
                  }}
                >
                  {" "}
                  <InboxOutlined /> {modalData.Linha__c}
                </h3>
                {/* Equipe c */}
                <h3
                  style={{
                    color: "#000000",
                    fontWeight: "600",
                    fontSize: "17px",
                    marginBottom: "0px",
                    marginTop: "8px",
                  }}
                >
                  <TeamOutlined /> {modalData.Equipe__c}
                </h3>
              </Col>

              <Divider style={{ height: "230px" }} type="vertical" />

              <Col span={11}>
                <div>
                  {/* Canal de entrada c */}
                  {modalData.Canal_de_entrada__c !== null && (
                    <h3
                      style={{
                        color: "#000000",
                        fontWeight: "600",
                        fontSize: "17px",
                        marginBottom: "0px",
                        marginTop: "8px",
                      }}
                    >
                      <ImportOutlined /> {modalData.Canal_de_entrada__c}
                    </h3>
                  )}
                  {/* Motivo da Ligacao c */}
                  <h3
                    style={{
                      color: "#000000",
                      fontWeight: "600",
                      fontSize: "17px",
                      marginBottom: "0px",
                      marginTop: "8px",
                    }}
                  >
                    <QuestionCircleFilled /> {modalData.Motivo_da_Ligacao__c}
                  </h3>
                  {/* Sub Motivo do Atendimento c */}
                  <h3
                    style={{
                      color: "#000000",
                      fontWeight: "600",
                      fontSize: "17px",
                      marginBottom: "0px",
                      marginTop: "8px",
                    }}
                  >
                    <QuestionCircleOutlined />{" "}
                    {modalData.Sub_Motivo_do_Atendimento__c}
                  </h3>
                  {/* Conclusao c */}
                  <h3
                    style={{
                      color: "#000000",
                      fontWeight: "600",
                      fontSize: "17px",
                      marginBottom: "0px",
                      marginTop: "8px",
                    }}
                  >
                    <SafetyOutlined /> {modalData.Conclusao__c}
                  </h3>
                  {/* Descricao da Solicitao c */}
                  {modalData.Descricao_da_Solicitao__c !== null && (
                    <h3
                      style={{
                        color: "#000000",
                        fontWeight: "600",
                        fontSize: "17px",
                        marginBottom: "0px",
                        marginTop: "8px",
                      }}
                    >
                      <FileTextOutlined />{" "}
                      {modalData.Descricao_da_Solicitao__c.substring(0, 50)}
                    </h3>
                  )}
                </div>
              </Col>
            </Row>
          </Modal>
        </Fragment>
      )}
    </Layout>
  );
};
