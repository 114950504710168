import styled from "styled-components";

export const Layout = styled.div`
  place-content: center;

  margin-top: 2rem;

  .boxShadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  .cardColor {
    background-color: #ffffff;
  }

  .card {
    align-self: center;
    justify-self: center;
  }
`;
