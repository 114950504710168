import styled from "styled-components";

export const Layout = styled.div`
  background-color: #FFFF !important;
  height: 108vh;

  .boxShadow {
    box-shadow: 0 2px 3px #00000029;
  }
  
  .boxShadowCard {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  .borderRadius {
    border-radius: 10px;
  }

  .cardColor {
      background-color: #FFFFFF;
  }

  .card {
    height: 100%;
    width: 100%;
    align-self: center;
    justify-self: center;
  }

  .cardSize {
    width: 100%;
    align-self: center;
    justify-self: center;
  }

  .buttons {
    box-shadow: 0 2px 3px #00000029;
  }

  .sae_button {
    box-shadow: 0 2px 3px #00000029;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }
  
  .rows {
    justify-content: space-between;
 }

  .cardColor {
    background-color: #FFFF;
  }

  .ant-input {
    border-radius: 25px;
  }

  .ant-select {
    border-radius: 25px;
  }

  .ant-input-affix-wrapper {
    border-radius: 25px;   // or other value
    box-shadow: 0 3px 4px #00000029;
 }

  .ant-carousel .slick-dots li button {
    height: 6px;
    border-radius: 20%;
    opacity: 0.4;
  }
  
  .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    height: 7px;
    border-radius: 20%;
  }
`;
