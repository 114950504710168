export const SET_CLIENT_DATA = "SET_CLIENT_DATA";
export const SET_CONTA_RESUMIDA = "SET_CONTA_RESUMIDA";
export const SET_CLIENT_PRODUCTS = "SET_CLIENT_PRODUCTS";
export const SET_CLIENT_SELECTED_PRODUCT = "SET_CLIENT_SELECTED_PRODUCT";
export const SET_CLIENT_SELECTED_SAE = "SET_CLIENT_SELECTED_SAE";
export const SET_CLIENT_SELECTED_CASE = "SET_CLIENT_SELECTED_CASE";
export const SET_CLIENT_SELECTED_REGISTER = "SET_CLIENT_SELECTED_REGISTER";
export const SET_CLIENT_PRODUCT_HISTORY = "SET_CLIENT_PRODUCT_HISTORY";
export const SET_CLIENT_OPENSVO = "SET_CLIENT_OPENSVO";
export const SET_CLIENT_SAE_LIST = "SET_CLIENT_SAE_LIST";
export const CLEAN_CLIENT_DATA = "CLEAN_CLIENT_DATA";
export const CLEAN_CLIENT_SAE_LIST = "CLEAN_CLIENT_SAE_LIST";
export const CLEAN_CLIENT_PRODUCT_HISTORY = "CLEAN_CLIENT_PRODUCT_HISTORY";
export const CLEAN_CLIENT_SELECT_PRODUCT = "CLEAN_CLIENT_SELECT_PRODUCT";
export const CLEAN_CLIENT_SELECT_SAE = "CLEAN_CLIENT_SELECT_SAE";
export const CLEAN_CLIENT_SELECT_REGISTER = "CLEAN_CLIENT_SELECT_REGISTER";
export const UPDATE_CLIENT_DATA = "UPDATE_CLIENT_DATA";
export const UPDATE_LEAD_REGISTERS = "UPDATE_LEAD_REGISTERS"
export const SET_CLIENT_SELECTED_BKO = "SET_CLIENT_SELECTED_BKO"
export const SET_SEARCH_CPF = "SET_SEARCH_CPF"

const initialState = {
    client: {
        Id: null,
        CNPJ__c: null,
        CPF__c: null,
        RecordTypeId: "",
        Segmenta_o_do_consumidor__c: "",
        Name: "",
        FirstName: "",
        LastName: "",
        E_Mail__c: "",
        PersonEmail: "",
        Phone: "",
        PersonMobilePhone: '',
        PersonBirthdate: "",
        Sexo__c: '',
        Bairro__c: '',
        BillingCity: "",
        BillingPostalCode: "",
        BillingState: "",
        BillingStreet: "",
        Proximidade__c: "",
    },
    products: [],
    selected_product: null,
    sae_list: {
        SAE_Results: 0,
        SAE_Type: '',
        SaeData: []
    },
    OPEN_SVO: false,
    product_history: {
        Product_ID: '',
        Cases: []
    },
    selected_case: null,
    selected_bko: null,
    selected_register: null,
    selected_sae: {
        Name: '',
        Name2: '',
        Phone: '',
        Phone2: '',
        Consultor: '',
        Email: '',
        Address: '',
        PostalCode: '',
        Service_Team: '',
        OwnerId: '',
        Type: '',
        AddressSearched: {
            CEP: '',
            Bairro: '',
            Cidade: '',
            Estado: ''
        }
    },
    searchCPF: null
}

export const Client = (state = initialState, { type, payload }) => {
    switch (type) {
        
        case SET_CLIENT_DATA:
            return {
                ...state,
                client: payload.clientData
            }

        case SET_CONTA_RESUMIDA:
            return {
                ...state,
                client: {
                    ...state.client,
                    Lead: payload
                }
            }
                    
        case SET_CLIENT_PRODUCTS:
            return {
                ...state,
                products: payload
            }

        case SET_CLIENT_OPENSVO:
            return {
                ...state,
                OPEN_SVO: payload
            }
        case SET_CLIENT_SELECTED_PRODUCT:
            return {
                ...state,
                selected_product: payload
            }
        case SET_CLIENT_SELECTED_SAE:
            return {
                ...state,
                selected_sae: payload
            }


        case SET_CLIENT_PRODUCT_HISTORY:
            return {
                ...state,
                product_history: payload
            }

        case SET_CLIENT_SELECTED_CASE:
            return {
                ...state,
                selected_case: payload
            }
        case SET_CLIENT_SELECTED_BKO:
            return {
                ...state,
                selected_bko: payload
            }
        case SET_CLIENT_SELECTED_REGISTER:
            return {
                ...state,
                selected_register: payload
            }
        case SET_CLIENT_SAE_LIST:
            return {
                ...state,
                sae_list: payload
            }
        case CLEAN_CLIENT_DATA:
            return {
                ...state,
                client: {
                    Id: null,
                    CNPJ__c: null,
                    CPF__c: null,
                    RecordTypeId: "",
                    Segmenta_o_do_consumidor__c: "",
                    Name: "",
                    FirstName: "",
                    LastName: "",
                    E_Mail__c: "",
                    PersonEmail: "",
                    Phone: "",
                    PersonMobilePhone: '',
                    PersonBirthdate: "",
                    Sexo__c: '',
                    Bairro__c: '',
                    BillingCity: "",
                    BillingPostalCode: "",
                    BillingState: "",
                    BillingStreet: "",
                    Proximidade__c: "",
                },
                products: [],
                selected_product: null,
                sae_list: [],
                OPEN_SVO: false,
                product_history: {
                    Product_ID: '',
                    Cases: []
                },
                selected_case: null,
                selected_register: null,
                selected_sae: {
                    Name: '',
                    Name2: '',
                    Phone: '',
                    Phone2: '',
                    Consultor: '',
                    Email: '',
                    Address: '',
                    PostalCode: '',
                    Service_Team: '',
                    OwnerId: '',
                    Type: '',
                    AddressSearched: {
                        CEP: '',
                        Bairro: '',
                        Cidade: '',
                        Estado: ''
                    }
                }
            }
        case CLEAN_CLIENT_SELECT_PRODUCT:
            return {
                ...state,
                selected_product: null
            }
        case CLEAN_CLIENT_SELECT_REGISTER:
            return {
                ...state,
                selected_register: null
            }
        case CLEAN_CLIENT_SAE_LIST:
            return {
                ...state,
                sae_list: {
                    SAE_Results: 0,
                    SAE_Type: '',
                    SaeData: []
                }
            }
        case CLEAN_CLIENT_SELECT_SAE:
            return {
                ...state,
                selected_sae: {
                    Name: '',
                    Name2: '',
                    Phone: '',
                    Phone2: '',
                    Consultor: '',
                    Email: '',
                    Address: '',
                    PostalCode: '',
                    Service_Team: '',
                    OwnerId: '',
                    Type: '',
                    AddressSearched: {
                        CEP: '',
                        Bairro: '',
                        Cidade: '',
                        Estado: ''
                    }
                }
            }
        case CLEAN_CLIENT_PRODUCT_HISTORY:
            return {
                ...state,
                product_history: {
                    Product_ID: '',
                    Cases: []
                },
                selected_case: null,
            }

        case UPDATE_CLIENT_DATA:
            return {
                ...state,
                client: payload
            }
    
        case UPDATE_LEAD_REGISTERS: 
            return {
                ...state,
                client: {
                    ...state.client,
                    LeadRegisters: payload
                }
            }
        
        case SET_SEARCH_CPF:
            return {
                ...state,
                searchCPF: payload
            }

        default:
            return state;
    }
}