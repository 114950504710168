import { createGlobalStyle } from "styled-components";
//import Roboto from "./fonts/Roboto-Regular.ttf";

export default createGlobalStyle`
    @font-face {
        font-family: 'Segoe UI';
        font-weight: 'bold';
    }

    html, body, #root{
        min-height: 100vh;
        margin:0;
        font-family: 'Segoe UI';
        font-weight: 'bold';
    }

    #root{
        background-color: #efefef;
    }
`;
