import {
  GERAR_BOLETO_SUCCESS,
  GERAR_BOLETO_START,
  GERAR_BOLETO_FAIL
} from "./constants";

const initialState = {
  data: { clientId: null, orderId: null, value: null, dueDate: null },
  taskId: null,
  error: null,
  loading: false
};

export function SVO(state = initialState, { type, payload }) {
  switch (type) {
    case GERAR_BOLETO_SUCCESS:
      return {
        ...state,
        taskId: payload.id,
        loading: false
      };
    case GERAR_BOLETO_START:
      return {
        ...state,
        loading: true
      };
    case GERAR_BOLETO_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    default:
      return state;
  }
}
