import styled from "styled-components";
import fundo from "../../Components/Images/fundoGeladeira.png"
export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;

  .boxShadow {
    box-shadow: 0 50px 50px rgba(0, 0, 0, 0.1), 0 15px 50px rgba(0, 0, 0, 0.05);
  }

  .borderRadius {
    border-radius: 10px;
  }

  h1 {
    font-family: 'Segoe UI';
    font-weight: 600;
  }

  .lostPass {
    float: right;
  }

  .rememberPass {
    float: left;
  }

  .loginBottom {
    border-radius: 6px;
  }

  .logo {
    width: 280px;
    margin-bottom: 20px;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }

   .site-space-background {
    position: fixed; height: 100%; width: 100%;
    background: no-repeat center center;
    background-image: url(${fundo});
    background-attachment: fixed;
    background-size: cover;
  }
  
  @keyframes shrink {
    0% {
      transform: scale(1.2)
    }
    100% {
      transform: scale(1.0)
    }
  }
`;
