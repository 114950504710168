import React, { useState, useEffect } from "react";
import { UserOutlined, AuditOutlined } from "@ant-design/icons";
import { Spin, Form, Input, Select, Button, message, Card, Divider, Row } from "antd";
import Title from "antd/lib/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { gerarBoleto } from "./actions";
import MaskedInput from 'antd-mask-input'
import { Layout } from "./styles";
import NumberFormat from "react-number-format";
import { SearchOutlined } from "@ant-design/icons";
import LoadingIMG from '../../Components/Images/loading.gif'
import Image from './Steps/Image'
import { Radio } from 'antd';

import { SET_CLIENT_PRODUCTS, SET_CLIENT_SELECTED_PRODUCT, SET_CLIENT_SELECTED_SAE, CLEAN_CLIENT_SELECT_PRODUCT, SET_CLIENT_OPENSVO, CLEAN_CLIENT_SELECT_SAE } from "../BuscaCPF/reducers";

import axios from 'axios'
axios.defaults.withCredentials = true

const TRANS_LINE = require('../../Components/data/TRANSLATE_LINE.json');
const PROD_PROBLEM = require('../../Components/data/EQUIP_PROBLEMS.json');
const TOP_SERVICE = require('../../Components/data/TOPS.json');

const { Option } = Select;
const { Option2 } = Select;

export const SVO = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm()

  const Client = useSelector(({ Client }) => Client.client);
  const Products = useSelector(({ Client }) => Client.products);
  const SelectedProduct = useSelector(({ Client }) => Client.selected_product);
  const SelectedSAE = useSelector(({ Client }) => Client.selected_sae);
  const SVOTrigger = useSelector(({ Client }) => Client.OPEN_SVO);
  const Login = useSelector(({Login}) => Login)


  const [count, setCount] = useState(1);
  const [error, setError] = useState(false);
  const [validateCEP, setValidateCEP] = useState('');
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setselectedProduct] = useState(null);
  const [topService, setTopService] = useState(false);
  const [problemList, setproblemList] = useState(null);
  const [selectedProblem, setProblem] = useState(null);
  const [caseNumber, setcaseNumber] = useState('');
  const [loadingCep, setloadingCep] = useState(false);
  const [problemDesc, setProblemDesc] = useState('');
  const [step, setStep] = useState(0);
  const [saeCEP, setsaeCEP] = useState('');
  const [saeCidade, setsaeCidade] = useState('');
  const [saeUf, setsaeUf] = useState('');
  const [commentText, setCommentText] = useState('');
  const [agenda, setAgenda] = useState(null);
  const [agendaSelecionada, setAgendaSelecionada] = useState(null);
  const [saeInfo, setSaeInfo] = useState({})
  const [initialComments, setInitialComments] = useState([
    `ID: ${Login.user.user_name === undefined ? Login.auth.userData : Login.user.user_name }`,
    'Observação: Por favor inserir o máximo possível de informações e detalhes deste atendimento',
    'Abertura de SVO - Consumidor ciente da apresentação da nota fiscal e ter um responsável maior de 18 anos para acompanhar o técnico.'
  ])

  useEffect(() => {

    let temp = ''
    initialComments.map(item => {
      temp += `${item}\n`
    })

    setCommentText(temp)

    form.setFieldsValue({ comentarios: temp })
    if (Client && Client.Id && Products.length === 0) {

      dispatch({ type: CLEAN_CLIENT_SELECT_SAE, payload: null });
      dispatch({ type: CLEAN_CLIENT_SELECT_PRODUCT, payload: null });
      setLoading(true);
      var produtos = []
      var date1 = new Date();

      axios.get(
        process.env.REACT_APP_API_URL + `/api/search_client_products`,
        {
          params: {
            data: Client.Id,
          }
        }
      ).then(res => {
        setLoading(false)
        if (res.data.return['totalSize'] === 0) {
          message.error("Não foi encontrado nenhum produto para esse cliente");
        }
        else {
          for (let index = 0; index < res.data.return['totalSize']; index++) {
            var date2 = new Date(res.data.return['records'][index]['Date_Installed__c'])
            var Difference_In_Days = (date1.getTime() - date2.getTime()) / (1000 * 3600 * 24)
            const Product = TRANS_LINE.find(e => e.LINHA_C === res.data.return['records'][index]['Linha__c'])
            produtos.push({
              url: "https://eluxproductsimages.s3-sa-east-1.amazonaws.com/images_by_code/" + res.data.return['records'][index]['Name'] + ".png",
              ID: res.data.return['records'][index]['Id'],
              Name: res.data.return['records'][index]['Nome_do_Produto__c'],
              Color: res.data.return['records'][index]['Cor__c'],
              Line: (Product !== undefined ? Product['PRODUCT_LINE'] : null),
              RecordType: (Product !== undefined ? Product['RECORDTYPE'] : null),
              Tensao: res.data.return['records'][index]['Tensao__c'],
              N_NFe: res.data.return['records'][index]['Numero_da_NF__c'],
              Date_Install: res.data.return['records'][index]['Date_Installed__c'],
              Status: res.data.return['records'][index]['Status__c'],
              Product_C: res.data.return['records'][index]['Product__c'],
              Code: res.data.return['records'][index]['Name'],
              Garantia: parseInt(Difference_In_Days) >= 365 ? false : true,
              Modelo_Comercial__c: res.data.return['records'][index]['Modelo_Comercial__c'],
              PNC_ML__c: res.data.return['records'][index]['PNC_ML__c'],
              Data_NF__c: res.data.return['records'][index]['Data_NF__c'],
              Marca__c: res.data.return['records'][index]['Marca__c'],
              Serial_Lot_Number__c: res.data.return['records'][index]['Serial_Lot_Number__c'],
              Premi__c: res.data.return['records'][index]['Premi__c'],
              DaysOfBuy: parseInt(Difference_In_Days),
              Address: {
                CEP: res.data.return['records'][index]['Zip__c'],
                Rua: res.data.return['records'][index]['Street__c'],
                Bairro: res.data.return['records'][index]['Bairro__c'],
                Proximidade: res.data.return['records'][index]['Proximidade__c'],
                City: res.data.return['records'][index]['City__c'],
                State: res.data.return['records'][index]['State__c'],
              }
            })
          }

          dispatch({ type: SET_CLIENT_PRODUCTS, payload: produtos });
          dispatch({ type: SET_CLIENT_SELECTED_PRODUCT, payload: produtos[0] })
          setLoading(false);
          setError(false);
          setProducts(produtos)
        }
      })
        .catch((error) => {
          console.log(error)
          setLoading(false)
          message.error("Houve um erro ao buscar produtos do cliente");
        })
    }

    if (SVOTrigger === true) {
      if (SelectedSAE.Name !== '' && (SelectedProduct.Address.CEP === SelectedSAE.AddressSearched.CEP)) {
        const TOPService = TOP_SERVICE.find(e => e.NAME === SelectedSAE.Name)
        setSaeInfo(SelectedSAE)
        setsaeCEP(SelectedSAE.AddressSearched.CEP)
        form.setFieldsValue({
          nomeSAE: SelectedSAE.Name,
          contatoSAE: SelectedSAE.Phone,
          cepInstalacao: SelectedSAE.AddressSearched.CEP
        })
        setTopService(TOPService === undefined ? false : TOPService)
      }
      const problems = PROD_PROBLEM.filter(e => e.Linha.includes(SelectedProduct['Line']))
      dispatch({ type: SET_CLIENT_OPENSVO, payload: false });
      form.setFieldsValue({ comentarios: temp })
      setValidateCEP('success')
      setproblemList(problems)
      nextStep()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Client])

  const onChangeProblem = (value, option) => {
    console.log(option)
    const problem = problemList[value]
    setProblem(problem)
  };

  const onChangeAgenda = (value, option) => {
    console.log(option)
    const indexes = option.key.split('.')
    const date = agenda[indexes[0]]
    const timeSlot = date.areas[0].timeSlots[indexes[1]].label

    setAgendaSelecionada({
      date: date.date,
      time: timeSlot
    });
  };

  const onChangeSelected = (value) => {
    const Selected = Products.find(product => product.ID === value)
    if (Selected.Address.CEP !== undefined && Selected.Address.CEP !== null && Selected.Address.CEP !== '') {
      Selected.Address.CEP !== null && setsaeCEP(Selected.Address.CEP)
      form.setFieldsValue({ cepInstalacao: Selected.Address.CEP })
    }

    dispatch({ type: SET_CLIENT_SELECTED_PRODUCT, payload: Selected });
    setselectedProduct(Selected)
  };

  const onChangeDesc = event => {
    const value = event.target.value.replace(`${initialComments[1]}\n`, '')
    form.setFieldsValue({comentarios: value })
    setCommentText(event.target.value)
  };

  function onChangeRadio(e) {
    setCount(e.target.value)
  };

  const changeCEP = event => {
    if (String(event.target.value).replace(/\D/g, "").length === 8) {
      setValidateCEP('success')
      setsaeCEP(event.target.value)
      searchSAE(event.target.value)
    }
    else {
      setValidateCEP('error')
      setsaeCEP(event.target.value)
    }
  };

  function searchSAE(cep) {
    dispatch({ type: CLEAN_CLIENT_SELECT_SAE, payload: null });
    setloadingCep(true);
    setAgenda(null);
    setAgendaSelecionada(null);

    axios.get(
      process.env.REACT_APP_API_URL + `/api/search_sae`,
      {
        params: {
          type: '1',
          searchType: 'ZIP',
          data: cep !== saeCEP ? cep : saeCEP
        }
      }
    ).then(res => {
      setloadingCep(false)
      if (res.data.return.ResultsNumber > 0) {
        const problems = PROD_PROBLEM.filter(e => e.Linha.includes(SelectedProduct['Line']))
        const TOPService = TOP_SERVICE.find(e => e.NAME === res.data.return['Results'][0]['Name'])
        setproblemList(problems)
        setSaeInfo(res.data.return.Results[0])
        dispatch({ type: SET_CLIENT_SELECTED_SAE, payload: res.data.return.Results[0] });
        form.setFieldsValue({ nomeSAE: res.data.return['Results'][0]['Name'] })
        form.setFieldsValue({ contatoSAE: res.data.return['Results'][0]['Phone'] })
        form.setFieldsValue({ comentarios: commentText })
        setTopService(TOPService === undefined ? false : TOPService)

        if (res.data.return.Results[0].Agendamento === true) {
          axios.get(
            process.env.REACT_APP_API_URL + `/api/search_schedules`,
            {
              params: {
                activityType: (SelectedProduct.Garantia) ? 'Elux_Garantia' : 'Elux_ForaGarantia',
                cstate: SelectedProduct.Address.State,
                ccity: SelectedProduct.Address.City,
                EluxBairro: SelectedProduct.Address.Bairro,
                postalCode: SelectedProduct.Address.CEP,
              }
            }
          ).then(response2 => {
            if (response2.data !== undefined && response2.data.status === 200) {
              setAgenda(response2.data.return.dates);
            }
          }).catch((error) => {
            message.error('Não foi encontrado as agendas, tente novamente mais tarde')
            console.log(error);
          });
        }
      }
      else {
        setError(true);
        message.error("Não foi encontrado SAE para o CEP Informado");
      }
    })
      .catch((error) => {
        console.log(error.response)
        setloadingCep(false)
        // setError(true);
        message.error(error.response.data.return !== undefined ? error.response.data.return : "Houve um erro ao buscar SAE");
      })
  };

  function nextStep() {
    if (SelectedProduct.Line === 'Linha Eletroportáteis' || SelectedProduct.Line === 'Purificadores de Água' || SelectedProduct.Line === 'Fornos de microondas')
      message.warning("SVOs não podem ser abertas para produtos de balcão");
    else
      setStep(step + 1)
  };

  function endSVO() {
    setTopService(false)
    setproblemList(null)
    setProblem(null)
    setsaeCEP('')
    dispatch({ type: CLEAN_CLIENT_SELECT_SAE, payload: null });
    dispatch({ type: CLEAN_CLIENT_SELECT_PRODUCT, payload: null });
    form.setFieldsValue({ Problem: '' })
    form.setFieldsValue({ cepInstalacao: '' })
    setStep(0)
  };

  function backStep() {
    setTopService(false)
    setproblemList(null)
    setProblem(null)
    setsaeCEP('')
    dispatch({ type: CLEAN_CLIENT_SELECT_SAE, payload: null });
    form.setFieldsValue({ Problem: '' })
    form.setFieldsValue({ cepInstalacao: '' })
    setStep(0)
  };

  async function onFinish(values) {
    const isTop = topService !== false ? topService['ID'] : false

    if (SelectedProduct['Garantia'] === false && SelectedProduct['Date_Install'] != null && isTop === false) {
      message.warning("O produto está fora de garantia.");
    }
    else {
      setLoading(true);

      axios.get(
        process.env.REACT_APP_API_URL + `/api/check_svo`,
        {
          params: {
            ClientID: Client.Id,
            ProductID: SelectedProduct.ID
          }
        }
      ).then(res => {
        if (res.data.return === true) {
          axios.post(
            process.env.REACT_APP_API_URL + "/api/open_svo",
            {
              data: {
                origin: 'Tela Resumo',
                client_id: Client.Id,
                needActivity: SelectedSAE['Agendamento'],
                SAE: {
                  Name: SelectedSAE['Name'],
                  TOPSAE: isTop,
                  OwnerId: SelectedSAE['OwnerId'],
                  Service_Team: SelectedSAE['Service_Team'],
                },
                Product: {
                  ID: SelectedProduct['ID'],
                  RecordType: SelectedProduct['RecordType'],
                  Line: SelectedProduct['Line'],
                  Date_Install: SelectedProduct['Date_Install']
                },
                Problem:
                {
                  Tipo: selectedProblem['Tipo'],
                  Motivo: selectedProblem['Motivo'],
                  Sub_Motivo: selectedProblem['Sub_Motivo'],
                  Descrição: selectedProblem['Descrição'],
                  Defeito: selectedProblem['Defeito'],
                  Comentarios: values['comentarios']
                },
                Activity: {
                  resourceId: SelectedSAE.Account,
                  date: (agendaSelecionada === null) ? '' : agendaSelecionada.date,
                  timeslot: (agendaSelecionada === null) ? '' : agendaSelecionada.time,
                  cname: Client.Name,
                  cphone: Client.Phone,
                  cemail: Client.E_Mail__c,
                  Elux_SeguimentacaoConsumidor: Client.Segmenta_o_do_consumidor__c,
                  czip: SelectedProduct.Address.CEP,
                  caddress: SelectedProduct.Address.Rua,
                  Elux_bairro_Produto: SelectedProduct.Address.Bairro,
                  Elux_cidade_Produto: SelectedProduct.Address.City,
                  Elux_estado_Produto: SelectedProduct.Address.State,
                  Elux_Proximidade: SelectedProduct.Address.Proximidade,
                  cstate: SelectedProduct.Address.State,
                  ccity: SelectedProduct.Address.City,
                  Elux_bairro: SelectedProduct.Address.Bairro,
                  Elux_NomeDoProduto: SelectedProduct.Name,
                  Elux_LiberarModeloUsual: (SelectedProduct.Code === '' || SelectedProduct.Code === undefined || SelectedProduct.Code === null) ? 1 : 0,
                  Elux_ModeloUsual: SelectedProduct.Code,
                  Elux_LiberarModeloComercial: (SelectedProduct.Modelo_Comercial__c === '' || SelectedProduct.Modelo_Comercial__c === undefined || SelectedProduct.Modelo_Comercial__c === null) ? 1 : 0,
                  Elux_ModeloComercial: SelectedProduct.Modelo_Comercial__c,
                  Elux_LiberarPNC_ML: (SelectedProduct.PNC_ML__c === '' || SelectedProduct.PNC_ML__c === undefined || SelectedProduct.PNC_ML__c === null) ? 1 : 0,
                  Elux_PNC_ML: SelectedProduct.PNC_ML__c,
                  Elux_LiberarNumeroNF: (SelectedProduct.N_NFe === '' || SelectedProduct.N_NFe === undefined || SelectedProduct.N_NFe === null) ? 1 : 0,
                  Elux_NumeroNF: SelectedProduct.N_NFe,
                  Elux_LiberarDataNF: (SelectedProduct.Data_NF__c === '' || SelectedProduct.Data_NF__c === undefined || SelectedProduct.Data_NF__c === null) ? 1 : 0,
                  Elux_DataNF: SelectedProduct.Data_NF__c,
                  Elux_LiberarNumeroSerie: (SelectedProduct.Serial_Lot_Number__c === '' || SelectedProduct.Serial_Lot_Number__c === undefined || SelectedProduct.Serial_Lot_Number__c === null) ? 1 : 0,
                  Elux_NumeroSerie: SelectedProduct.Serial_Lot_Number__c,
                  Elux_LiberarMarca: (SelectedProduct.Marca__c === '' || SelectedProduct.Marca__c === undefined || SelectedProduct.Marca__c === null) ? 1 : 0,
                  Elux_Marca: SelectedProduct.Marca__c,
                  Elux_TensaoProdInstalado: SelectedProduct.Tensao,
                  Elux_Produto: SelectedProduct.Code,
                  Elux_LinhaProduto: SelectedProduct.Line,
                  Elux_premium: SelectedProduct.Premi__c,
                },
              }
            },
            {
              headers: {
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
              },
            }
          ).then(res => {
            console.log(res);
            setLoading(false);
            setcaseNumber(res.data.Saida.return)
            message.success('SVO Aberta com sucesso.')
            nextStep()
          })
            .catch((error) => {
              console.log(error)
              setLoading(false)
              setError(true);
              message.error(error.response.data.return !== undefined ? error.response.data.return : "Erro ao abrir SVO para o Cliente/Produto");
            })
        }
        else {
          setLoading(false)
          setError(true);
          message.error("Já existe uma SVO aberta para o Cliente/Produto");
        }
      })
        .catch((error) => {
          console.log(error)
          setLoading(false)
          message.error("Erro ao abrir SVO para o Cliente/Produto");
          setError(true);
        })
    }
  };

  return (
    <Layout>
      {(step === 0) ?
        <Card className="boxShadow borderRadius cardColor card">
          <Spin spinning={loading}>
            <Form
              name="selectForm"
              onFinish={nextStep}
              style={{
                width: '400px',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >

              {Client && Client.Id ?
                <div>
                  <h1 style={{ color: '#002D62', fontWeight: 'bold', textAlign: 'center', fontSize: '25px' }}>Abertura de SVO</h1>
                  <Divider style={{ color: '#000000', width: '95%' }} />
                  {SelectedProduct !== null ?
                    <img alt={SelectedProduct.Name} src={SelectedProduct.url} style={{ width: '50%', left: '50%', position: 'relative', transform: 'translateX(-50%)', marginBottom: '20px' }} />
                    : null}
                  {SelectedProduct !== null && SelectedProduct['Garantia'] === false && SelectedProduct['Date_Install'] !== null ?
                    <Form.Item>
                      <Title style={{ textAlign: "center", fontWeight: "bold", color: 'red' }} level={3}>
                        Produto fora de Garantia
                      </Title>
                    </Form.Item > : null}

                  <Form.Item
                    name="Product"
                    label='Selecione o produto por nome ou modelo do produto:'
                    hasFeedback
                    rules={[{ required: false, message: "Selecione o produto." }]}

                  >

                    <Radio.Group onChange={onChangeRadio} value={count} style={{ marginBottom: '20px', marginTop: '10px' }} >
                      <Radio value={1}>Nome</Radio>
                      <Radio value={2}>Modelo</Radio>
                    </Radio.Group>
                    {count === 1 ? <Select value={SelectedProduct !== null ? SelectedProduct.ID : ''} onChange={onChangeSelected} placeholder="Selecione o produto">
                      {Products.map((product) => <Option key={product.ID} value={product.ID} >{product.Name}  - NF({product.N_NFe})</Option>)}
                    </Select> :

                      <Select value={SelectedProduct !== null ? SelectedProduct.ID : ''} onChange={onChangeSelected} placeholder="Selecione o produto">
                        {Products.map((product) => <Option key={product.ID} value={product.ID} >{product.Code}  - NF({product.N_NFe})</Option>)}
                      </Select>
                    }
                  </Form.Item>
                  <Button disabled={SelectedProduct == null ? true : false} style={{ marginTop: '10px', width: '25%', background: '#002D62', color: '#ffff', borderRadius: '5px', marginLeft: '75%' }} type="primary" htmlType="submit">
                    Próximo
                  </Button>

                </div> :
                <h1 style={{ color: '#002D62', fontWeight: 'bold', textAlign: 'center', fontSize: '25px' }}>
                  Consumidor não identificado
                </h1>
              }
            </Form>
          </Spin>
        </Card> : null}

      {(step === 1) ?
        <Card className="boxShadow borderRadius cardColor cardSize">
          <Spin spinning={loading}>
            <Form
              form={form}
              name="svoForm"
              onFinish={onFinish}
              style={{
                width: "100%",
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div>
                <h1 style={{ color: '#002D62', fontWeight: 'bold', textAlign: 'center', fontSize: '25px' }}>Abertura de SVO</h1>
                <Divider style={{ color: '#000000', width: '100%' }} />
                <Form.Item
                  label="Produto selecionado:"
                  name="productSelected"
                  hasFeedback
                  rules={[{ enable: false }]}
                  style={{ display: 'inline-block', width: '60%', marginRight: '10%' }}
                >
                  <Input disabled={true} defaultValue={SelectedProduct !== null ? SelectedProduct['Name'] : ''} />
                </Form.Item>
                <Form.Item
                  label="Código do produto:"
                  name="productCode"
                  hasFeedback
                  rules={[{ enable: false }]}
                  style={{ display: 'inline-block', width: '30%' }}
                >
                  <Input disabled={true} defaultValue={SelectedProduct !== null ? SelectedProduct['Code'] : ''} />
                </Form.Item>

                <Form.Item
                  name="cepInstalacao"
                  label="CEP de instalação:"
                  hasFeedback
                  rules={[{ required: true, message: " Digite CEP de instalação." }]}
                  style={{ display: 'inline-block', width: '25%', marginRight: '1%' }}
                >
                  <MaskedInput
                    placeholder="CEP de instalação"
                    value={SelectedProduct !== null ? SelectedProduct.PostalCode : ''}
                    onChange={(e) => changeCEP(e)}
                    onPressEnter={() => searchSAE(saeCEP)}
                    maxLength={9}
                    mask="11111-111"
                  />
                </Form.Item>

                <Form.Item
                  name="BuscaSae"
                  label=""
                  style={{ display: 'inline-block', width: '15%' }}
                >
                  <Button style={{ marginTop: '32px', borderRadius: '5px', marginLeft: '3%' }} icon={<SearchOutlined />} loading={loadingCep} disabled={loadingCep || (saeCEP.replace(/\D/g, "").length !== 8 ? true : false)} onClick={() => searchSAE(saeCEP)}>Buscar</Button>
                </Form.Item>

                {(SelectedSAE.Name !== '' && saeCEP !== '') &&
                  <div style={{ display: 'inline-block', width: '57%', marginLeft: '2%', marginTop: '1%' }}>
                    <Form.Item
                      name="CepBairro"
                      label="Bairro"
                      style={{ display: 'inline-block', width: '47%' }}
                    >
                      <h2 style={{ fontWeight: '600', textAlign: 'left', fontSize: '16px' }}>{SelectedSAE.AddressSearched.Bairro}</h2>
                    </Form.Item>

                    <Form.Item
                      name="CepCidade"
                      label="Cidade"
                      style={{ display: 'inline-block', width: '47%' }}
                    >
                      <h2 style={{ fontWeight: '600', textAlign: 'left', fontSize: '16px' }}>{SelectedSAE.AddressSearched.Cidade}</h2>
                    </Form.Item>

                    <Form.Item
                      name="CepEstado"
                      label="UF"
                      style={{ display: 'inline-block', width: '6%' }}
                    >
                      <h2 style={{ fontWeight: '600', textAlign: 'left', fontSize: '16px' }}>{SelectedSAE.AddressSearched.Estado}</h2>
                    </Form.Item>
                  </div>}

                {
                  (agenda !== null) &&
                  <Form.Item
                    name="Agenda"
                    rules={[{ required: true, message: "Selecione a agenda." }]}
                    label="Selecione a agenda para a SVO:"
                    style={{ display: 'inline-block', width: '100%', justifySelf: 'center' }}>
                    <Select onChange={onChangeAgenda} placeholder="Selecione a agenda" >
                      {
                        (agenda !== null || agenda !== undefined) ?
                        agenda.map((item, index) => {
                          return item.areas[0].timeSlots ? item.areas[0].timeSlots.map((timeslot, index2) => {
                            if (timeslot.reason === '') {
                              return (
                                <Option key={index + '.' + index2} value={item.date + ' - ' + timeslot.label}>
                                  {item.date + ' ' + timeslot.label}
                                </Option>
                              )
                            }
                            else {
                              return <></>
                            }
                          }) : null
                        }) : null
                      }
                    </Select>
                  </Form.Item>
                }

                <Form.Item
                  hasFeedback
                  name="nomeSAE"
                  label="Nome do SAE:"
                  rules={[{ required: true, enable: false, message: "Buscar SAE" }]}
                  style={{ display: 'inline-block', width: '60%', marginRight: '10%' }}
                >
                  <Input key={`${Math.floor((Math.random() * 1000))}-min`} disabled={true} value={SelectedSAE.Name} defaultValue={SelectedSAE.Name} />
                </Form.Item>
                <Form.Item
                  hasFeedback
                  name="contatoSAE"
                  label="Contato do SAE:"
                  rules={[{ required: true, enable: false, message: "Buscar SAE" }]}
                  style={{ display: 'inline-block', width: '30%' }}
                >
                  <Input key={`${Math.floor((Math.random() * 1000))}-min`} disabled={true} value={SelectedSAE.Phone} defaultValue={SelectedSAE.Phone} />
                </Form.Item>

                <Form.Item
                  name="Problem"
                  hasFeedback
                  rules={[{ required: true, message: "Selecione o problema." }]}
                  label="Selecione o problema do produto:"
                  style={{ display: 'inline-block', width: '100%', justifySelf: 'center' }}>
                  <Select 
                    onChange={onChangeProblem} 
                    placeholder="Selecione o problema"
                    options={
                      problemList !== null ? problemList.map((problem, index) => {
                        return {
                          label: problem.Ocorrência,
                          value: index
                        }
                      }) : []
                    } 
                  >
                    {/* {problemList !== null ? problemList.map((problems, index) => <Option key={index} value={
                      [
                        problems.Ocorrência, problems.Descrição,
                        problems.Linha, problems.Motivo,
                        problems.Produtos, problems.Defeito,
                        problems.Sub_Motivo, problems.Tipo,
                        problems.Tipo_Registro,
                      ]
                    } >{problems.Ocorrência}</Option>) : null} */}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="comentarios"
                  label="Comentários:"
                  rules={[{ required: true, message: "Digite um comentário" }]}
                  style={{ display: 'inline-block', width: '100%', justifySelf: 'center' }}>
                  <Input.TextArea
                    onChange={(e) => onChangeDesc(e)}
                    rows={6}
                    placeholder="Observação: Por favor inserir o máximo possível de informações e detalhes deste atendimento"
                    allowClear 
                    autoSize/>
                </Form.Item>
                <Form.Item
                  style={{ display: 'inline-block', width: '50%' }}>
                  <Button style={{ marginTop: '10px', width: '40%', background: '#002D62', borderRadius: '5px' }} type="primary" onClick={backStep}>
                    Voltar
                  </Button>
                </Form.Item>
                <Form.Item
                  style={{ display: 'inline-block', width: '50%' }}>
                  <Button style={{ marginTop: '10px', width: '40%', background: '#002D62', borderRadius: '5px', marginLeft: '60%' }} type="primary" htmlType="submit">
                    Finalizar
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Spin>
        </Card> : null}

      {(step === 2 && caseNumber !== '') ?
        <Card className="boxShadow borderRadius cardColor cardSize2">
          <Form
            name="basic"
            className="svoForm"
            initialValues={{ remember: true }}
            onFinish={endSVO}
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div>
              <h1 style={{ color: '#002D62', fontWeight: 'bold', textAlign: 'center', fontSize: '25px' }}>Abertura de SVO</h1>
              <Divider style={{ color: '#000000', width: '95%' }} />
              <Form.Item
                style={{ display: 'inline-block', width: '100%' }}>
                <h2 style={{ color: '#000000', fontWeight: '500', textAlign: 'center', fontSize: '20px' }}>
                  A abertura de SVO foi concluída. Em até 2 dias úteis, o Serviço Autorizado irá entrar em contato com o consumidor para agendar a visita.
                  O número da SVO é {caseNumber}.
                </h2>
              </Form.Item>
              <h1 style={{ color: '#002D62', fontWeight: 'bold', textAlign: 'center', fontSize: '25px' }}>Dados do Serviço Autorizado</h1>
              <Divider style={{ color: '#000000', width: '95%' }} />
              <Form.Item
                style={{ display: 'inline-block', width: '100%' }}>
                <h3 style={{ color: '#000000', fontWeight: '600', fontSize: '20px' }}>Nome do SAE:</h3>
                <h3 style={{ color: '#000000', fontSize: '20px' }}>{SelectedSAE.Name}</h3>
              </Form.Item>
              <Form.Item
                style={{ display: 'inline-block', width: '100%' }}>
                <h3 style={{ color: '#000000', fontWeight: '600', fontSize: '20px' }}>Telefone:</h3>
                <h3 style={{ color: '#000000', fontSize: '20px' }}>{SelectedSAE.Phone}</h3>
              </Form.Item>
              <Form.Item
                style={{ display: 'inline-block', width: '100%' }}>
                <h3 style={{ color: '#000000', fontWeight: '600', fontSize: '20px' }}>E-mail:</h3>
                <h3 style={{ color: '#000000', fontSize: '20px' }}>{SelectedSAE.Email}</h3>
              </Form.Item>
              <Form.Item
                style={{ display: 'inline-block', width: '100%' }}>
                <h3 style={{ color: '#000000', fontWeight: '600', fontSize: '20px' }}>Endereço:</h3>
                <h3 style={{ color: '#000000', fontSize: '20px' }}>{SelectedSAE.Address}</h3>
              </Form.Item>

              <Form.Item>
                <Button style={{ marginTop: '10px', width: '25%', background: '#002D62', borderRadius: '5px', left: '50%', position: 'relative', transform: 'translateX(-50%)' }} type="primary" htmlType="submit">
                  Entendido
                </Button>
              </Form.Item>

            </div>
          </Form>
        </Card> : null}
    </Layout >
  );
};
